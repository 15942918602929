import Layout from '@components/v5/layouts/Layout';
import ContextLabel from '../ContextLabel';
import { StaticImage } from 'gatsby-plugin-image';
import { useLocation } from '@reach/router';
import { getQueryParams } from '@src/utils/helper';
import React, { useContext, useEffect, useState } from 'react';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../../../context/GlobalContextProvider';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants';
import LeadGenerationService from '../../../../utils/lead-generation';
import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';

export default function CouponDetails({ couponDetails, currentProgram }) {
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);

  const location = useLocation();
  const query = getQueryParams(location?.search) || {};

  const selectedCouponDetail =
    couponDetails.find((coupon) => coupon.coupon_code === query.coupon_code) ||
    {};

  const {
    affiliate_name,
    benefit_amount,
    coupon_code,
    electives_amount,
    scholarship_amount,
  } = selectedCouponDetail || {};

  const globalState = useContext(GlobalStateContext);
  const email = globalState.email;

  const EProgramNames = {
    'Software Development': 'Fellowship Program in Software Development',
    'QA Automation': 'Fellowship Program in QA Automation (SDET)',
    'Data Analytics': 'Fellowship Program in NextGen Data Analytics with AI',
  };

  const [open, setOpen] = useState();

  const redeemedCodes = localStorage.getItem('redeemedCodes') || '';
  const isCodeRedeemed = redeemedCodes === coupon_code;

  useEffect(() => {
    if (isCodeRedeemed) {
      setOpen(true);
    }
  }, []);

  const referralActivityHandler = async () => {
    const leadAndActivityPayload = {
      LeadDetails: [
        {
          Attribute: 'EmailAddress',
          Value: email,
        },
      ],
      Activity: {
        ActivityEvent: ELeadSquaredActivityCode.REFERRAL_COUPON,
        Fields: [
          { SchemaName: 'mx_Custom_1', Value: coupon_code },
          { SchemaName: 'mx_Custom_2', Value: query?.utm_source || 'null' },
          { SchemaName: 'mx_Custom_3', Value: query?.utm_medium || 'null' },
          { SchemaName: 'mx_Custom_4', Value: query?.utm_campaign || 'null' },
          { SchemaName: 'mx_Custom_5', Value: query?.utm_term || 'null' },
          { SchemaName: 'mx_Custom_6', Value: query?.utm_content || 'null' },
        ],
      },
    };

    await LeadGenerationService.queueLeadAndActivity(leadAndActivityPayload);
  };

  return query.hasOwnProperty('coupon_code') && coupon_code ? (
    <Layout className="container flex flex-col  items-center pt-12 md:items-start ">
      {/* Context Label */}
      <ContextLabel
        text="Special Offer !!"
        className={
          'left-1/2 translate-y-1/2 rounded-full bg-v5-green-700 px-20 text-sm text-[10px] font-bold md:relative md:-translate-x-1/2 md:text-[14px]'
        }
      />

      {/* Whatsapp Group Details Container */}
      <div className="w-full rounded-[10px] border border-v5-neutral-500 bg-v5-neutral-200 md:border-[2px]">
        {/* <Header /> */}
        <div className="flex flex-col-reverse items-center justify-between gap-x-6 gap-y-5 p-8 px-6 md:flex-col md:p-12 lg:flex-row lg:items-stretch">
          <div className="max-w-[650px] text-center md:text-left">
            {open ? (
              <>
                <p className="text-[12px] md:text-[24px]">
                  Use code <b>{coupon_code}</b> to get{' '}
                  <b className="text-v5-green-200">₹10,000</b>{' '}
                  <b>additional off</b> on our {EProgramNames[currentProgram]}
                </p>
                <p className="text-center text-[40px] font-bold">+</p>
                <p className="text-[12px] md:text-[24px]">
                  Get exclusive benefits up to{' '}
                  <b className="text-v5-green-200">₹{benefit_amount}</b>
                </p>
                <div className="my-3 flex flex-col items-center gap-2 md:flex-row md:gap-20">
                  <div className="flex items-center gap-2 text-[14px] md:text-[18px]">
                    <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full bg-v5-neutral-400 md:h-[40px] md:w-[40px]">
                      <StaticImage
                        loading="lazy"
                        src="../../../images/GraduationHat.webp"
                        placeholder="blurred"
                        alt="YourStory"
                        width={50}
                        className="w-[18px] md:w-[24px]"
                      />
                    </div>
                    <div>
                      Scholarship of <b>₹{scholarship_amount}</b>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 text-[14px] md:text-[18px]">
                    <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full bg-v5-neutral-400 md:h-[40px] md:w-[40px]">
                      <StaticImage
                        loading="lazy"
                        src="../../../images/Book.webp"
                        placeholder="blurred"
                        alt="YourStory"
                        width={50}
                        className="w-[16px] md:w-[24px]"
                      />
                    </div>
                    <div>
                      Electives Worth <b>₹{electives_amount}</b>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <p className="text-[12px] md:text-[24px]">
                  You’ve registered for the masterclass via{' '}
                  <strong>{affiliate_name}’s post!</strong>
                </p>
                <p className="mt-4 text-[16px] md:text-[32px]">
                  As our trusted <b>Affiliate Education Partner</b>,{' '}
                  {affiliate_name?.split(' ')?.[0]} has helped you unlock a{' '}
                  <b className="text-v5-green-200">special discount!</b>
                </p>
              </>
            )}
          </div>
          <div className="relative">
            <StaticImage
              loading="lazy"
              src="../../../images/CouponCard.webp"
              placeholder="blurred"
              alt="YourStory"
              width={260}
            />
            <p className="absolute bottom-28 w-[260px] text-center text-[16px] text-[#F5EBBD]">
              Additional
              <br />
              <span className="text-[20px]">
                <b>₹10,000</b> OFF
              </span>
            </p>
            <div
              className="absolute bottom-4 mx-[20px] w-[220px] cursor-pointer rounded-md border-[#D3C791] bg-[#F6ECBF] p-4 px-3 text-center font-semibold"
              onClick={async () => {
                if (!isCodeRedeemed && coupon_code) {
                  GTM.track(gtmEvents.EXTERNAL_PLATFORM_EVENT, {
                    type: 'COUPON_AVAILED',
                    couponCode: coupon_code,
                  });

                  await referralActivityHandler();

                  localStorage.setItem('redeemedCodes', coupon_code);
                }

                setOpen(true);
              }}
            >
              <p>{open ? coupon_code : 'Tap to Claim Discount'}</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  ) : (
    <></>
  );
}
