import React from 'react';
import MasterclassSectionLayout from '../Layouts/MasterclassSectionLayout';
import VideoTestimonialSection from '@components/v5/VideoTestimonialSection';
import useResizer from '@components/extra/useResizer';

export default function CrioFeedback() {
  const isMobile = useResizer();
  return (
    <MasterclassSectionLayout
      layoutClassName="container grid grid-cols-1 pt-[26px] md:pt-12 lg:grid-cols-[58%_1fr]"
      heading={`Learner's Review`}
      sectionWrapperClassName={'md:gap-[30px] gap-[24px]'}
      headingClassName={'md:py-9 md:px-7 shadow-[0px_4px_9.4px_0px_#00000014]'}
    >
      {/* Set a fixed width of 58% for the swiper to prevent it from expanding to full width */}
      <VideoTestimonialSection
        disableGreenBanner
        disableCareerHandbook
        disableSectionHeading
        numberOfTestimonials={2}
        removeDescription={true}
        className="lg:max-w-[651px]"
        carouselWrapperClassname="mt-0 max-h-[620px]"
      ></VideoTestimonialSection>
    </MasterclassSectionLayout>
  );
}
