import { navigate } from 'gatsby'; //import navigate from gatsby
import { GTM } from '../../analytics/gtm';
import { gtmEvents } from '../../analytics/gtmEventsConstant';

const programsConfig = {
  BDT: {
    intro:
      'Our Backend Web Developer Track is carefully designed to help you learn in-demand backend engineering skills by working on real-world projects.',
    path: '/backend-developer-track',
  },
  FULL_STACK: {
    intro:
      'Our Full-Stack Web Developer Track is carefully designed to help you learn in-demand MERN-stack development skills by working on real-world projects.',
    path: '/full-stack-developer-track',
  },
};

const steps = [
  { id: '1', message: 'Hi there! How can I help you today?', trigger: '2' },
  {
    id: '2',
    message: ({ steps }) =>
      `Before we move ahead could you share your email ID to get in touch with you in case we get disconnected?`,
    trigger: 'email',
  },
  {
    id: 'email',
    user: true,
    placeholder: 'Type your email',
    validator: (mail) => {
      if (
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          mail,
        )
      ) {
        return true;
      }
      return 'Please enter a valid email address';
    },
    trigger: ({ steps }) => {
      GTM.login({
        userId: steps.email.value,
        email: steps.email.value,
      });

      //GTM Event for Email Capture
      GTM.track(gtmEvents.EMAIL_SUBMITTED, {
        type: 'CHATBOT',
      });

      fetch(
        `https://script.google.com/macros/s/AKfycbzGRcNkSR1QcLDInOIPoXyB36bfwbljSAclXnilDccDG67NgGo/exec?sheet=pe&email=${steps.email.value}&country=${window.country}&url=${window.location.pathname}`,
      )
        .then((resp) => resp.json())
        .then((resp) => {})
        .catch();
      return 'help-intent';
    },
  },
  {
    id: 'help-intent',
    message: 'Great. So how can I help you?',
    trigger: 'help-option',
  },
  {
    id: 'help-option',
    options: [
      {
        value: 'about',
        label: 'I would like to know more about Crio',
        trigger: 'about',
      },
    ],
  },
  {
    id: 'about',
    message: `Crio is a project-based learning platform for developers where you build internship-grade tech projects and learn key developer skills hands-on.`,
    trigger: 'about-2',
  },

  {
    id: 'about-2',
    delay: 3000,
    message:
      'We have a signature program on Full-Stack development where you will master HTML, CSS, JavaScript, MongoDB, ExpressJS, React, Node.js and more through hands-on projects, and get ready to advance your career as a successful Full-Stack developer',
    trigger: 'try-crio',
  },
  {
    id: 'try-crio',
    options: [
      {
        value: 'try-crio',
        label: 'Can I try Crio for free?',
        trigger: 'try-crio-free',
      },
      {
        value: 'scholarships',
        label: 'Can I get scholarships for this program?',
        trigger: 'scholarships-2',
      },
      {
        value: 'some-other',
        label: 'I have some other question',
        trigger: 'other-question',
      },
    ],
  },
  {
    id: 'try-crio-free',
    delay: 2000,
    message:
      'Our signature Full-Stack program comes with a free trial. You can apply for the program and start for free.',
    trigger: 'help-1',
  },
  {
    id: 'scholarships-2',
    delay: 2000,
    message:
      'Yes indeed. You can bag attractive scholarships for this program by participating and excelling in our free trial. The first week of the program is a free trial. Apply right away and start for free!',
    trigger: 'help-1',
  },
  {
    id: 'help-1',
    delay: 2000,
    message: 'Can I help you with something else?',
    trigger: 'help',
  },

  {
    id: 'help',
    options: [
      {
        value: 'yes',
        label: 'Yes',
        trigger: 'try-crio',
      },
      {
        value: false,
        label: 'No Thanks',
        trigger: 'result-end',
      },
    ],
  },
  {
    id: 'redirect-finish',
    message: 'Can I help you with something else?',
    delay: 4000,
    trigger: ({ steps }) => {
      navigate(steps['program-learn-more-option'].value);
      document.querySelector('.rsc-header a').click();
      return 'result-option';
    },
  },

  {
    id: 'other-question',
    message: 'Sure, Please type your question below in input box.',
    trigger: 'userQuestion',
  },
  {
    id: 'userQuestion',
    user: true,
    placeholder: 'Type your question here',
    trigger: 'userPhone-ques',
  },
  {
    id: 'userPhone-ques',
    message:
      'Thank you. It was nice chatting with you. We will reach out to you with an answer in 2-3 days.',
    trigger: ({ steps }) => {
      fetch(
        `https://script.google.com/macros/s/AKfycbzGRcNkSR1QcLDInOIPoXyB36bfwbljSAclXnilDccDG67NgGo/exec?sheet=peq&email=${steps.email.value}&query=${steps.userQuestion.value}&country=${window.country}&url=${window.location.pathname}`,
      )
        .then((resp) => resp.json())
        .then((resp) => {})
        .catch();
      return 'help-1';
    },
  },

  {
    id: 'result-end',
    message: ({ steps }) => {
      return `Thank you, It was nice chatting with you.`;
    },
    end: true,
  },
];
export default steps;
