import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import useResizer from '@components/extra/useResizer';

export default function TestimonialsCard({ testimonial }) {
  const {
    employeeName,
    employeeDesignation,
    employeeImage,
    companyImage,
    employeeSocialUrl,
  } = testimonial;
  const isScreenLessThan1200 = useResizer(1200);

  const data = useStaticQuery(graphql`
    query {
      socialIcons: allFile(
        filter: { relativePath: { regex: "images/v5/Launch/Social/" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              width: 800
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `);

  const testimonialGatsbyImage = getImage(employeeImage?.childImageSharp);
  const companyGatsbyImage = getImage(companyImage?.childImageSharp);

  const linkedinLogo = data?.socialIcons?.nodes.find((node) =>
    node.name.includes('linkedin-black'),
  );

  const linkedinGatsbyImage = getImage(linkedinLogo?.childImageSharp);

  return (
    <div
      className="flex flex-col gap-4 rounded-[12px] border border-[#E6F4F2] bg-white p-4 md:hidden"
      style={{
        boxShadow: isScreenLessThan1200 ? 'none' : '5px 5px 5px 0px #0000000D',
      }}
    >
      {/* Employee Image */}
      <div className="h-[200px] w-full overflow-hidden rounded-[12px]">
        <GatsbyImage
          image={testimonialGatsbyImage}
          className="h-full w-full object-cover"
          alt={employeeName}
        />
      </div>

      <div className="w-full font-manrope">
        {/* Employee Details */}
        <div className="mb-2 text-left text-base font-light leading-tight text-v5-neutral-500">
          <strong className="font-extrabold">{employeeName}</strong>
        </div>
        <div className="flex flex-col items-start justify-start space-y-3 py-2">
          <div className="text-left font-rubik text-sm font-semibold text-v5-neutral-400">
            {employeeDesignation}
          </div>
          <div className="relative h-[40px] w-full max-w-[150px]">
            <GatsbyImage
              image={companyGatsbyImage}
              className="!absolute inset-0 h-full w-full"
              imgClassName="object-contain object-left !h-[40px]"
              alt={`${employeeName}'s company logo`}
            />
          </div>

          <a href={employeeSocialUrl} target="_blank" rel="noreferrer">
            <GatsbyImage
              image={linkedinGatsbyImage}
              className="h-12 w-12 object-contain"
              alt="LinkedIn logo"
              loading="eager"
            />
          </a>
        </div>
      </div>
    </div>
  );
}
