import { useLocation } from '@reach/router';

const useCurriculumDownload = () => {
  const EPaths = {
    FSD: '/software-development-fellowship-program',
    QA: '/qa-automation-testing',
    ACCELERATE: '/accelerate',
    DATA_ANALYTICS: '/ai-data-analytics',
    SYSTEM_DESIGN: '/fellowship-program-system-design',
  };

  const location = useLocation();

  const downloadCurriculum = () => {
    const pathname = location.pathname;
    let downloadLink =
      'https://crio-assets.s3.ap-south-1.amazonaws.com/FSD_Handbook.pdf';

    if (pathname.includes(EPaths.FSD)) {
      downloadLink =
        'https://crio-assets.s3.ap-south-1.amazonaws.com/FSD_Handbook.pdf';
    } else if (pathname.includes(EPaths.QA)) {
      downloadLink =
        'https://crio-assets.s3.ap-south-1.amazonaws.com/QA_Handbook.pdf';
    } else if (pathname.includes(EPaths.ACCELERATE)) {
      downloadLink =
        'https://crio-assets.s3.ap-south-1.amazonaws.com/Accelerate_Handbook.pdf';
    } else if (pathname.includes(EPaths.DATA_ANALYTICS)) {
      downloadLink =
        'https://crio-assets.s3.ap-south-1.amazonaws.com/Data_Analytics_Handbook.pdf';
    } else if (pathname.includes(EPaths.SYSTEM_DESIGN)) {
      downloadLink =
        'https://crio-assets.s3.ap-south-1.amazonaws.com/System_Design_Handbook.pdf';
    } else {
      downloadLink =
        'https://crio-assets.s3.ap-south-1.amazonaws.com/FSD_Handbook.pdf';
    }

    if (downloadLink) {
      window.open(downloadLink, '_blank');
    }

    return '';
  };

  return { downloadCurriculum };
};

export default useCurriculumDownload;
