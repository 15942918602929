import { isSessionNearby } from '@src/utils/Masterclass';
import { useState, useEffect, useCallback } from 'react';

const useSessionAvailability = (sessionStartDateTime) => {
  const [isSessionAvailable, setIsSessionAvailable] = useState(
    isSessionNearby(sessionStartDateTime),
  );

  const updateSessionAvailability = useCallback(() => {
    setIsSessionAvailable(isSessionNearby(sessionStartDateTime));
  }, [sessionStartDateTime]);

  useEffect(() => {
    let frameId;

    const checkSessionStatus = () => {
      updateSessionAvailability();
      frameId = requestAnimationFrame(checkSessionStatus);
    };

    frameId = requestAnimationFrame(checkSessionStatus);

    return () => {
      if (frameId) {
        cancelAnimationFrame(frameId);
      }
    };
  }, [updateSessionAvailability]);

  return isSessionAvailable;
};

export default useSessionAvailability;
