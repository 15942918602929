import React, { useContext, useEffect, useRef, useState } from 'react';
import FormDropDown from '../FormComponents/FormDropDown';
import {
  generateDropDownValues,
  profiles,
  getGraduationYearList,
  leadStage,
  experiments,
  getDemoSlotsMenuItems,
  getTwoNextDemoSlots,
  allotExperimentId,
  whitelistedLeadSources,
  ERROR_LEAD_SOURCE,
  highestQualifications,
  jobDomains,
  jobRoleTechs,
  jobRoleNonTechs,
  companies,
  LEAD_GEN_DATA_SOURCE,
  extendLeadObjectWithUserLocationData,
  LEAD_GEN_PROGRAM_ID_FELLOWSHIP_PLUS,
  workExperience,
  LEAD_GEN_QA_AUTOMATION_PATH,
  LEAD_GEN_AUTOMATION_EDGE_PATH,
  LEAD_GEN_PROGRAM_ID_DATA_ANALYTICS,
  OTHER_DEMO_SLOT,
  routesWithCustomDemoSlots,
  LEAD_GEN_PATHNAME_FALLBACK,
  LEAD_GEN_LOCATION_FALLBACK,
  LEAD_GEN_DEVICE_MOBILE,
  LEAD_GEN_DEVICE_DESKTOP,
  LEAD_GEN_QA_AUTOMATION_PPC_PATH,
  EProgramInterested,
  programNameTrialTypeIdMapping,
  sources,
} from '../../../constants/LeadGenerationConstants/index';
import FormInput from '../FormComponents/FormInput';
import { FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormStateContext } from '../../../context/FormContextProvider';
import LeadGenerationService from '../../../../utils/lead-generation';
import FormSubmitButton from '../FormComponents/FormSubmitButton';
import { navigate } from '@reach/router';
import toast from 'react-hot-toast';
import {
  faBook,
  faBuilding,
  faBriefcase,
  faCalendarDays,
  faEnvelope,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons';
import { faUserTie } from '@fortawesome/pro-solid-svg-icons';

import * as Sentry from '@sentry/gatsby';
import { GTM } from '../../../analytics/gtm';
import { gtmEvents } from '../../../analytics/gtmEventsConstant';
import { useLocation } from '@reach/router';
import {
  LEAD_GEN_PROGRAM_ID_FULL_STACK,
  LEAD_GEN_UTM_CAMPAIGN_TWO,
  LEAD_GEN_PROGRAM_ID_QA,
} from '../../../constants/LeadGenerationConstants/index';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';
import useResizer from '@components/extra/useResizer';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '@src/context/GlobalContextProvider';
import {
  LEAD_GEN_UTM_SOURCE_QA,
  LEAD_GEN_UTM_SOURCE_DEV,
  CWP_EVENT_FIRED_KEY,
} from '../../../constants/LeadGenerationConstants/index';
import { getOpenFormPageOneSchema } from '../FormPageValidations/PageTwoValidation';
import { addMinutes, compareAsc, format } from 'date-fns';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';
import FormAutoComplete from '../FormComponents/FormAutoComplete';
import useDataScienceProgram from '@src/hooks/useDataScienceProgram';
import webinarjamService from '../../../../utils/webinarjam-service';
import {
  getProgramIdMasterclass,
  getWebinarIdBasedOnRoute,
  getWebinarIdByProgram,
  isYearGreaterThanCurrent,
} from '@src/utils/LeadGeneration/index';
import FormPhoneInput from '../FormComponents/FormPhoneInput';
import FormCheckbox from '../FormComponents/FormCheckbox';
import db from '../../../data/db.json';
import { cn } from '@/lib/utils';
import OTPPage from './OTPPage';
import Cookies from 'js-cookie';
import useRegisterMetadata from '@src/hooks/Masterclass/useRegisterMetadata';
import { getCountryCodeAndPhone, getQueryParams } from '@src/utils/helper';
import useProvisionalEnrollment from '@src/hooks/useProvisionalEnrollment';
import useAffiliate from '@src/hooks/useAffiliate';
import { checkIsOnDemandPage } from '@src/constants/pages';
import useSendActivities from '@src/hooks/useSendActivities';

export default function OpenFormPageOne(props) {
  const {
    closeDialog,
    programType,
    trialProgramId,
    buttonLocation,
    isOpenForm,
    onSubmitL2,
    demoSlots,
    pageTwoButtonText,
    onSubmitPageTwo,
    applicationPage = '',
    isFullStackPpcVl,
    customUtmParams,
    hasProgramInterestedField,
    openFormClassName,
    hideLastName,
    disableDemoSlots = false,
    shouldShowAffiliatePagePopup,
  } = props;

  const [isPhoneInputClicked, setIsPhoneInputClicked] = useState(false);
  const [showOTPPage, setShowOTPPage] = useState(false);

  // State variables
  const state = useContext(FormStateContext);
  const isPhoneVerified = Cookies.get('isPhoneVerified');
  const { sendActivityHandler } = useSendActivities();

  const globalState = useContext(GlobalStateContext);
  const globalDispatch = useContext(GlobalDispatchContext);
  const [isFormTouched, setIsFormTouched] = useState(false); //state to track if a form field is focused
  const [loading, setLoading] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const isMobile = useResizer();
  const email = globalState.email;
  const openFormStoredData = globalState.openFormStoredData;
  const experiment = applicationPage ? '' : allotExperimentId(email);
  const { getCurrentProgram } = useRegisterMetadata();

  // Path variables
  const location = useLocation();
  const pathname = location?.pathname;
  const hasUtmParams = location?.search.includes('utm');

  const isOnDemandPage = checkIsOnDemandPage(pathname);

  const { isProvisionalEnrollment } = useProvisionalEnrollment();
  const { isAffiliatePage } = useAffiliate();

  const [emailSubmitted, setEmailSubmitted] = useState(false);

  const userLocation = globalState.userLocation;

  // UTM variables
  const storedParams = globalState.utm;
  const searchParam = new URLSearchParams(storedParams);
  const couponCode = searchParam.get('coupon_code') || 'null';
  const commType = searchParam.get('commtype') || 'null';
  const utmSource = searchParam.get('utm_source') || customUtmParams?.utmSource;
  const utmMedium = searchParam.get('utm_medium') || customUtmParams?.utmMedium;
  const utmNetwork = searchParam.get('utm_network');
  const utmPublisher = searchParam.get('utm_publisher');
  const utmCampaign =
    searchParam.get('utm_campaign') || customUtmParams?.utmCampaign;
  const utmTerm = searchParam.get('utm_term');
  const utmContent = searchParam.get('utm_content');
  const gclID = searchParam.get('gclid');
  const lcID = searchParam.get('li_fat_id');
  const fbclID = searchParam.get('fbclid');

  useEffect(() => {
    if (couponCode !== 'null') {
      GTM.track(gtmEvents.EXTERNAL_PLATFORM_EVENT, {
        type: 'COUPON_VIEWED',
        couponCode: couponCode,
      });
    }
  }, [couponCode]);

  // Check if the current lead if of type QA
  const isMQALead =
    pathname === LEAD_GEN_QA_AUTOMATION_PATH ||
    pathname === LEAD_GEN_AUTOMATION_EDGE_PATH ||
    pathname === LEAD_GEN_QA_AUTOMATION_PPC_PATH ||
    programType === LEAD_GEN_PROGRAM_ID_QA;
  const isSde2Lead =
    pathname === '/software-development-fellowship-program-plus/';

  // Check if the current page is masterlclass page and get the eventSlug from the URL.
  const isMasterclassRegisterPage = pathname.includes('masterclass/register');

  const isHomePage = pathname === '/';
  const isCampaignPage = pathname.includes('/campaign/');

  const { isDataSciencePage } = useDataScienceProgram();
  const isDemoSlotSelectionEnabled = routesWithCustomDemoSlots.some((route) =>
    pathname.includes(route),
  );

  const getProgramIdRedirect = () => {
    if (isMQALead) return LEAD_GEN_PROGRAM_ID_QA;
    else if (isSde2Lead) return LEAD_GEN_PROGRAM_ID_FELLOWSHIP_PLUS;
    else if (isDataSciencePage) return LEAD_GEN_PROGRAM_ID_DATA_ANALYTICS;
    return LEAD_GEN_PROGRAM_ID_FULL_STACK;
  };

  // Demo Slot variables
  const allDemoSlots = db?.strapi_metadata?.demoSlots || []; // Ensure allDemoSlots is an array

  let shouldShowDemoSlots = false; // Now shouldShowDemoSlots is initialized before being used here

  // initialize RHF variables (react-hook-form)
  const hookFormTwo = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      programInterested: '', // Ensure programInterested has a default value
      email: '',
      phone: '',
      whatsAppUpdates: true,
      highestQualification: '',
      graduationYear: '',
      ...(isFullStackPpcVl
        ? {}
        : { currentlyWorking: '', jobDomain: '', jobRole: '', company: '' }),
      trialSlot: '',
      source: '',
    },
    resolver: yupResolver(
      getOpenFormPageOneSchema(
        true,
        shouldShowDemoSlots, // Now shouldShowDemoSlots is initialized before being used here
        isSde2Lead,
        isFullStackPpcVl,
        isDemoSlotSelectionEnabled,
        isOpenForm,
        isHomePage,
        isCampaignPage,
        isProvisionalEnrollment,
        isAffiliatePage,
        hasUtmParams,
      ),
    ),
    mode: 'all',
    shouldFocusError: false,
  });

  // Use watch() to monitor changes in form fields, including programInterested
  const programInterested = hookFormTwo.watch('programInterested') || ''; // Provide a fallback value

  // Check if we have a valid programInterested value before filtering
  const updatedDemoSlots = hasProgramInterestedField
    ? allDemoSlots.filter(
        (slot) =>
          slot.TrialType ===
          (programNameTrialTypeIdMapping?.[programInterested] ||
            programNameTrialTypeIdMapping['Software Development']),
      )
    : demoSlots;

  const nextDemoSlots = getTwoNextDemoSlots(updatedDemoSlots);

  let demoSlotMenuItems = getDemoSlotsMenuItems(nextDemoSlots);

  // If Demo Slot selection is enabled, append the "Attend Now" option
  if (isDemoSlotSelectionEnabled) {
    demoSlotMenuItems = [
      ...demoSlotMenuItems,
      {
        label: <>Attend Now</>,
        value: OTHER_DEMO_SLOT,
      },
    ];
  }

  // Show demo field option only if lead is from demo flow and demo slots exist
  shouldShowDemoSlots =
    experiment === experiments.DEMO_FLOW &&
    Array.isArray(nextDemoSlots) &&
    nextDemoSlots.length > 0 &&
    !disableDemoSlots;

  /* START: Focus on error field */
  const [canFocus, setCanFocus] = useState(true);
  const onError = (e) => {
    setCanFocus(true);
  };

  useEffect(() => {
    if (hookFormTwo.formState.errors && canFocus) {
      // Get all error fields
      const elements = Object.keys(hookFormTwo.formState.errors)
        .map((name) => document.getElementsByName(name)[0])
        .filter((el) => !!el);
      elements.sort(
        (a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top,
      );

      // Set scroll to the 1st one
      if (elements.length > 0) {
        let errorElement = elements[0];
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        errorElement.focus({ preventScroll: true });
        setCanFocus(false);
      }
    }
  }, [hookFormTwo.formState, canFocus]);
  /* END: Focus on error field */

  const currentlyWorkingWatcher = hookFormTwo.watch('currentlyWorking');
  const domainWatcher = hookFormTwo.watch('jobDomain');

  useEffect(() => {
    globalDispatch({
      type: 'SET_PROGRAM_INTERESTED',
      payload: programInterested,
    });
  }, [programInterested, globalDispatch]);

  const isCurrentlyWorking = currentlyWorkingWatcher === 'Yes';
  const isDomainTech = domainWatcher === 'Tech';

  const program = hasProgramInterestedField
    ? programInterested
    : isSde2Lead
    ? 'Software Development Plus'
    : isMQALead
    ? 'QA Automation'
    : isDataSciencePage
    ? 'Data Analytics'
    : isMasterclassRegisterPage
    ? getCurrentProgram()
    : 'Software Development';

  const handleOnDemandSessionAutoRegistration = async (formData) => {
    if (
      isDemoSlotSelectionEnabled &&
      (formData.trialSlot === OTHER_DEMO_SLOT || isOnDemandPage)
    ) {
      //check if the program is accelerate:
      const isAccelerateProgram =
        isYearGreaterThanCurrent(formData.graduationYear) &&
        (programInterested === EProgramInterested[0] || isOnDemandPage);

      let webinarId;

      if (isAccelerateProgram && isOnDemandPage) {
        webinarId = getWebinarIdByProgram('Accelerate');
      } else {
        //get webinar id based on route and program interested:
        webinarId = getWebinarIdBasedOnRoute(
          pathname,
          isAccelerateProgram ? 'Accelerate' : programInterested,
        );
      }

      const phone = formData.phone.includes('+')
        ? formData.phone
        : `+${formData.phone}`;

      //if webinar id is present, register the user for the on demand session:
      const registerPayload = {
        firstName: formData?.firstName ?? '',
        lastName: formData?.lastName ?? '',
        email: formData?.email,
        webinarId: webinarId,
        phoneCountryCode: getCountryCodeAndPhone(phone)?.countryCode,
        phone: getCountryCodeAndPhone(phone)?.phoneNum,
      };
      let thankYouUrl = null;
      if (webinarId) {
        let response = await webinarjamService.register(
          registerPayload,
          'everwebinar',
        );
        thankYouUrl = response.status.user.thank_you_url;
      }

      return thankYouUrl;
    }
  };

  // Store formData in state so it can be accessed after OTP verification

  const onSubmitLeadPageOne = async (data) => {
    try {
      setLoading(true);

      data.program = program;

      data.phone = '+'.concat(data.phone);
      data.email = data.email.toLowerCase();
      data.formURL = pathname || LEAD_GEN_PATHNAME_FALLBACK;
      data.location = buttonLocation || LEAD_GEN_LOCATION_FALLBACK;
      data.deviceType = isMobile
        ? LEAD_GEN_DEVICE_MOBILE
        : LEAD_GEN_DEVICE_DESKTOP;
      data.applicationStage = leadStage.HALF_FILL;
      data.dataSource = LEAD_GEN_DATA_SOURCE;

      if (utmSource) {
        if (whitelistedLeadSources.includes(utmSource))
          data.utmSource = utmSource;
        else data.utmSource = ERROR_LEAD_SOURCE;
      }
      if (utmMedium) data.utmMedium = utmMedium;
      if (utmPublisher) data.utmPublisher = utmPublisher;
      if (utmCampaign) data.utmCampaign = utmCampaign;
      if (utmNetwork) data.utmNetwork = utmNetwork;
      if (utmContent) data.utmContent = utmContent;
      if (utmTerm) data.utmTerm = utmTerm;
      if (gclID) data.gclID = gclID;
      if (lcID) data.lcID = lcID;
      if (fbclID) data.fbclID = fbclID;

      // Add user location data
      extendLeadObjectWithUserLocationData(data, userLocation);

      const lead = await LeadGenerationService.generateLead(data);
      const leadID = lead.data.leadID;

      // Set email, phone & docID details in global context
      globalDispatch({
        type: 'SET_EMAIL',
        payload: data.email,
      });
      globalDispatch({
        type: 'SET_PHONE',
        payload: data.phone,
      });
      globalDispatch({
        type: 'SET_GLOBAL_LEAD_DOCUMENT_ID',
        payload: leadID,
      });

      //ga events for successfull L1 form submission
      GTM.track(gtmEvents.L1_SUCCESSFULL_SUBMISSION, {
        url: location.href,
        email: data.email,
        location:
          pathname === LEAD_GEN_QA_AUTOMATION_PATH ||
          pathname === LEAD_GEN_AUTOMATION_EDGE_PATH
            ? LEAD_GEN_UTM_SOURCE_QA
            : LEAD_GEN_UTM_SOURCE_DEV,
        buttonLocation: buttonLocation,
        applicationPage,
      });

      //GTM Event for Email Capture
      // Check if phone is valid and completely filled before triggering email submission
      const isPhoneValid = !hookFormTwo.formState.errors?.phone;

      if (
        !globalState.isEmailEventSubmitted &&
        !emailSubmitted &&
        isPhoneValid
      ) {
        GTM.emailSubmission(data.email, data.phone);

        globalDispatch({
          type: 'SET_IS_EMAIL_EVENT_SUBMITTED',
          payload: true,
        });
      }

      setLoading(false);
      if (closeDialog) closeDialog();
    } catch (error) {
      console.log(error);
      setLoading(false);

      //ga events for unsuccessfull L1 form submission
      GTM.track(gtmEvents.L1_UNSUCCESSFULL_SUBMISSION, {
        email: data?.email,
        error: error?.message,
      });

      toast.error('Could not process request, please try again later.', {
        duration: 6000,
        style: { fontSize: '14px' },
        id: 'lead-gen-form-page-1',
      });
    }
  };

  const onOTPVerificationSuccess = async (url) => {
    if (onSubmitPageTwo && openFormStoredData) {
      await onSubmitPageTwo(openFormStoredData);
      return;
    }
    if (redirectUrl) {
      navigate(redirectUrl);
    } else {
      navigate(url);
    }
  };

  const provisionalEnrollmentHandler = async (formData) => {
    try {
      const queryParams = getQueryParams(location.search);

      const leadAndActivityPayload = {
        LeadDetails: [
          {
            Attribute: 'EmailAddress',
            Value: formData.email.toLowerCase(),
          },
        ],
        Activity: {
          ActivityEvent: ELeadSquaredActivityCode.PROVISIONAL_ENROLLMENT,
          Fields: [
            {
              SchemaName: 'mx_Custom_7',
              Value: queryParams.sessionType || 'default',
            },
          ],
        },
      };

      sendActivityHandler(ELeadSquaredActivityCode.PROVISIONAL_ENROLLMENT);

      await LeadGenerationService.queueLeadAndActivity(leadAndActivityPayload);

      document.querySelector('.PaymentButton').click();
    } catch (error) {
      console.error('debug > ', error);
    }
  };

  const referralActivityHandler = async (formData) => {
    const leadAndActivityPayload = {
      LeadDetails: [
        {
          Attribute: 'EmailAddress',
          Value: formData.email,
        },
      ],
      Activity: {
        ActivityEvent: '471',
        Fields: [
          { SchemaName: 'mx_Custom_1', Value: formData.firstName },
          {
            SchemaName: 'mx_Custom_2',
            Value: formData.lastName,
          },
          {
            SchemaName: 'mx_Custom_3',
            Value: formData.email,
          },
          {
            SchemaName: 'mx_Custom_4',
            Value: formData.phone,
          },
          {
            SchemaName: 'mx_Custom_5',
            Value: commType?.toLowerCase() || 'email',
          },
          {
            SchemaName: 'mx_Custom_6',
            Value: couponCode || 'null',
          },
        ],
      },
    };

    await LeadGenerationService.queueLeadAndActivity(leadAndActivityPayload);
  };

  const affiliatePageHandler = async (formData) => {
    try {
      await referralActivityHandler(formData);

      // Change this line to be more explicit
      if (typeof shouldShowAffiliatePagePopup === 'function') {
        shouldShowAffiliatePagePopup(true);
      }
    } catch (error) {
      console.log('debug > ', error);
    }
  };

  // Update submitPageTwo to store the formData
  const submitPageTwo = async (formData) => {
    if (isProvisionalEnrollment) {
      await provisionalEnrollmentHandler(formData);
      return;
    }

    console.log('🚀 ~ submitPageTwo ~ isAffiliatePage:', isAffiliatePage);

    if (isAffiliatePage) {
      await affiliatePageHandler(formData);
      return;
    }

    try {
      setLoading(true);
      globalDispatch({
        type: 'SET_STORED_OPEN_FORM_DATA',
        payload: formData,
      }); // Store formData for use after OTP verification

      const jobRole = [...jobRoleTechs, ...jobRoleNonTechs].includes(
        formData.jobRole,
      )
        ? formData.jobRole
        : 'Other';
      const company = companies.includes(formData.company)
        ? formData.company
        : 'Other';
      const highestQualification = highestQualifications.includes(
        formData.highestQualification,
      )
        ? formData.highestQualification
        : 'Other';

      let payload = {
        leadID: globalState.globalLeadDocumentId, // Retrieve profile and docID values from context

        name: `${formData.firstName} ${formData.lastName}`,

        highestQualification: highestQualification,
        ...(highestQualification === 'Other'
          ? { highestQualificationOther: formData.highestQualification }
          : {}),

        graduationYear: formData.graduationYear,
        currentlyWorking: isFullStackPpcVl
          ? state.workingStatus
          : formData.currentlyWorking,
        dataSource: LEAD_GEN_DATA_SOURCE,

        ...(formData.currentlyWorking === 'Yes' && !isFullStackPpcVl
          ? { jobDomain: formData.jobDomain }
          : {}),

        ...(formData.currentlyWorking === 'Yes' && !isFullStackPpcVl
          ? { jobRole: jobRole }
          : {}),
        ...(formData.currentlyWorking === 'Yes' && jobRole === 'Other'
          ? { jobRoleOther: formData.jobRole }
          : {}),

        ...(formData.currentlyWorking === 'Yes' && !isFullStackPpcVl
          ? { currentCompany: company }
          : {}),
        ...(formData.currentlyWorking === 'Yes' &&
        company === 'Other' &&
        !isFullStackPpcVl
          ? { currentCompanyOther: formData.company }
          : {}),

        // Form Data for SDE2
        ...(formData.currentlyWorking === 'Yes' && isSde2Lead
          ? {
              techStack: formData.techStack,
              workExperience: formData.workExperience,
            }
          : {}),

        formURL: pathname,
        location: buttonLocation || '',
        formStep: LEAD_GEN_UTM_CAMPAIGN_TWO,
        program,
        programID: isMasterclassRegisterPage
          ? getProgramIdMasterclass(getCurrentProgram())
          : trialProgramId
          ? trialProgramId
          : isMQALead
          ? LEAD_GEN_PROGRAM_ID_QA
          : isDataSciencePage
          ? LEAD_GEN_PROGRAM_ID_DATA_ANALYTICS
          : LEAD_GEN_PROGRAM_ID_FULL_STACK,
        applicationStage: leadStage.FULL_FILL,
        experiment: experiment,
      };

      // add UTM data
      if (utmSource) {
        if (whitelistedLeadSources.includes(utmSource))
          payload.utmSource = utmSource;
        else payload.utmSource = ERROR_LEAD_SOURCE;
      }
      if (utmMedium) payload.utmMedium = utmMedium;
      if (utmPublisher) payload.utmPublisher = utmPublisher;
      if (utmCampaign) payload.utmCampaign = utmCampaign;
      if (utmContent) payload.utmContent = utmContent;
      if (utmNetwork) payload.utmNetwork = utmNetwork;
      if (utmTerm) payload.utmTerm = utmTerm;
      if (gclID) payload.gclID = gclID;
      if (lcID) payload.lcID = lcID;
      if (fbclID) payload.fbclID = fbclID;

      if (!hasUtmParams) {
        payload.utmSource = 'Direct';
        payload.utmMedium = 'Direct Lead Capture';
        payload.utmTerm = formData.source;
      }

      // Add user location data
      extendLeadObjectWithUserLocationData(payload, userLocation);

      // Append selected slot details
      if (shouldShowDemoSlots && formData.trialSlot) {
        const selectedDemo = nextDemoSlots.find(
          (slot) =>
            compareAsc(
              new Date(slot.TrialTime),
              new Date(formData.trialSlot),
            ) === 0,
        );

        if (selectedDemo) {
          // (yyyy-MM-dd HH:mm:ss) format and conversion to IST as LeadSquared stores time in UTC which is 5:30 hours ahead
          const date = new Date(formData.trialSlot);
          payload.demoSlotStartTime = format(
            addMinutes(date, date.getTimezoneOffset()),
            'yyyy-MM-dd HH:mm:ss',
          );

          if (selectedDemo['MagicLink'])
            payload.demoSlotJoinUrl = selectedDemo['MagicLink'];

          if (selectedDemo['TrialType'])
            payload.demoSlotType = selectedDemo['TrialType'];
        }
      }

      await LeadGenerationService.updateLead(payload);
      if (onSubmitL2) {
        await onSubmitL2(payload);
      }

      //handle on demand session auto registration:
      const redirectUrlPostOtpVerification =
        await handleOnDemandSessionAutoRegistration(formData);

      //If Accelerate Page & trial status is joinRightNow:
      if (
        isDemoSlotSelectionEnabled &&
        formData.trialSlot === OTHER_DEMO_SLOT
      ) {
        sendActivityHandler(
          ELeadSquaredActivityCode.OTHER_DEMO_TRIAL_SLOT_SELECTION,
        );
      }

      // Send a GTM event for full filled form
      GTM.track(gtmEvents.LEAD_GEN_FULL_FORM_FILL, {
        url: location.href,
        email: formData.email,
        location:
          pathname === LEAD_GEN_QA_AUTOMATION_PATH ||
          pathname === LEAD_GEN_AUTOMATION_EDGE_PATH
            ? LEAD_GEN_UTM_SOURCE_QA
            : LEAD_GEN_UTM_SOURCE_DEV,
        buttonLocation: buttonLocation,
        applicationPage,
      });
      // GA event for L2 successfull submission:
      GTM.track(gtmEvents.L2_SUCCESSFULL_SUBMISSION, {
        url: location.href,
        location:
          pathname === LEAD_GEN_QA_AUTOMATION_PATH ||
          pathname === LEAD_GEN_AUTOMATION_EDGE_PATH
            ? LEAD_GEN_UTM_SOURCE_QA
            : LEAD_GEN_UTM_SOURCE_DEV,
        buttonLocation: buttonLocation,
        applicationPage,
      });

      // Send a GTM event for CWP - add session-level check
      if (
        formData.currentlyWorking === 'Yes' &&
        !sessionStorage.getItem(CWP_EVENT_FIRED_KEY)
      ) {
        GTM.track(gtmEvents.CWP_EVENT, {
          url: location.href,
          email: formData.email,
          location:
            pathname === LEAD_GEN_QA_AUTOMATION_PATH ||
            pathname === LEAD_GEN_AUTOMATION_EDGE_PATH
              ? LEAD_GEN_UTM_SOURCE_QA
              : LEAD_GEN_UTM_SOURCE_DEV,
          buttonLocation: buttonLocation,
          applicationPage,
        });

        // Mark that the event has been fired in this session
        sessionStorage.setItem(CWP_EVENT_FIRED_KEY, 'true');
      }

      GTM.leadSquaredEvent(
        ELeadSquaredActivityCode.BOOK_FREE_TRIAL_SUBMITTED,
        formData.email,
      );

      let parseUtmParams = `programID=${getProgramIdRedirect()}`;

      if (Boolean(globalState.utm)) {
        const tokenizeUtm = globalState.utm.split('&');
        tokenizeUtm.forEach((utm) => {
          if (
            !utm.startsWith('programID') &&
            !utm.startsWith('leadFlow') &&
            !utm.startsWith('leadform_open') &&
            !utm.startsWith('static_form')
          )
            parseUtmParams = parseUtmParams.concat(
              `&${utm.replaceAll('/', '')}`,
            );
        });
      }

      globalDispatch({
        type: 'SET_UTM',
        payload: parseUtmParams,
      });

      closeDialog();

      //Handle Redirection after successful lead form submission:
      const pageParam = applicationPage ? `&page=${applicationPage}` : '';
      const defaultRedirect = `/registered/v2/?${parseUtmParams}${pageParam}`;

      /**  If demo slot is appearing in the trial slot selection dropdown AND If the user selects "Attend Now" option, redirect to the ever webinar page based on the program interested.
       * Otherwise, redirect the user to the SignIn Page/OTP Page.
       */
      if (
        isDemoSlotSelectionEnabled &&
        (formData.trialSlot === OTHER_DEMO_SLOT || isOnDemandPage)
      ) {
        setRedirectUrl(redirectUrlPostOtpVerification || defaultRedirect);

        if (isPhoneVerified === 'true') {
          onOTPVerificationSuccess(
            redirectUrlPostOtpVerification || defaultRedirect,
          );
        }
      } else if (onSubmitPageTwo && isPhoneVerified === 'true') {
        await onSubmitPageTwo(formData);
      } else {
        setRedirectUrl(defaultRedirect);
        if (isPhoneVerified === 'true') {
          onOTPVerificationSuccess(defaultRedirect);
        }
      }
      if (!isPhoneVerified) setShowOTPPage(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Could not process request, please try again later.', {
        duration: 6000,
        style: { fontSize: '14px' },
        id: 'lead-gen-form-page-2',
      });

      // Log error to sentry
      Sentry.withScope(function (scope) {
        scope.setUser({ email: state.email });
        scope.setTag('lead_gen_form', 'critical');
        scope.setLevel('critical');
        Sentry.captureException(error);
      });

      // GA event for L2 unsuccessfull submission:
      GTM.track(gtmEvents.L2_UNSUCCESSFULL_SUBMISSION, {
        error: error?.message,
      });
    }
  };

  //handler function to detect when a field is focused and send the event to GA:
  const handleFocus = () => {
    if (!isFormTouched) {
      //only register one attempt for form filling:
      GTM.track(gtmEvents.L2_FORM_FILL_ATTEMPT, {
        url: location.href,
        location:
          pathname === LEAD_GEN_QA_AUTOMATION_PATH ||
          pathname === LEAD_GEN_AUTOMATION_EDGE_PATH
            ? LEAD_GEN_UTM_SOURCE_QA
            : LEAD_GEN_UTM_SOURCE_DEV,
        buttonLocation: buttonLocation,
      });
      setIsFormTouched(true);
    }
  };

  // Create a ref for the form container
  const formRef = useRef(null);

  // Function to handle scrolling to the bottom of the form
  const scrollToBottom = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  // Function to simulate clicking the payment button
  function clickPaymentButton() {
    // Find the payment button by its class name
    const paymentButton = document.querySelector('.PaymentButton');

    // Check if the button exists before attempting to click
    if (paymentButton) {
      paymentButton.click();
    } else {
      console.error('Payment button not found!');
    }
  }

  return (
    <div
      className={cn(
        'no-scrollbar h w-full overflow-y-auto rounded-[20px] bg-[#E6F4F2] p-6 drop-shadow-md md:w-[450px]',
        openFormClassName,
        `${
          isProvisionalEnrollment || isAffiliatePage ? 'h-[400px]' : 'h-[500px]'
        }`,
      )}
    >
      {/* TODO: Show the OTP Page after submit page two */}
      {showOTPPage ? (
        <OTPPage onOTPVerificationSuccess={onOTPVerificationSuccess} />
      ) : (
        <FormProvider {...hookFormTwo}>
          <form
            autoComplete="on"
            className={`mt-2 flex w-full flex-col items-stretch gap-3.5 overflow-y-auto`}
            onSubmit={hookFormTwo.handleSubmit(submitPageTwo, onError)}
            ref={formRef} // Attach the ref to the form container
          >
            <div
              className={`mt-2 flex w-full flex-col items-stretch gap-3.5 overflow-y-auto scrollbar-hide ${
                isOpenForm ? 'overflow-y-auto' : ''
              }`}
            >
              {hasProgramInterestedField ? (
                <FormDropDown
                  icon={<FontAwesomeIcon icon={faQuestion} />}
                  name="programInterested"
                  label="Program interested"
                  menu_items={generateDropDownValues(EProgramInterested)}
                  isOpenForm={isOpenForm}
                  handleFocus={handleFocus}
                />
              ) : (
                <></>
              )}

              <FormInput
                icon={<FontAwesomeIcon icon={faAddressCard} />}
                name="firstName"
                label={
                  isHomePage ||
                  isCampaignPage ||
                  hideLastName ||
                  isProvisionalEnrollment ||
                  isAffiliatePage
                    ? 'Name'
                    : 'First Name'
                }
                isOpenForm={isOpenForm}
                handleFocus={handleFocus}
              />

              {!hideLastName &&
                !isHomePage &&
                !isCampaignPage &&
                !isProvisionalEnrollment &&
                !isAffiliatePage && (
                  <FormInput
                    icon={<FontAwesomeIcon icon={faAddressCard} />}
                    name="lastName"
                    label="Last Name"
                    isOpenForm={isOpenForm}
                    handleFocus={handleFocus}
                  />
                )}
              <FormInput
                icon={<FontAwesomeIcon icon={faEnvelope} />}
                label="Email"
                name="email"
                error={hookFormTwo.formState.errors?.email?.message}
                isOpenForm={isOpenForm}
                handleFocus={handleFocus}
              />
              <div
                onClick={() => {
                  if (!isMobile) {
                    setTimeout(() => {
                      scrollToBottom(); // Call the function to scroll to the bottom
                    }, 300);
                  }

                  if (!isProvisionalEnrollment && !isAffiliatePage) {
                    setIsPhoneInputClicked(true);
                  }
                }}
                onBlur={() => {
                  const { email, phone } = hookFormTwo.watch();

                  if (
                    email &&
                    phone &&
                    (globalState.email !== email ||
                      globalState.phone !== '+' + phone) &&
                    !isProvisionalEnrollment &&
                    !isAffiliatePage
                  ) {
                    onSubmitLeadPageOne({ email, phone });
                    setEmailSubmitted(true);
                  }
                }}
              >
                <FormPhoneInput
                  error={hookFormTwo.formState.errors?.phone?.message}
                  validation={
                    hookFormTwo.formState.dirtyFields?.phone &&
                    !hookFormTwo.formState.errors?.phone
                  }
                  isOpenForm={isOpenForm}
                  handleFocus={() => {
                    handleFocus();
                  }}
                  className={'w-full'}
                />
              </div>
              {!isPhoneInputClicked ? (
                <></>
              ) : (
                <>
                  <FormAutoComplete
                    icon={<FontAwesomeIcon icon={faBook} />}
                    menu_items={generateDropDownValues(highestQualifications)}
                    name="highestQualification"
                    label="Education Qualification (Eg. BTech)"
                    isOpenForm={isOpenForm}
                    handleFocus={handleFocus}
                  />

                  <Box
                    className={`grid ${
                      isMobile
                        ? 'gap-3.5'
                        : isFullStackPpcVl
                        ? 'grid-cols-[auto]'
                        : 'grid-cols-[45%_auto] gap-x-3'
                    }`}
                  >
                    <FormDropDown
                      icon={
                        <FontAwesomeIcon icon="fa-solid fa-graduation-cap" />
                      }
                      name="graduationYear"
                      label="Graduation Year"
                      menu_items={getGraduationYearList()}
                      isOpenForm={isOpenForm}
                      handleFocus={handleFocus}
                    />
                    {!isFullStackPpcVl && (
                      <FormDropDown
                        icon={<FontAwesomeIcon icon={faBriefcase} />}
                        name="currentlyWorking"
                        label="Are you working?"
                        menu_items={generateDropDownValues(profiles)}
                        isOpenForm={isOpenForm}
                        handleFocus={handleFocus}
                      />
                    )}
                  </Box>

                  {isCurrentlyWorking && (
                    <FormDropDown
                      icon={<FontAwesomeIcon icon={faBriefcase} />}
                      menu_items={generateDropDownValues(jobDomains)}
                      name="jobDomain"
                      label="Job Domain"
                      isOpenForm={isOpenForm}
                    />
                  )}

                  {isCurrentlyWorking && domainWatcher && (
                    <FormAutoComplete
                      icon={<FontAwesomeIcon icon={faBriefcase} />}
                      menu_items={
                        isDomainTech
                          ? generateDropDownValues(jobRoleTechs)
                          : generateDropDownValues(jobRoleNonTechs)
                      }
                      name="jobRole"
                      label="Job Role"
                      isOpenForm={isOpenForm}
                    />
                  )}

                  {isCurrentlyWorking && (
                    <FormAutoComplete
                      icon={<FontAwesomeIcon icon={faBuilding} />}
                      label="Company"
                      name="company"
                      menu_items={generateDropDownValues(companies)}
                      isOpenForm={isOpenForm}
                    />
                  )}

                  {isCurrentlyWorking && isSde2Lead && (
                    <FormDropDown
                      icon={<FontAwesomeIcon icon={faUserTie} />}
                      menu_items={generateDropDownValues(workExperience)}
                      name="workExperience"
                      label="Work experience in software development"
                      isOpenForm={isOpenForm}
                    />
                  )}

                  {isCurrentlyWorking && isSde2Lead && (
                    <FormInput
                      icon={<FontAwesomeIcon icon={faBriefcase} />}
                      name="techStack"
                      label="Tech stack you work on"
                      isOpenForm={isOpenForm}
                    />
                  )}
                  {!isFullStackPpcVl && !hasUtmParams && (
                    <FormDropDown
                      icon={<FontAwesomeIcon icon={faQuestion} />}
                      name="source"
                      label="How did you hear about us?"
                      menu_items={generateDropDownValues(sources)}
                      isOpenForm={isOpenForm}
                      handleFocus={handleFocus}
                    />
                  )}

                  {shouldShowDemoSlots && !isOnDemandPage && (
                    <FormDropDown
                      icon={<FontAwesomeIcon icon={faCalendarDays} />}
                      label="Trial Workshop Slot"
                      menu_items={demoSlotMenuItems}
                      name="trialSlot"
                      isOpenForm={isOpenForm}
                      handleFocus={handleFocus}
                    />
                  )}
                </>
              )}

              <FormCheckbox
                text="I agree to receive SMS & Whatsapp communications on this number."
                name="whatsAppUpdates"
                isOpenForm={isOpenForm}
              />
            </div>

            <FormSubmitButton
              text={
                isOnDemandPage
                  ? 'Attend Now'
                  : pageTwoButtonText
                  ? pageTwoButtonText
                  : isProvisionalEnrollment || isAffiliatePage
                  ? 'Confirm Your Seat'
                  : 'Book Your Trial'
              }
              loading={loading}
              className={isOpenForm ? `flex w-full` : ''}
            />
          </form>
        </FormProvider>
      )}
    </div>
  );
}
