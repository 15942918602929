import RegistrationSuccessMessage from '@components/Masterclass/RegistrationSuccessMessage';
import React from 'react';
import SessionInfo from '../SessionInfo';
import AddToCalendar from '@components/Masterclass/AddToCalendar';
import { cn } from '@/lib/utils';
import JoinSessionButton from '@components/Masterclass/JoinSessionButton';
import EventStartTimer from '@components/Masterclass/EventStartTimer';

export default function DesktopHero({ className }) {
  return (
    <div className={cn('', className)}>
      <div className="text-v5- flex items-center justify-between gap-[60px] rounded-[10px] border border-v5-neutral-300 p-10">
        {/* Session and joining session details */}
        <div className="grid grid-cols-[auto_1fr] grid-rows-[repeat(3_auto)] gap-5">
          <RegistrationSuccessMessage className="col-[1_/_3] self-start justify-self-start" />
          <SessionInfo />
          <JoinSessionButton />
        </div>

        {/* Add To Calendar and Event Timer */}
        <div className="flex max-w-[340px] flex-col items-center gap-y-[18px]">
          <AddToCalendar />
          <EventStartTimer />
        </div>
      </div>
    </div>
  );
}
