export const CHATBOT_EVENTS = {
  // FAB Click Events
  EXTERNAL_PLATFORM_EVENT: 'extPlatformEvent',
  EXTERNAL_FAB_CLICK: {
    type: 'EXTERNAL_FAB_CLICK',
    locations: {
      REQUEST_CALLBACK: 'REQUEST_CALLBACK',
      EXTERNAL_CHATBOT_OPEN: 'EXTERNAL_CHATBOT_OPEN',
    },
  },

  // Chat Close Events
  CHAT_CLOSED: {
    type: 'CHAT_CLOSED',
    locations: {
      BROWSER_CHAT_CLOSED: 'BROWSER_CHAT_CLOSED',
      CHAT_WINDOW_CLOSED: 'CHAT_WINDOW_CLOSED',
    },
  },

  // Reset Chat Events
  CHAT_CLICK: {
    type: 'CHAT_CLICK',
    locations: {
      RESET_CHAT: 'RESET_CHAT',
    },
  },
};
