import React from 'react';
import RawHTML from '@components/Common/RawHTML';
import * as ProjectImages from './ProjectImages';

export const ProjectCard = ({ project }) => {
  const ImageComponent = project.imageType
    ? ProjectImages[`${project.imageType}Image`]
    : null;

  return (
    <div className="min-w-[165px] overflow-hidden  rounded-3xl border border-v5-neutral-300 bg-v5-neutral-200 p-4 md:min-w-[210px]">
      <div className="relative flex h-48 items-center justify-center overflow-hidden rounded-2xl   bg-v5-neutral-300">
        {ImageComponent && (
          <div className="flex h-full w-full items-center justify-center p-4">
            <ImageComponent />
          </div>
        )}
      </div>
      <div className="p-4">
        <h3 className="text-base font-medium text-black">
          <RawHTML>{project.title}</RawHTML>
        </h3>
        <p className="mt-2 text-sm text-black/80">
          <RawHTML>{project.content}</RawHTML>
        </p>
      </div>
    </div>
  );
};
