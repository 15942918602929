import React from 'react';
// import Google from '../../../images/google-cal-icon.png';
import ContextLabel from '../ContextLabel';
import useRegisterMetadata from '@src/hooks/Masterclass/useRegisterMetadata';
import { cn } from '@/lib/utils';
import { StaticImage } from 'gatsby-plugin-image';
export default function AddToCalendar({ wrapperClassName, ctaClassName }) {
  const { getEventDetails, handleAddToCalendar } = useRegisterMetadata();
  const sessionDetail = getEventDetails();

  return (
    <div className={cn('flex flex-col items-center', wrapperClassName)}>
      {/* Context Lable */}
      <ContextLabel
        text="😇 Dont miss out on key learning"
        className="bg-black px-6 text-xs md:px-4"
      />

      {/*  Add to calendar Container */}
      <div className="flex flex-col justify-between gap-y-4 rounded-[10px] border border-v5-neutral-300 py-8 px-6 md:gap-y-7 md:px-10 lg:px-4">
        {/* Label */}
        <div className="text-center font-rubik text-sm leading-normal text-v5-neutral-500">
          Secure your spot by adding this event to your calendar - you're{' '}
          <strong>70%</strong> more likely to attend
        </div>

        {/* Add to Calendar Icon */}
        <div
          className={cn(
            'leading-1 flex h-10 w-full cursor-pointer items-center justify-center gap-x-2 rounded-md border border-[#2463CB] font-manrope text-sm font-bold text-[#2463CB] md:gap-x-1 md:text-lg',
            ctaClassName,
          )}
          onClick={handleAddToCalendar}
        >
          <StaticImage
            src="../../../images/google-cal-icon.png"
            alt="A dinosaur"
            layout="fixed"
            width={20}
            height={20}
          />
          <span>Add To Calendar</span>
        </div>
      </div>
    </div>
  );
}
