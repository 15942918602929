import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import HamburgerIcon from '@src/images/HamburgerIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import { useLocation } from '@reach/router';
import TopStrip from '@components/PlacementReportTopStrip';
import useProvisionalEnrollment from '@src/hooks/useProvisionalEnrollment';

const PPCNavbar = () => {
  const [open, setOpen] = useState(false);

  const { isProvisionalEnrollment } = useProvisionalEnrollment();

  const location = useLocation();

  const scrollToSectionHandler = (anchor) => {
    window.scrollTo(0, 0); // Reset scroll position
    setTimeout(() => {
      const targetElement = document.getElementById(anchor);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  const anchorLinks = [
    { title: 'Curriculum', anchor: 'curriculum' },
    { title: 'Crio Success Stories', anchor: 'crio-success-stories' },
    { title: 'Projects', anchor: 'projects' },
    { title: 'Career Services', anchor: 'career-services' },
    { title: 'FAQ', anchor: 'faq' },
  ];

  if (isProvisionalEnrollment) {
    return (
      <div
        className="fixed top-0 left-0 z-[1000] bg-red-800"
        id="navbar-wrapper"
      >
        <div className="flex-col">
          <div className="flex h-[80px] w-[100dvw] items-center justify-center bg-v5-green-100 px-5">
            <div>
              <StaticImage
                loading="eager"
                src="../../images/Crio_Dark.png"
                placeholder="none"
                alt="Crio.Do"
                className="h-[40px] w-[100px] md:w-[130px]"
                imgStyle={{ objectFit: 'contain' }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed top-0 left-0 z-[1000] bg-red-800" id="navbar-wrapper">
      {open ? (
        <div className="flex h-[100dvh] w-[100dvw] flex-col gap-2 bg-white p-4">
          <TopStrip />
          <div className="flex w-full items-center justify-between">
            <div>
              <StaticImage
                loading="eager"
                src="../../images/Crio_Dark.png"
                placeholder="none"
                alt="Crio.Do"
                className="h-[40px] w-[100px] md:w-[130px]"
                imgStyle={{ objectFit: 'contain' }}
              />
            </div>
            <div
              className="p-4"
              onClick={() => {
                setOpen(false);
              }}
            >
              <FontAwesomeIcon
                icon={['fas', 'times']}
                className="text-[24px] text-v5-neutral-600"
              ></FontAwesomeIcon>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            {anchorLinks.map((link) => (
              <span
                className="flex h-full cursor-pointer items-center justify-start p-3 font-rubik text-[18px] font-bold"
                key={link.anchor}
                onClick={() => {
                  setOpen(false);
                  // Track the event:
                  GTM.track(gtmEvents.NAVBAR_EVENT, {
                    pathname: location.pathname,
                    type: 'NAVBAR_ANCHOR_LINK_CLICK',
                    anchorLinks: link.anchor,
                    location: 'top_strip',
                  });
                  scrollToSectionHandler(link.anchor);
                }}
              >
                {link.title}
              </span>
            ))}
          </div>
        </div>
      ) : (
        <div className="flex-col">
          <TopStrip />
          <div className="flex h-[80px] w-[100dvw] items-center justify-between bg-v5-green-100 px-5">
            <div
              onClick={() => {
                scrollToSectionHandler('top');
              }}
            >
              <StaticImage
                loading="eager"
                src="../../images/Crio_Dark.png"
                placeholder="none"
                alt="Crio.Do"
                className="h-[40px] w-[100px] md:w-[130px]"
                imgStyle={{ objectFit: 'contain' }}
              />
            </div>
            <div className="hidden h-full md:flex">
              {anchorLinks.map((link) => (
                <span
                  className="flex h-full cursor-pointer items-center justify-center px-5 font-manrope font-bold"
                  key={link.anchor}
                  onClick={() => {
                    // Track the event:
                    GTM.track(gtmEvents.NAVBAR_EVENT, {
                      pathname: location.pathname,
                      type: 'NAVBAR_ANCHOR_LINK_CLICK',
                      anchorLinks: link.anchor,
                      location: 'top_strip',
                      device: 'mobile',
                    });
                    scrollToSectionHandler(link.anchor);
                  }}
                >
                  {link.title}
                </span>
              ))}
            </div>
            <div
              className="flex h-full w-[17px] items-center justify-center md:hidden"
              onClick={() => {
                setOpen(true);
              }}
            >
              <HamburgerIcon />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PPCNavbar;
