/**
 * Handles validation of user inputs based on validation schemas
 */

import { validationSchemas } from '../config/validationSchemas';

/**
 * Validates user input against a validation schema
 * @param {string} input - User input to validate
 * @param {object} validationConfig - Validation configuration from schema
 * @returns {object} Validation result with isValid flag and optional error message
 */
export const validateInput = (input, validationConfig) => {
  if (!input || !validationConfig) {
    return { isValid: false, errorMessage: 'Invalid input' };
  }

  // Get schema for this validation type
  const schema = validationSchemas[validationConfig.type];
  if (!schema) return { isValid: true };

  // Clean input using schema's cleaning function
  const cleanedInput = schema.cleaningFunction(input);

  // Run additional validations first
  for (const validation of schema.additionalValidations) {
    if (validation.check(cleanedInput)) {
      return {
        isValid: false,
        errorMessage: validation.errorMessage,
      };
    }
  }

  // Run regex validation
  const isValid = schema.regex.test(cleanedInput);
  return {
    isValid,
    errorMessage: isValid ? '' : schema.errorMessage,
  };
};
