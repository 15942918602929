import React from 'react';
import { Dialog } from '@mui/material';
import Times from '@src/images/icons/Times';
import { StaticImage } from 'gatsby-plugin-image';
import { useLocation } from '@reach/router';

const AffiliateSuccessPopup = ({ isOpen, onClose }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const couponCode = searchParams.get('coupon_code');

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '100dvw',
          width: '950px',
          margin: 'auto 1rem',
          borderRadius: '21px',
          overflow: 'hidden',
          boxShadow: 'none',
          background: 'white',
        },
      }}
    >
      <div className="relative">
        {/* Close button */}
        <div
          className="absolute top-5 right-5 z-[1] h-[12px] cursor-pointer md:top-10 md:right-10 md:h-[18px]"
          onClick={onClose}
        >
          <Times />
        </div>

        {/* Top left corner design */}
        <div className="absolute top-0 left-0">
          <StaticImage
            src="../../../images/affiliate/affiliatePopupLeftCornerDesign.png"
            alt="Top left design"
            placeholder="blurred"
            className="w-[75px] md:w-[135px]"
          />
        </div>

        {/* Bottom right corner design */}
        <div className="absolute bottom-0 right-0">
          <StaticImage
            src="../../../images/affiliate/affiliatePopupRightCornerDesign.png"
            alt="Bottom right design"
            placeholder="blurred"
            className="w-[75px] md:w-[135px]"
          />
        </div>

        {/* Content */}
        <div className="flex flex-col items-center justify-center p-8 text-center font-manrope md:p-16">
          <h1 className="mb-4  text-2xl font-extrabold text-[#0A2540] md:text-4xl">
            You're All Set!
          </h1>

          <p className="mb-8  text-lg text-[#4A5568] md:text-xl">
            Thank You For Confirming Your Seat With Us!
          </p>

          <div className="mb-8 w-full max-w-[800px] rounded-lg border border-v5-neutral-300 p-4 font-medium md:p-8">
            <p className="mb-4  text-base text-[#4A5568] md:text-lg">
              <strong>Please Note:</strong> Now That You've Reserved Your Seat,
              Share The Coupon Code <br />{' '}
              <strong className="mb-4  font-bold capitalize text-v5-green-200">
                {couponCode}
              </strong>{' '}
              With Your Program Advisor During The Program Enrollment Process To
              Unlock Your Benefits.
            </p>
          </div>

          <div className="flex items-center gap-2">
            <p className="text-lg text-[#4A5568] md:text-xl ">
              We Can't Wait To Welcome You To{' '}
              <span className="text-lg font-bold text-[#0A2540] md:text-xl">
                Crio.Do!
              </span>
            </p>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AffiliateSuccessPopup;
