import React from 'react';
import { Message } from '@chatscope/chat-ui-kit-react';
import ChatSwiperCarousel from '../ChatSwiperCarousel';
import { ChatGrid } from '../ChatGrid';
import { ChatSection } from './ChatSection';
import { ChatSectionWithCarousel } from './ChatSectionWithCarousel';
import { ChatCallToAction } from './ChatCallToAction';
import { ChatSectionWithSubSection } from './ChatSectionWithSubSection';
import { ChatRedirectButton } from './ChatRedirectButton';
import RawHTML from '@components/Common/RawHTML';

export const ChatResponseGroup = ({ messages }) => {
  const renderMessageContent = (msg, isProcessing, isTyping) => {
    if (msg.type === 'text') {
      // For chatbot responses, we already have HTML content, so prevent newline conversion
      const cleanMessage = msg.message?.includes('<')
        ? msg.message
        : msg.message?.replace(/\n/g, '<br />');
      return (
        <Message.CustomContent>
          <RawHTML className="chatbot-ai-response">{cleanMessage}</RawHTML>
        </Message.CustomContent>
      );
    }

    return (
      <Message.CustomContent>
        {msg.type === 'carousel' && <ChatSwiperCarousel items={msg.payload} />}
        {msg.type === 'grid' && <ChatGrid items={msg.payload} />}
        {msg.type === 'section' && <ChatSection items={msg.payload} />}
        {msg.type === 'section_with_carousel' && (
          <div>
            <ChatSectionWithCarousel items={msg.payload} />
          </div>
        )}
        {msg.type === 'callToAction' && (
          <ChatCallToAction
            text={msg.payload.text}
            link={msg.payload.link}
            programInterested={msg.payload?.programInterested}
          />
        )}
        {msg.type === 'section_with_sub_section' && (
          <ChatSectionWithSubSection items={msg.payload} />
        )}
        {msg.type === 'redirectButton' && (
          <ChatRedirectButton text={msg.payload.text} link={msg.payload.link} />
        )}
      </Message.CustomContent>
    );
  };

  return (
    <div className="chat-response-group my-2 mb-4 max-w-[95%] rounded-lg bg-[#F2FBF7] p-4">
      <div className="flex flex-col gap-4">
        {messages.map((msg, index) => (
          <Message
            key={index}
            model={{
              message: msg.message,
              sentTime: msg.sentTime,
              sender: msg.sender,
              direction: msg.direction,
              position: msg.position,
            }}
            className="text-black"
          >
            {renderMessageContent(msg)}
          </Message>
        ))}
      </div>
    </div>
  );
};
