import { countryCodes } from '../constants/country-codes/index.js';

export function timeToMinutes(timeString) {
  // Split the time string into hours, minutes, seconds, and milliseconds
  const [hours, minutes, seconds] = timeString.split(':').map(parseFloat);

  // Calculate the total minutes
  const totalMinutes = hours * 60 + minutes + seconds / 60;

  return totalMinutes;
}

export const getUniqueId = function () {
  let uniqueId = localStorage.getItem('user_unique_id');

  if (!uniqueId) {
    uniqueId = Date.now().toString(36) + Math.random().toString(36).substr(2);
    localStorage.setItem('user_unique_id', uniqueId);
  }

  return uniqueId;
};

export const getQueryParams = (url) => {
  let queryParams = {};
  let queryString = url.split('?')[1]; // Get the query string part of the URL
  if (queryString) {
    let pairs = queryString.split('&'); // Split the query string into individual key-value pairs
    pairs.forEach((pair) => {
      let [key, value] = pair.split('='); // Split each key-value pair into separate key and value
      queryParams[decodeURIComponent(key)] = decodeURIComponent(value); // Decode and add to the queryParams object
    });
  }
  return queryParams;
};

export const getCountryCodeAndPhone = (phone) => {
  let countryCode = '';
  let phoneNum = '';
  // Iterate through country codes to find the matching prefix
  for (const code of countryCodes) {
    if (phone.startsWith(code)) {
      countryCode = code.substring(1);
      phoneNum = phone.substring(code.length);
      break;
    }
  }

  return { countryCode, phoneNum };
};

export const getSessionStartDate = (isAccelerateJoinSessionPage) => {
  const day = new Date().getDay();

  let currentDate = new Date();

  if (day === 6 || day === 0) {
    currentDate.setHours(14, 0, 0, 0);
  } else if (isAccelerateJoinSessionPage) {
    currentDate.setHours(19, 0, 0, 0);
  } else {
    currentDate.setHours(20, 30, 0, 0);
  }

  return new Date(currentDate);
};

export function getUtmAttributes(activityCode) {
  const mappings = {
    utm_source: {
      mx_Custom_1: [
        317, 321, 326, 330, 319, 318, 434, 461, 462, 467, 329, 472, 473, 474,
        315, 405, 406,
      ],
      mx_Custom_6: [289],
      mx_Custom_10: [377],
      mx_Custom_2: [
        338, 322, 327, 324, 331, 335, 316, 334, 336, 475, 476, 477, 320, 402,
        403, 404, 456,
      ],
      mx_Custom_3: [373, 401, 453, 463],
      mx_Custom_31: [250],
      mx_Custom_8: [438],
      mx_Custom_4: [460],
    },
    utm_medium: {
      mx_Custom_2: [
        317, 321, 326, 330, 319, 318, 434, 461, 462, 467, 329, 472, 473, 474,
        315, 405, 406,
      ],
      mx_Custom_7: [289],
      mx_Custom_11: [377],
      mx_Custom_3: [
        338, 322, 327, 324, 331, 335, 316, 334, 336, 475, 476, 477, 320, 402,
        403, 404, 456,
      ],
      mx_Custom_4: [373, 401, 453, 463],
      mx_Custom_32: [250],
      mx_Custom_9: [438],
      mx_Custom_5: [460],
    },
    utm_campaign: {
      mx_Custom_3: [
        317, 321, 326, 330, 319, 318, 434, 461, 462, 467, 329, 472, 473, 474,
        315, 405, 406,
      ],
      mx_Custom_8: [289],
      mx_Custom_12: [377],
      mx_Custom_4: [
        338, 322, 327, 324, 331, 335, 316, 334, 336, 475, 476, 477, 320, 402,
        403, 404, 456,
      ],
      mx_Custom_5: [373, 401, 453, 463],
      mx_Custom_33: [250],
      mx_Custom_10: [438],
      mx_Custom_6: [460],
    },
    utm_content: {
      mx_Custom_4: [
        317, 321, 326, 330, 319, 318, 434, 461, 462, 467, 329, 472, 473, 474,
        315, 405, 406,
      ],
      mx_Custom_9: [289],
      mx_Custom_13: [377],
      mx_Custom_5: [
        338, 322, 327, 324, 331, 335, 316, 334, 336, 475, 476, 477, 320, 402,
        403, 404, 456,
      ],
      mx_Custom_6: [373, 401, 453, 463],
      mx_Custom_34: [250],
      mx_Custom_11: [438],
      mx_Custom_7: [460],
    },
    utm_term: {
      mx_Custom_5: [
        317, 321, 326, 330, 319, 318, 434, 461, 462, 467, 329, 472, 473, 474,
        315, 405, 406,
      ],
      mx_Custom_10: [289],
      mx_Custom_14: [377],
      mx_Custom_6: [
        338, 322, 327, 324, 331, 335, 316, 334, 336, 475, 476, 477, 320, 402,
        403, 404, 456,
      ],
      mx_Custom_7: [373, 401, 453, 463],
      mx_Custom_35: [250],
      mx_Custom_12: [438],
      mx_Custom_8: [460],
    },
    utm_network: {
      mx_Custom_6: [
        317, 321, 326, 330, 319, 318, 434, 461, 462, 467, 329, 472, 473, 474,
        315, 405, 406,
      ],
      mx_Custom_11: [289],
      mx_Custom_15: [377],
      mx_Custom_7: [
        338, 322, 327, 324, 331, 335, 316, 334, 336, 475, 476, 477, 320, 402,
        403, 404, 456,
      ],
      mx_Custom_8: [373, 401, 453, 463],
      mx_Custom_36: [250],
      mx_Custom_13: [438],
      mx_Custom_9: [460],
    },
    utm_publisher: {
      mx_Custom_7: [
        317, 321, 326, 330, 319, 318, 434, 461, 462, 467, 329, 472, 473, 474,
        315, 405, 406,
      ],
      mx_Custom_12: [289],
      mx_Custom_16: [377],
      mx_Custom_8: [
        338, 322, 327, 324, 331, 335, 316, 334, 336, 475, 476, 477, 320, 402,
        403, 404, 456,
      ],
      mx_Custom_9: [373, 401, 453, 463],
      mx_Custom_37: [250],
      mx_Custom_14: [438],
      mx_Custom_10: [460],
    },
  };

  const result = {};
  for (const [key, mapping] of Object.entries(mappings)) {
    for (const [utm, codes] of Object.entries(mapping)) {
      if (codes.includes(activityCode)) {
        result[key] = utm;
        break;
      }
    }
  }

  return result;
}
