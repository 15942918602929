import React, { useContext, useRef, useState } from 'react';
import { ControlledMenu } from '@szhsin/react-menu';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Typography from '@mui/material/Typography';
import { useAuth } from '@src/firebase/useAuth';
import { doLoginToBackend } from '../../api/index';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'react-hot-toast';
import '@szhsin/react-menu/dist/index.css';
import { ERoutes } from '@src/constants/routes/index';
import TopStrip from '@components/PlacementReportTopStrip/index';
import CrioButton from '@components/v5/CrioButton/index';
import { EProgramIDs, EPrograms } from '@src/constants/data/programs/index';
import SaleTopStrip from '@components/v5/Sale/SaleTopStrip';
import { navigate } from 'gatsby'; //import navigate from gatsby
import { GlobalStateContext } from '@src/context/GlobalContextProvider';
import { StaticQuery, graphql } from 'gatsby';
import BottomSheet from '@components/BottomSheet/index';
import { GTM } from '../../analytics/gtm';
import { gtmEvents } from '../../analytics/gtmEventsConstant';
import { GlobalDispatchContext } from '@src/context/GlobalContextProvider';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import {
  LEAD_GEN_NAVIGATION,
  LEAD_GEN_FORM_OPEN,
} from '../../constants/LeadGenerationConstants/index';
import { useQuery } from '@components/extra/useQuery';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';
import useSendActivities from '@src/hooks/useSendActivities';

const Navbar = ({ hideCTA, dark, CTAProgram }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useContext(GlobalDispatchContext);
  const [expanded, setExpanded] = useState(false);
  const state = useContext(GlobalStateContext);
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const { sendActivityHandler } = useSendActivities();

  const shouldOpenLeadForm = useQuery('leadform_open') === 'true';

  const programQuery = graphql`
    query {
      allStrapiPrograms {
        ...allStrapiProgramsFragment
      }
    }
  `;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const auth = useAuth();

  const handlePostFirebaseLogin = async (idToken, cb) => {
    let response = await doLoginToBackend(idToken);

    if (response) {
      cb();
    } else {
      toast.error(
        'Authentication failed due to poor network connection. Please refresh your browser and try again.',
        { duration: 8000, style: { fontSize: '14px' }, id: 'auth' },
      );
    }
  };

  async function handleLogin(e) {
    GTM.track(gtmEvents.USER_SIGNIN);
    sendActivityHandler(ELeadSquaredActivityCode.SIGN_IN_CLICKED);

    e.preventDefault();
    if (auth?.user) {
      const idToken = await auth.getIdToken();

      // track signup in web-engage
      GTM.track(gtmEvents.USER_SIGNEDUP, {
        email: auth?.user?.email,
        displayName: auth?.user?.displayName,
      });

      handlePostFirebaseLogin(idToken, () => {
        window.open('/learn/home/', '_self');
      });
      return;
    }
    auth.signin().then(async (res) => {
      const idToken = res.idToken;

      // track signup in web-engage
      GTM.track(gtmEvents.USER_SIGNEDUP, {
        email: res?.user?.email,
        displayName: res?.user?.displayName,
      });

      handlePostFirebaseLogin(idToken, () => {
        window.open('/learn/home/', '_self');
      });

      return;
    });
  }

  // <-----------  LINKS  ------------>

  const programLinks = [
    {
      title: EPrograms[EProgramIDs.FELLOWSHIP].title,
      subtitle:
        'Upskill and land your dream career as an AI-powered Full-Stack or Backend developer - Assured!',
      link: ERoutes.FELLOWSHIP,
      icon: (
        <StaticImage
          loading="lazy"
          src="../../images/placements/Icons/Fellowship.png"
          placeholder="blurred"
          alt="Crio.Do"
          imgStyle={{ objectFit: 'contain' }}
        />
      ),
    },
    {
      title: EPrograms[EProgramIDs.QA_AUTOMATION_TESTING].title,
      subtitle:
        'Get practical work experience of automation testing & get software testing roles in top companies- Assured!',
      link: ERoutes.QA_AUTOMATION_TESTING,
      icon: (
        <StaticImage
          loading="lazy"
          src="../../images/placements/Icons/QA.png"
          placeholder="blurred"
          alt="Crio.Do"
          imgStyle={{ objectFit: 'contain' }}
        />
      ),
    },
    {
      title: EPrograms[EProgramIDs.DATA_SCIENCE].title,
      subtitle:
        'Master the in-demand, AI-powered data analytics skills to land top data analyst roles - Assured!',
      link: ERoutes.DATA_SCIENCE,
      icon: (
        <StaticImage
          loading="lazy"
          src="../../images/placements/Icons/DataScience.webp"
          placeholder="blurred"
          alt="Crio.Do"
          imgStyle={{ objectFit: 'contain' }}
        />
      ),
    },
    {
      title: EPrograms[EProgramIDs.SYSTEM_DESIGN].title,
      subtitle:
        'Master advanced software development and design skills with hands-on experience in System Design - Assured!',
      link: ERoutes.SYSTEM_DESIGN,
      icon: (
        <StaticImage
          loading="lazy"
          src="../../images/SystemDesignIcon.webp"
          placeholder="blurred"
          alt="Crio.Do"
          imgStyle={{ objectFit: 'contain' }}
        />
      ),
    },
  ];

  const links = [
    { name: 'Hire from Crio', link: ERoutes.RECRUIT },
    { name: 'Masterclass', link: ERoutes.MASTERCLASS },
    { name: 'Projects', link: ERoutes.PROJECTS },
    { name: 'Blog', link: ERoutes.BLOG },
  ];

  const placementLinks = [
    {
      title: 'Placement Stats',
      subtitle:
        'Helping our Learners Get the Best Career Breakthroughs and into the Best Companies',
      link: ERoutes.PLACEMENTS,
      icon: (
        <StaticImage
          loading="lazy"
          src="../../images/placements/Icons/PlacementStats.png"
          placeholder="blurred"
          alt="Crio.Do"
          imgStyle={{ objectFit: 'contain' }}
        />
      ),
    },
    {
      title: 'Success Stories',
      subtitle:
        'Hear from some of our learners who took their careers to new heights',
      link: ERoutes.SUCCESS_STORIES,
      icon: (
        <StaticImage
          loading="lazy"
          src="../../images/placements/Icons/SuccessStories.png"
          placeholder="blurred"
          alt="Crio.Do"
          imgStyle={{ objectFit: 'contain' }}
        />
      ),
    },
  ];

  // <-----------  LINKS  ------------>

  const handleGoToProgramRegistration = (program) => {
    let hash = btoa(program.PrimaryProgramID + program.typeform);

    if (state.registrationDetails && state.registrationDetails[hash]) {
      navigate(`/registered/?id=${state.registrationDetails[hash].id}`);
    } else {
      navigate('/registration', {
        state: {
          link: program.typeform,
          type: program.PrimaryProgramID,
          location: 'HEADER',
          program: program,
        },
      });
    }
  };

  const onClose = (bottomSheetProps) => {
    setShowBottomSheet(false);
    dispatch({
      type: 'USER_IS_APPLYING',
      payload: false,
    });
    let event = bottomSheetProps.closeEvent;
    GTM.track(gtmEvents[event], {
      type: bottomSheetProps.type,
      location: bottomSheetProps.location,
      ...(bottomSheetProps.meId && { meId: bottomSheetProps.meId }),
      ...(bottomSheetProps.secondaryType && {
        secondaryType: bottomSheetProps.secondaryType,
      }),
    });
  };

  const onOpen = (bottomSheetProps) => {
    setOpen(false);
    dispatch({
      type: 'USER_IS_APPLYING',
      payload: true,
    });
    setShowBottomSheet(true);
    let event = bottomSheetProps.openEvent;
    GTM.track(gtmEvents[event], {
      type: bottomSheetProps.type,
      location: bottomSheetProps.location,
      ...(bottomSheetProps.meId && { meId: bottomSheetProps.meId }),
      ...(bottomSheetProps.secondaryType && {
        secondaryType: bottomSheetProps.secondaryType,
      }),
    });
  };

  return (
    <StaticQuery
      query={programQuery}
      render={(data) => {
        const trialProgram = data.allStrapiPrograms.nodes.find(
          (ele) => ele.PrimaryProgramID === 'FREE_TRIAL',
        );

        const bottomSheetProps = {
          program: trialProgram,
          type: trialProgram.PrimaryProgramID,
          meId: trialProgram.PrimaryProgramID,
          link: trialProgram.typeform,
          location: 'HOME_PROJECTS',
          openEvent: 'FREE_ME_START_NOW_CLICKED',
          closeEvent: 'FREE_ME_EMAIL_FORM_CLOSED',
        };

        return (
          <>
            <nav>
              <div
                className={`flex h-[60px] w-full items-center justify-between px-6 md:h-[65px]  ${
                  !dark ? 'bg-white text-black' : 'bg-v5-green-700 text-white'
                }`}
              >
                <div>
                  <Link to="/">
                    <div>
                      {!dark ? (
                        <StaticImage
                          loading="eager"
                          src="../../images/Crio_Dark.png"
                          placeholder="none"
                          alt="Crio.Do"
                          className="h-[40px] w-[100px] md:w-[130px]"
                          imgStyle={{ objectFit: 'contain' }}
                        />
                      ) : (
                        <StaticImage
                          loading="eager"
                          src="../../images/Crio_Light.png"
                          placeholder="none"
                          alt="Crio.Do"
                          className="h-[40px] w-[100px] md:w-[130px]"
                          imgStyle={{ objectFit: 'contain' }}
                        />
                      )}
                    </div>
                  </Link>
                </div>
                <div className="px-3 py-1 pr-1 md:hidden">
                  {!open ? (
                    <FontAwesomeIcon
                      size="1x"
                      icon={['far', 'bars']}
                      onClick={() => {
                        setOpen(true);
                      }}
                    ></FontAwesomeIcon>
                  ) : (
                    <FontAwesomeIcon
                      size="1x"
                      icon={['far', 'times']}
                      onClick={() => {
                        setOpen(false);
                      }}
                    ></FontAwesomeIcon>
                  )}
                </div>
                <div className="hidden h-full items-center gap-4 font-manrope text-sm font-bold md:flex">
                  <MenuPopover
                    programLinks={programLinks}
                    placementLinks={placementLinks}
                    name="Programs"
                  />
                  {links.map((el, idx) => (
                    <Link to={el.link} key={idx}>
                      {el.name}
                    </Link>
                  ))}
                  <MenuPopover
                    programLinks={programLinks}
                    placementLinks={placementLinks}
                    name="Placements"
                  />
                  <>
                    <div>
                      <CrioButton
                        variant="secondary"
                        className={`h-[38px] ${
                          dark ? 'border-white text-white' : ''
                        }`}
                        onClick={handleLogin}
                      >
                        {'Sign In'}
                      </CrioButton>
                    </div>
                    {!hideCTA && (
                      <LeadGenerationButton
                        buttonLocation={
                          shouldOpenLeadForm
                            ? LEAD_GEN_FORM_OPEN
                            : LEAD_GEN_NAVIGATION
                        }
                        type="Navigation"
                        shouldOpenLeadForm={shouldOpenLeadForm}
                      />
                    )}
                  </>
                </div>
              </div>
              {open && (
                <div className="fixed z-30 w-screen bg-white px-6 pb-6">
                  <div className="my-6 flex flex-col gap-4 font-rubik text-xl font-bold">
                    <div className="programs flex items-center justify-between">
                      <Accordion
                        className="programsAccordion"
                        expanded={expanded === 'programs'}
                        onChange={handleChange('programs')}
                      >
                        <AccordionSummary
                          expandIcon={
                            <FontAwesomeIcon
                              icon={['fal', 'angle-down']}
                              size={'lg'}
                            ></FontAwesomeIcon>
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{
                            padding: '0',
                            margin: '0',
                            minHeight: '0px',
                          }}
                          className="programAccordionSummary"
                        >
                          <Typography className="font-rubik text-xl font-bold">
                            Programs
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="my-2 p-0">
                          <MenuLinks linksArray={programLinks} />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    {links.map((el, idx) => (
                      <Link to={el.link} key={idx}>
                        {el.name}
                      </Link>
                    ))}
                    <div className="programs flex items-center justify-between">
                      <Accordion
                        className="programsAccordion"
                        expanded={expanded === 'placements'}
                        onChange={handleChange('placements')}
                      >
                        <AccordionSummary
                          expandIcon={
                            <FontAwesomeIcon
                              icon={['fal', 'angle-down']}
                              size={'lg'}
                            ></FontAwesomeIcon>
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="programAccordionSummary"
                          style={{
                            padding: '0',
                            margin: '0',
                            fontWe: 'bold',
                            fontSize: '18px',
                            minHeight: '0px',
                          }}
                        >
                          <Typography className="font-rubik text-xl font-bold">
                            Placement
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="my-2 p-0">
                          <MenuLinks linksArray={placementLinks} />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <>
                      <div>
                        <CrioButton
                          variant="secondary"
                          className="h-[38px] w-full"
                          onClick={handleLogin}
                        >
                          {' '}
                          {'Sign In'}
                        </CrioButton>
                      </div>
                      {!hideCTA && (
                        <LeadGenerationButton
                          buttonLocation={
                            shouldOpenLeadForm
                              ? LEAD_GEN_FORM_OPEN
                              : LEAD_GEN_NAVIGATION
                          }
                          type="Navigation"
                          shouldOpenLeadForm={shouldOpenLeadForm}
                        />
                      )}
                    </>
                  </div>
                </div>
              )}
              <style>
                {`.programsAccordion {
               box-shadow: none;
               width: 100%;
             }
             
             .programAccordionSummary .MuiAccordionSummary-content {
               margin: 0;
             }
      
             .dropdown {
              box-shadow: none;
             }
             `}
              </style>
            </nav>
            {showBottomSheet && (
              <BottomSheet
                onClose={() => onClose(bottomSheetProps)}
                {...bottomSheetProps}
              >
                {React.cloneElement(
                  <CrioButton variant="primary" className="w-full">
                    Book Your Trial
                  </CrioButton>,
                  {
                    ...bottomSheetProps,
                  },
                )}
              </BottomSheet>
            )}
          </>
        );
      }}
    />
  );
};

const MenuPopover = ({ name, programLinks, placementLinks }) => {
  const MenuHeading = ({ name }) => {
    return (
      <div
        ref={ref}
        onMouseEnter={() => setState('open')}
        className="-mr-4 cursor-pointer text-sm "
      >
        {name}
        <span className="ml-2 mt-1">
          <FontAwesomeIcon
            icon={['fal', 'angle-down']}
            size={'lg'}
          ></FontAwesomeIcon>
        </span>
      </div>
    );
  };

  const ref = useRef(null);
  const [state, setState] = useState();

  return (
    <>
      <MenuHeading name={name} />
      <ControlledMenu
        state={state}
        anchorRef={ref}
        onMouseLeave={() => setState('closed')}
        onClose={() => setState('closed')}
        className="menuItem"
        menuStyles={{
          borderRadius: '15px',
          backgroundColor: '#F2FBF7',
          marginTop: '0.5rem',
          maxWidth: '450px',
        }}
      >
        <MenuLinks
          linksArray={name === 'Programs' ? programLinks : placementLinks}
        />
      </ControlledMenu>
    </>
  );
};

const MenuLinks = ({ linksArray }) => {
  return (
    <div className="flex flex-col gap-2">
      {linksArray.map((el) => {
        return (
          <Link
            to={el.link}
            key={el.title}
            className="transition-all hover:opacity-70"
          >
            <div className="mb-2 flex justify-between gap-2 rounded-lg bg-v5-neutral-200 p-4 last:mb-0">
              <div className="pointer-events-none h-[30px] w-[30px]">
                {el.icon}
              </div>
              <div className="flex-1">
                <h5 className="text-sm">{el.title}</h5>
                <p className="text-sm text-v5-neutral-400">{el.subtitle}</p>
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

const getTopStrip = (sale, program) => {
  switch (sale) {
    case 'true':
      return <SaleTopStrip program={program} />;
    default:
      return <TopStrip />;
  }
};

const Navigation = ({
  hideTopStrip,
  hideCTA,
  dark,
  program,
  sale,
  CTAProgram,
}) => {
  return (
    <div className="fixed z-[1000] w-screen">
      {hideTopStrip ? '' : getTopStrip(sale, program)}
      <Navbar hideCTA={hideCTA} dark={dark} CTAProgram={CTAProgram} />
    </div>
  );
};

export default Navigation;
