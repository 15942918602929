import React, { useContext, useMemo } from 'react';
import { faLockKeyhole } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import ContextLabel from '../ContextLabel';
import useRegisterMetadata from '@src/hooks/Masterclass/useRegisterMetadata';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';
import {
  getButtonClasses,
  redirectToEverWebinar,
} from '@src/utils/Masterclass';
import useSessionAvailability from '@src/hooks/Masterclass/useSessionAvailability';

const JoinSessionButton = () => {
  // Retrieve event details and state
  const { getEventDetails, getEventSlug } = useRegisterMetadata();
  const { sessionStartDateTime } = getEventDetails();
  const state = useContext(GlobalStateContext);
  const eventSlug = getEventSlug();

  // Check session availability
  const isSessionAvailable = useSessionAvailability(sessionStartDateTime);

  // Memoize button classes to prevent unnecessary recalculations
  const buttonClasses = useMemo(
    () => getButtonClasses(isSessionAvailable),
    [isSessionAvailable],
  );

  return (
    <div className="col-[2_/_3] flex flex-col items-center justify-self-start">
      {/* Display context label when session is not yet available */}
      {!isSessionAvailable && (
        <ContextLabel
          text="Link activates 15 min before session"
          className="bg-black text-[5px] md:text-[10px]"
        />
      )}

      {/* Join session button with dynamic state */}
      <Button
        className={buttonClasses}
        type="contained"
        disabled={!isSessionAvailable}
        onClick={() => redirectToEverWebinar(state, eventSlug)}
      >
        Join the Session
        {/* Show lock icon when session is not available */}
        {!isSessionAvailable && (
          <FontAwesomeIcon icon={faLockKeyhole} className="ml-2" />
        )}
      </Button>
    </div>
  );
};

export default JoinSessionButton;
