import LeadGenerationService from '../../../../utils/lead-generation';
import {
  LEAD_GEN_DEVICE_MOBILE,
  LEAD_GEN_DEVICE_DESKTOP,
} from '@src/constants/LeadGenerationConstants';

// Use window.innerWidth instead of hook
const isMobileDevice = () => window.innerWidth <= 768;

export const generateLead = async (email, phone, dispatch) => {
  try {
    const data = {
      email,
      phone: phone,
      formURL: window.location.pathname,
      location: 'WEBSITE_CHAT_BOT',
      deviceType: isMobileDevice()
        ? LEAD_GEN_DEVICE_MOBILE
        : LEAD_GEN_DEVICE_DESKTOP,
      dataSource: 'WEBSITE_CHAT_BOT',
      ApplicationStage: 'halfFill',
      ApplicationDate: new Date().toISOString(),
      Status: 'Active',
    };

    // Add UTM parameters if present in URL
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams = [
      'utmSource',
      'utmMedium',
      'utmCampaign',
      'utmTerm',
      'utmContent',
      'utmPublisher',
      'utmNetwork',
      'gclID',
      'lcID',
      'fbclID',
    ];

    utmParams.forEach((param) => {
      const value = urlParams.get(param.toLowerCase());
      if (value) data[param] = value;
    });
    const lead = await LeadGenerationService.generateLead(data);
    const leadID = lead.data.leadID;

    dispatch({
      type: 'SET_GLOBAL_LEAD_DOCUMENT_ID',
      payload: leadID,
    });
  } catch (error) {
    console.error('Lead creation failed:', error);
  }
};
