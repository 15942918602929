// import Button from '@components/Button/index';
// import AwesomeConfetti from '@components/Confetti/index';
import useResizer from '@components/extra/useResizer';
import FellowshipProgramCard from '@components/FellowshipProgramCard/index';
import FooterImage from '@components/FooterImage/index';
import Loader from '@components/Loader/index';
import ProgramCardRevamped from '@components/ProgramCardRevamped/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import React, { useContext, useEffect, useState } from 'react';
import { GTM } from '../../analytics/gtm';
import { gtmEvents } from '../../analytics/gtmEventsConstant';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../../context/GlobalContextProvider';
import db from '../../data/db.json';
import affiliate from '../../data/affiliate.json';
import hero from '../../images/affiliate/Header.png';
import * as styles from './Redeem.module.scss';
import toast from 'react-hot-toast';
import { EAnchorIds } from '@src/constants/anchorLinks/index';
import Button from '@mui/material/Button';
import { graphql } from 'gatsby';
import { StaticImage, getImage } from 'gatsby-plugin-image';
import FooterV2 from '@components/FooterV2/index';
import Navigation from '@components/Navigation/index';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import {
  LEAD_GEN_AFFILIATE_REDEEM,
  LEAD_GEN_REDEEM_PROGRAM_CARD,
} from '@src/constants/LeadGenerationConstants/index';

let str = '';
if (typeof window !== 'undefined') {
  let parts = window.location.hostname.split('.');
  let subdomain = parts.shift();
  let upperleveldomain = parts.join('.');

  // eslint-disable-next-line global-require
}

export default function Redeem(props) {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const [email, setEmail] = useState('');
  const [applyNowHero, setApplyNowHero] = useState(false);

  const isMobile = useResizer();
  const [valid, setValid] = useState(false);
  const [myParam, setMyParam] = useState('');

  const [copySuccess, setCopySuccess] = useState('');
  const toastList = new Set();
  const MAX_TOAST = 1;
  const sale = props.data.strapiSale.SaleID;
  const headerImage = getImage(props.data.headerImage);
  const fullStackAvatar = getImage(props.data.fullStackAvatar);
  const backendAvatar = getImage(props.data.backendAvatar);

  // your function to copy here

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };
  function sanitizeHelper(str) {
    return str.replace(/\s+/g, '-').toLowerCase();
  }

  const config = {
    angle: 270,
    spread: 360,
    startVelocity: 40,
    elementCount: 140,
    dragFriction: 0.12,
    duration: 5000,
    stagger: 3,
    width: '10px',
    height: '10px',
    perspective: '500px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
  };
  const tryFreeData = [
    {
      meId: 'ME_QEATS_REVIEW_MP',
      meName: 'QReview',
      mePrice: 'Free',
      meDescription: `Implement a Social Reviews feature in QEats - a popular food-ordering app. Try this Micro-Experience to get a flavor of Crio’s signature Learn-by-Doing methodology.<br></br>`,
      meIcon: 'API.png',
      meDuration: '10 Hours',
      meSkills: ['REST API', 'cURL', 'HTTP', 'Facebook Share API', 'JSON'],
    },
    {
      meId: 'ME_QPREP_SD',
      meName: 'System Design',
      mePrice: 'Free',
      meDescription: `Ever wondered what goes on behind the scenes when you use a browser to navigate to <a href="https://www.google.com" target="_blank">www.google.com</a>? Try this Micro-Experience to get a preview of how scalable, distributed, and fault-tolerant systems are designed.`,
      meIcon: 'SystemDesign.png',
      meDuration: '10 Hours',

      meSkills: ['Client-Server Architecture', 'HTTP', 'DNS', 'Load Balancing'],
    },
  ];

  const unlockGoodies = (email, phone) => {
    dispatch({
      type: 'SET_EMAIL',
      payload: email,
    });
    GTM.setEmail(email);
    dispatch({
      type: 'SET_PHONE',
      payload: phone,
    });

    GTM.login({
      userId: email,
      email: email,
      phon: phone,
      whatsappNumber: phone,
    });

    if (process.env.GATSBY_ENV == 'production') {
      fetch(
        `https://script.google.com/macros/s/AKfycbzZu0sfE3ZdlifBokz_l_ei9duEq9YZ3mEN8mf5cDsM8JZyF-2_Et7aiwRW7i7jnQ-6/exec?endpoint=website-leads&email=${email
          .trim()
          .toLowerCase()}&country=${
          state.country
        }&type=AFFILIATE&location=${myParam}&utm=${encodeURIComponent(
          state.utm,
        )}&adblocker=${
          typeof window.webengage == 'undefined' ? true : false
        }&url=${window.location.href}`,
      );
    }

    //GTM Event for Email Capture
    if (!state.isEmailEventSubmitted) {
      GTM.track(gtmEvents.EMAIL_SUBMITTED, {
        type: 'AFFILIATE',
        source: myParam,
      });
      dispatch({
        type: 'SET_IS_EMAIL_EVENT_SUBMITTED',
        payload: true,
      });
    }

    dispatch({
      type: 'SET_AFFILIATE_UNLOCKED',
      payload: true,
    });
  };

  useEffect(() => {
    let temp = false;

    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);

      const myParam = urlParams.get('affiliate');
      const myParam2 = urlParams.get('a');

      Object.keys(affiliate).forEach((y) => {
        if (y === myParam || y === myParam2) {
          temp = true;
          return;
        }
      });
      if ((myParam == null && myParam2 == null) || temp === false) {
        navigate('/');
      } else {
        if (myParam !== null) setMyParam(myParam);
        else if (myParam2 !== null) setMyParam(myParam2);

        let temp = window.location.search;
        let utm = '';
        if (temp.includes('?a=')) {
          let url_string = window.location.href;
          let url = new URL(url_string);
          let c = url.searchParams.get('a');
          if (c !== null) {
            utm = 'utm_source=' + c + '&utm_medium=affiliate';
            dispatch({
              type: 'SET_UTM',
              payload: utm,
            });
          }
        } else if (temp.includes('?affiliate=')) {
          let url_string = window.location.href;
          let url = new URL(url_string);
          let c = url.searchParams.get('affiliate');
          if (c !== null) {
            utm = 'utm_source=' + c + '&utm_medium=affiliate';
            dispatch({
              type: 'SET_UTM',
              payload: utm,
            });
          }
        }

        setTimeout(() => {
          setValid(true);
        }, 800);
      }
    }
  }, []);
  const freeTrialData = db.strapi_metadata.programs.find(
    (ele) => ele.PrimaryProgramID == 'FREE_TRIAL',
  );
  return valid ? (
    <>
      <GatsbySeo title={`Crio Giveaway`} noindex={true} />
      <Navigation />

      <div
        className={styles.hero}
        style={{
          backgroundImage: `url(${hero})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: '250px',
        }}
      >
        <div className="container   pt-10 pb-7 text-white">
          <div className="row ">
            <div className="col-lg-12  flex items-center justify-center">
              <div className={styles.headerImage}>
                <StaticImage
                  loading="lazy"
                  src="../../images/affiliate/HeaderImage.png"
                  placeholder="blurred"
                  alt="Background"
                />
              </div>
            </div>{' '}
          </div>
        </div>
      </div>
      <section className="pt-10 pb-24  " style={{ background: '#EAE8DF' }}>
        {' '}
        <div className="container   flex flex-col items-center justify-center text-center">
          {' '}
          <h1 className="mb-5">
            {affiliate[myParam]['name'] !== 'CRIO'
              ? 'Gift from ' + affiliate[myParam]['name']
              : 'Your Gift'}{' '}
          </h1>
          {!state.unlocked ? (
            <>
              <p className="mb-3">
                Enter your email and unlock multiple goodies
              </p>
              <LeadGenerationButton
                type="Affiliate"
                text="Unlock Right Now!"
                isOnePageForm
                formHeading="Unlock your Gifts"
                pageOneButtonText="Unlock Right Now"
                buttonLocation={LEAD_GEN_AFFILIATE_REDEEM}
                onSubmitPageOne={unlockGoodies}
                {...(state.email &&
                  state.phone && {
                    onClick: () => {
                      unlockGoodies(state.email, state.phone);
                    },
                  })}
              ></LeadGenerationButton>
            </>
          ) : (
            <section
              className="mb-4"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div className="">
                <div className="container   text-center">
                  <div className="animation-ctn">
                    <div
                      className="icon icon--order-success svg flex w-full flex-col items-center justify-center"
                      style={{ marginBottom: '-40px' }}
                    >
                      <svg
                        style={{ transform: 'scale(0.3)' }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="154px"
                        height="154px"
                      >
                        <g fill="none" stroke="#22AE73" strokeWidth="2">
                          <circle
                            cx="77"
                            cy="77"
                            r="72"
                            style={{
                              strokeDasharray: '480px, 480px',
                              strokeDashoffset: '960px',
                            }}
                          ></circle>
                          <circle
                            id="colored"
                            fill="#22AE73"
                            cx="77"
                            cy="77"
                            r="72"
                            style={{
                              strokeDasharray: '480px, 480px',
                              strokeDashoffset: '960px',
                            }}
                          ></circle>
                          <polyline
                            className="st0"
                            stroke="#fff"
                            strokeWidth="10"
                            points="43.5,77.8 63.7,97.9 112.2,49.4 "
                            style={{
                              strokeDasharray: '100px, 100px',
                              strokeDashoffset: '200px',
                            }}
                          />
                        </g>
                      </svg>
                    </div>
                  </div>

                  <p className="subtitle1 m-0 font-light">
                    Your gifts are ready to avail now!
                  </p>
                  <br></br>
                  <p className="subtitle1 mb-10 font-light lg:pl-5 lg:pr-5">
                    Check out{' '}
                    <div
                      className="inline font-bold"
                      style={{ color: '#22AE73', textDecoration: 'underline' }}
                    >
                      <a href="#programs">Crio Signature Learning Programs.</a>
                    </div>{' '}
                    Use your <div className="inline font-bold">Promo code</div>{' '}
                    while filling your application and{' '}
                    {affiliate[myParam]['name'] !== 'CRIO' ? (
                      <span>
                        <div className="inline font-bold">
                          avail {affiliate[myParam]['discount']}% extra discount{' '}
                          <span className="font-normal">
                            <i>(Upto Rs.3,000)</i>
                          </span>
                          . On top of it, get additional scholarships by
                          completing the trial successfully.
                        </div>{' '}
                      </span>
                    ) : (
                      <span className="inline">
                        <span className="font-bold">
                          avail {affiliate[myParam]['discount']}% discount{' '}
                          <span className="font-normal">
                            <i>(Upto Rs.3,000)</i>
                          </span>
                          .
                        </span>{' '}
                        The first week of learning is free for all our programs.
                        <span className="font-bold">
                          {' '}
                          Earn additional scholarship on top of your discount
                        </span>{' '}
                        by excelling in the trial week.
                      </span>
                    )}
                  </p>
                </div>
              </div>{' '}
            </section>
          )}
          <div className="row mt-10 flex w-full items-center justify-center text-center ">
            <div className="col-6 col-lg-3  mb-5 flex flex-col  items-center justify-center md:mb-0">
              <StaticImage
                loading="lazy"
                src="../../images/affiliate/Circle1.png"
                placeholder="blurred"
                alt="Affiliate"
                className="mb-3"
                style={{ width: '50%' }}
              />
              <h5 className=" mb-3 font-light">
                <div className="font-bold">
                  Extra {affiliate[myParam]['discount']}% Off
                </div>
                <div className="subtitle2 mb-1 md:mb-0">
                  (<i>Upto Rs.3,000</i>
                  <br />+ additional scholarships)
                </div>
                <div>Promo Code</div>
                <div className="subtitle2 mb-1 md:mb-0">
                  (Expires {affiliate[myParam]['expiry_date']})
                </div>
              </h5>{' '}
              {state.unlocked ? (
                <Button
                  variant="contained"
                  color="secondary"
                  type="primary"
                  className="font-bold capitalize"
                  style={{ padding: '15px 20px' }}
                  onClick={(e) => {
                    copyToClipBoard(affiliate[myParam]['promo_code']);
                    GTM.track(gtmEvents.AFFILIATE_COUPON_COPIED, {
                      source: myParam,
                      promo_code: affiliate[myParam]['promo_code'],
                    });
                    toast.success(
                      `Promo Code ${affiliate[myParam]['promo_code']} copied!`,
                      { id: 'affiliate' },
                    );
                  }}
                >
                  {affiliate[myParam]['promo_code']}{' '}
                  <FontAwesomeIcon
                    icon={['fal', 'copy']}
                    className="ml-2"
                    size="lg"
                  />{' '}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  className="cursor-not-allowed bg-gray-500 font-bold capitalize opacity-20"
                  style={{
                    color: '#fff',
                    padding: '15px 20px',
                  }}
                >
                  Locked
                </Button>
              )}
            </div>

            <div className="col-6 col-lg-3  mb-5 flex flex-col  items-center justify-center md:mb-0">
              <StaticImage
                loading="lazy"
                src="../../images/affiliate/Circle_Full_Stack.png"
                placeholder="blurred"
                alt="Affiliate"
                className="mb-3"
                style={{ width: '50%' }}
              />
              {/* <Image
                className="mb-3"
                filename="affiliate/Circle_Full_Stack.png"
                style={{ width: '50%' }}
              ></Image> */}
              <h5 className="mb-3 font-light">
                Free guide to<br></br>
                <div className="inline font-bold">
                  {' '}
                  Product Based company Interviews
                </div>
                <div
                  className="subtitle2 hidden md:block"
                  style={{ opacity: '0' }}
                >
                  (Expires 30th Sep 2020)
                </div>
              </h5>{' '}
              {state.unlocked ? (
                <Button
                  variant="contained"
                  color="secondary"
                  type="primary"
                  className="font-bold capitalize"
                  style={{ padding: '15px 20px' }}
                  onClick={(e) => {
                    GTM.track(gtmEvents.AFFILIATE_BDT_GUIDE_DOWNLOADED, {
                      source: myParam,
                    });

                    window.open(
                      'https://drive.google.com/uc?export=download&id=1rC2dAgpqRMJFNOeRmBD-0YEkpwKGEFPk',
                      '_blank',
                    );
                  }}
                >
                  Download{' '}
                  <FontAwesomeIcon
                    icon={['fal', 'download']}
                    className="ml-2"
                    size="lg"
                  />{' '}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  className="cursor-not-allowed bg-gray-500 font-bold capitalize opacity-20"
                  style={{
                    color: '#fff',
                    padding: '15px 20px',
                  }}
                >
                  Locked
                </Button>
              )}
            </div>
            <div className="col-6 col-lg-3  mb-5 flex flex-col  items-center justify-center md:mb-0">
              <StaticImage
                loading="lazy"
                src="../../images/affiliate/Circle3.png"
                placeholder="blurred"
                alt="Affiliate"
                className="mb-3"
                style={{ width: '50%' }}
              />
              {/* <Image
                className="mb-3"
                filename="affiliate/Circle3.png"
                style={{ width: '50%' }}
              ></Image> */}
              <h5 className="mb-3 font-light">
                Free guide to
                <div className="font-bold">Best Resume</div>
                <div
                  className="subtitle2 hidden md:block"
                  style={{ opacity: '0' }}
                >
                  (Expires 30th Sep 2020) (Expires 30th Sep 2020)
                </div>
              </h5>{' '}
              {state.unlocked ? (
                <Button
                  type="primary"
                  variant="contained"
                  color="secondary"
                  className="font-bold capitalize"
                  style={{ padding: '15px 20px' }}
                  onClick={(e) => {
                    GTM.track(gtmEvents.AFFILIATE_INTERVIEW_GUIDE_DOWNLOADED, {
                      source: myParam,
                    });
                    window.open(
                      ' https://drive.google.com/uc?export=download&id=1cDDmIsQhA3hsA5IZm38KbnkTOSKuMvBl',
                      '_blank',
                    );
                  }}
                >
                  Download{' '}
                  <FontAwesomeIcon
                    icon={['fal', 'download']}
                    className="ml-2"
                    size="lg"
                  />{' '}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  className="cursor-not-allowed bg-gray-500 font-bold capitalize opacity-20"
                  style={{
                    color: '#fff',
                    padding: '15px 20px',
                  }}
                >
                  Locked
                </Button>
              )}
            </div>
            <div className="col-6 col-lg-3  mb-5 flex flex-col  items-center justify-center md:mb-0">
              <StaticImage
                loading="lazy"
                src="../../images/affiliate/projects.png"
                placeholder="blurred"
                alt="Affiliate"
                className="mb-3"
                style={{ width: '50%' }}
              />
              {/* <Image
                className="mb-3"
                filename="affiliate/projects.png"
                style={{ width: '50%' }}
              ></Image> */}
              <h5 className="mb-3 font-light">
                Free guide to
                <div className="font-bold">Mini Projects</div>
                <div
                  className="subtitle2 hidden md:block"
                  style={{ opacity: '0' }}
                >
                  (Expires 30th Sep 2020) (Expires 30th Sep 2020)
                </div>
              </h5>{' '}
              {state.unlocked ? (
                <Button
                  type="primary"
                  className="font-bold capitalize"
                  variant="contained"
                  color="secondary"
                  style={{ padding: '15px 20px' }}
                  onClick={(e) => {
                    GTM.track(gtmEvents.AFFILIATE_INTERVIEW_GUIDE_DOWNLOADED, {
                      source: myParam,
                    });
                    window.open(
                      'https://drive.google.com/uc?export=download&id=1Uzh6zimW76kCh4WHtBH6GdBQ-19qZX5U',
                      '_blank',
                    );
                  }}
                >
                  Download{' '}
                  <FontAwesomeIcon
                    icon={['fal', 'download']}
                    className="ml-2"
                    size="lg"
                  />{' '}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  className="cursor-not-allowed  bg-gray-500 font-bold capitalize opacity-20"
                  style={{
                    color: '#fff',
                    padding: '15px 20px',
                  }}
                >
                  Locked
                </Button>
              )}
            </div>
          </div>
        </div>
      </section>
      {state.unlocked ? (
        <>
          <span id="product"></span>
          <span id={EAnchorIds.PROGRAMS}></span>
          <section className="bg-crio-neutral-75 ">
            <div className="container pt-16 pb-16 ">
              <div className=" text-center">
                <h2 className={`mb-5 ${styles.heading}`}>
                  Immersive Project-Based Developer Programs
                </h2>

                <div className="mb-7 flex  justify-center ">
                  <p className="font-light leading-loose md:w-5/6">
                    Land an exciting job in full-stack development (JavaScript,
                    MERN) or become a top-notch backend developer (Spring Boot,
                    MongoDB) with our hands-on, project-based learning approach.
                  </p>
                </div>

                <div className="">
                  <div className="flex  flex-wrap justify-center md:flex-nowrap">
                    <div className="mb-10 md:mr-5">
                      <ProgramCardRevamped
                        newTab={true}
                        sale={sale}
                        program={props.data.allStrapiPrograms.nodes.find(
                          (ele) => ele.PrimaryProgramID == 'FULL_STACK',
                        )}
                        gradient={headerImage}
                        cardHeader="BECOME A SKILLED DEVELOPER"
                        data={{
                          image: fullStackAvatar,
                          cardTitle: 'Full Stack Developer Program',
                          offeringId: 'FULL_STACK',
                          offeringType: 'FULL_STACK',
                          content:
                            'Work on guided internship-grade projects in a real dev environment to master Full-Stack development.',

                          highlights: [
                            {
                              icon: 'star',
                              description: `<strong>7 internship-grade projects</strong> to learn React, HTML, CSS, JavaScript, MERN and more`,
                            },
                            {
                              icon: 'star',
                              description: `<strong>15+ hands-on micro-skilling exercises</strong> to learn a variety of concepts and practical developer skills`,
                            },
                            {
                              icon: 'star',
                              description: `<strong>Curated, self-paced Data Structures, Algorithms and System Design practice</strong> with mock-tests to crack interview rounds with ease`,
                            },
                          ],
                          prerequisites: 'Basic programming in any language',
                          duration: '240 Hours',
                          access: '(6 months access)',
                          fee: '₹1,999',
                          feeSubText:
                            '(Full refund if you discontinue after the 1st week)',
                        }}
                      ></ProgramCardRevamped>
                    </div>

                    <div className="mb-10">
                      <ProgramCardRevamped
                        newTab={true}
                        sale={sale}
                        program={props.data.allStrapiPrograms.nodes.find(
                          (ele) =>
                            ele.PrimaryProgramID ==
                            'BACKEND_DEVELOPER_EXPERIENCE',
                        )}
                        gradient={headerImage}
                        cardHeader="BECOME A SKILLED DEVELOPER"
                        data={{
                          image: backendAvatar,
                          cardTitle: 'Backend Developer Program',
                          offeringId: 'BACKEND_DEVELOPER_EXPERIENCE',
                          offeringType: 'BACKEND_DEVELOPER_EXPERIENCE',
                          content:
                            'Build guided internship-grade projects in a real dev environment to excel in Backend development.',

                          highlights: [
                            {
                              icon: 'star',
                              description: `<strong>5 internship-grade projects</strong> to learn Java, Springboot, RestAPI, MongoDB, Caching and more`,
                            },
                            {
                              icon: 'star',
                              description: `<strong>15+ hands-on micro-skilling exercises</strong> to learn a variety of concepts and practical developer skills`,
                            },
                            {
                              icon: 'star',
                              description: `<strong>Curated, self-paced Data Structures, Algorithms and System Design practice</strong> with mock-tests to crack interview rounds with ease`,
                            },
                          ],
                          prerequisites: 'Basic programming in any language',
                          duration: '240 Hours',
                          access: '(6 months access)',
                          fee: '₹1,999',
                          feeSubText:
                            '(Full refund if you discontinue after the 1st week)',
                        }}
                      ></ProgramCardRevamped>
                    </div>
                  </div>

                  <div className="relative mb-10">
                    <FellowshipProgramCard
                      sale={sale}
                      newTab={true}
                      program={props.data.allStrapiPrograms.nodes.find(
                        (ele) => ele.PrimaryProgramID == 'FELLOWSHIP',
                      )}
                      data={{
                        image: fullStackAvatar,
                        cardTitle: 'Fellowship Program in Software Development',
                        offeringId: 'FELLOWSHIP',
                        offeringType: 'FELLOWSHIP',
                        content:
                          'Learn with real work experience and land your dream career as a coveted Full-Stack or Backend Developer - Assured!',

                        highlights: [
                          {
                            icon: 'star',
                            description: `<strong>Complete Full-Stack or Backend Developer Track curriculum included</strong> depending on the specialisation you choose`,
                          },
                          {
                            icon: 'star',
                            description: `<strong>In-depth curriculum with mentorship, live sessions, workshops, and mini-projects</strong> to master programming foundations, Data Structures, Algorithms, and System Design concepts`,
                          },
                          {
                            icon: 'star',
                            description: `<strong>Referrals to exciting dev roles & assured referrals</strong> within 6 months of completing the program curriculum`,
                          },
                        ],
                        prerequisites: 'Basic programming in any language',
                        duration: '450 Hours',
                        access: '(6 months access)',
                        fee: '₹1,999',
                        feeSubText:
                          '(Full refund if you discontinue after the 1st week)',
                      }}
                    ></FellowshipProgramCard>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-[-330px]  h-[200px] bg-crio-green-600 md:hidden"></div>
          </section>
          <span id="career-prep"></span>
          <section className="bg-crio-green-600 ">
            <div className="pt-24  pb-1 md:pt-16">
              <div className="container text-white  md:text-center">
                <div className=" mb-5 mt-[-145px] hidden md:flex md:justify-center">
                  <StaticImage
                    loading="lazy"
                    src="../../images/CareerPrep.png"
                    placeholder="blurred"
                    alt="Career Prep"
                    style={{ width: '150px' }}
                  />
                  {/* <Image
                    filename="CareerPrep.png"
                    style={{ width: '150px' }}
                  ></Image> */}
                </div>
                <h2 className={`mb-5 ${styles.heading}`}>
                  Crio Career Services
                </h2>

                <div className="mb-10 flex  justify-center md:mb-14">
                  <p className="font-light leading-loose md:w-5/6">
                    All of our web development programs come with{' '}
                    <span className="font-bold">career services</span> that will
                    equip you to use your learnings and skills to ace your next
                    interview or excel in your current role.
                  </p>
                </div>

                <div className="container">
                  <div className="row  mb-10 leading-7" id={styles.career}>
                    <div className="col-md-4 flex p-2 md:flex-col">
                      <div className="w-full rounded-md bg-crio-green-800 p-4 text-left md:mr-3 md:w-auto md:bg-transparent md:p-0">
                        <div className="mb-4 mr-4 md:mr-0 ">
                          <StaticImage
                            loading="lazy"
                            src="../../images/home/Career/Two.png"
                            placeholder="blurred"
                            alt="Why Crio"
                            className={styles.whyCrioImage}
                          />
                          {/* <Image
                            filename="home/Career/Two.png"
                            className={styles.whyCrioImage}
                          ></Image> */}
                        </div>
                        <h5
                          className={`font-semibold ${styles.customFont}  mb-3`}
                        >
                          Profile Readiness
                        </h5>

                        <div className="text-sm leading-loose">
                          <ul>
                            <li>Resume & LinkedIn Profile Review</li>
                            <li>
                              Tips & Hacks to get noticed by Recruiters
                            </li>{' '}
                            <li>Playbook on how best to apply to companies</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 flex p-2 md:flex-col">
                      <div className=" mb-8 w-full overflow-hidden  rounded-md bg-crio-green-800  p-4 text-left md:mr-3 md:w-auto md:bg-transparent md:p-0">
                        <div className="mb-4 mr-4 flex justify-between md:mr-0">
                          <div>
                            <StaticImage
                              loading="lazy"
                              src="../../images/home/Career/Three.png"
                              placeholder="blurred"
                              alt="Why Crio"
                              className="mb-2 hidden w-[190px] md:block"
                            />
                            {/* <Image
                              filename="home/Career/Three.png"
                              // className={styles.whyCrioImage}
                              className="w-[190px] hidden md:block mb-2"
                            ></Image> */}
                            <StaticImage
                              loading="lazy"
                              src="../../images/home/Career/Three_mobile.png"
                              placeholder="blurred"
                              alt="Why Crio"
                              className="w-[100px]  md:hidden"
                            />
                            {/* <Image
                              filename="home/Career/Three_mobile.png"
                              // className={styles.whyCrioImage}
                              className="w-[100px]  md:hidden"
                            ></Image> */}
                          </div>
                        </div>
                        <h5
                          className={`font-semibold ${styles.customFont}  mb-3`}
                        >
                          Career Planning & Coaching
                        </h5>

                        <div className="text-sm leading-loose">
                          <ul>
                            <li>S.M.A.R.T goal setting</li>
                            <li>Personalised career planning</li>{' '}
                            <li>Group office hours with career coaches</li>
                          </ul>
                        </div>
                      </div>
                    </div>{' '}
                    <div className="col-12 col-md-4 flex p-2 md:flex-col">
                      <div className=" mb-8  overflow-hidden rounded-md  bg-crio-green-800 p-4 text-left md:mr-3 md:bg-transparent md:p-0">
                        <div className="mb-4 mr-4 flex justify-between md:mr-0">
                          <div>
                            <StaticImage
                              loading="lazy"
                              src="../../images/home/Career/One.png"
                              placeholder="blurred"
                              alt="Why Crio"
                              className="mb-2 hidden w-[190px] md:block"
                            />
                            {/* <Image
                              filename="home/Career/One.png"
                              // className={styles.whyCrioImage}
                              className="w-[190px] hidden md:block mb-2"
                            ></Image> */}
                            <StaticImage
                              loading="lazy"
                              src="../../images/home/Career/One_mobile.png"
                              placeholder="blurred"
                              alt="Why Crio"
                              className="w-[100px]  md:hidden"
                            />
                            {/* <Image
                              filename="home/Career/One_mobile.png"
                              // className={styles.whyCrioImage}
                              className="w-[100px]  md:hidden"
                            ></Image> */}
                          </div>
                        </div>
                        <h5
                          className={`font-semibold ${styles.customFont}  mb-3`}
                        >
                          Assured Referrals
                        </h5>

                        <div className="text-sm leading-loose">
                          <ul>
                            <li>Assured referrals to exciting dev roles</li>
                            <li>
                              Assured Referrals within{' '}
                              <br className="hidden md:block"></br>6 months of
                              program completion
                            </li>{' '}
                          </ul>
                        </div>
                      </div>
                    </div>{' '}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <span id="trial"></span>
          <section style={{ background: '#F7F7F7' }}>
            <div className="container   pt-20 pb-20 ">
              <div className="row">
                <div className="col-lg-4  mb-3 flex items-center justify-center  text-center md:mb-0">
                  <StaticImage
                    loading="lazy"
                    src="../../images/trial/avatar.png"
                    placeholder="blurred"
                    alt="Avatar"
                    style={{ width: '200px' }}
                  />
                  {/* <Image
                    filename="trial/avatar.png"
                    style={{ width: '200px' }}
                  ></Image> */}
                </div>
                <div className="col-lg-8 items-center justify-start md:flex">
                  <div className="mt-3 mb-3 w-full text-center md:mt-0 md:mb-0 md:text-left">
                    <h1>Want to get Started?</h1>
                    <h3 className="container   mb-3 p-0 font-normal">
                      Experience <div className="inline">#LearnByDoing </div>
                      for <div className={`free inline`}>Free!</div>
                      {/* {data[props.pageContext.programName].offeringName} */}
                    </h3>
                    <div className="mt-4 flex flex-wrap items-center justify-center text-center text-white md:mt-0 md:justify-start ">
                      {' '}
                      {applyNowHero ? (
                        <div className="mb-3 flex w-full flex-wrap items-center justify-center md:flex-nowrap">
                          <section className="flex w-full flex-col items-center justify-center">
                            <div className="w-full">
                              <div>
                                <form
                                  id="my-form"
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    dispatch({
                                      type: 'SET_EMAIL',
                                      payload: email,
                                    });
                                    GTM.setEmail(email);

                                    GTM.login({
                                      userId: email,
                                      email: email,
                                    });

                                    //GTM Event for Email Capture
                                    if (!state.isEmailEventSubmitted) {
                                      GTM.track(gtmEvents.EMAIL_SUBMITTED, {
                                        type: 'FREE_TRIAL',
                                        location: 'REDEEM_PAGE',
                                      });
                                      dispatch({
                                        type: 'SET_IS_EMAIL_EVENT_SUBMITTED',
                                        payload: true,
                                      });
                                    }

                                    navigate('/registration', {
                                      state: {
                                        link: freeTrialData.typeform,
                                        program: freeTrialData,

                                        type: 'FREE_TRIAL',
                                        location: 'REDEEM_PAGE',
                                      },
                                    });
                                  }}
                                >
                                  <input
                                    type="email"
                                    className="bodyText1 font-light"
                                    placeholder="Enter your Email"
                                    style={{
                                      flexGrow: '2',
                                      padding: '12px 1rem',
                                      border: 'none',
                                      // width: "100%",
                                    }}
                                    value={email}
                                    onChange={(e) => {
                                      setEmail(e.target.value);
                                    }}
                                    required
                                  ></input>
                                  <Button
                                    variant="contained"
                                    className="subtitle1 font-bold capitalize"
                                    type="submit"
                                    style={{
                                      width: 'max-content',
                                      whiteSpace: 'nowrap',
                                      // padding: "0px 10px",
                                      background: '#FFB533',
                                      color: '#022F2A',
                                      background:
                                        'linear-gradient(107.15deg, #FFC772 -9.95%, #FF980E 108.2%)',
                                    }}
                                  >
                                    Book your trial
                                    <FontAwesomeIcon
                                      icon={['fas', 'arrow-up']}
                                      className="ml-2 rotate-45"
                                      style={{ marginBottom: '2px' }}
                                    ></FontAwesomeIcon>
                                  </Button>
                                </form>
                              </div>
                            </div>
                          </section>
                          <Button
                            variant="contained"
                            className="md  ml-3 mt-3 font-bold capitalize  md:mr-3 md:mt-0"
                            // type="secondary"
                            onClick={(e) => {
                              setApplyNowHero(false);
                              dispatch({
                                type: 'USER_IS_APPLYING',
                                payload: false,
                              });
                              GTM.track(gtmEvents.FREE_ME_EMAIL_FORM_CLOSED, {
                                // meId:
                                //   data[props.pageContext.programName].offeringId,
                                meId: 'FREE_TRIAL',
                                location: 'REDEEM_PAGE',
                              });
                            }}
                            style={{
                              // width: "250px",
                              background: '#FFB533',
                              color: '#022F2A',
                            }}
                          >
                            Close
                          </Button>{' '}
                        </div>
                      ) : (
                        <div className="flex flex-col gap-4 md:flex-row">
                          {/* <Button
                            variant="contained"
                            className="mb-3  text-center   font-bold capitalize md:mr-3"
                            // type="secondary"
                            onClick={(e) => {
                              GTM.track(gtmEvents.FREE_ME_START_NOW_CLICKED, {
                                // meId:
                                //   data[props.pageContext.programName].offeringId,
                                meId: 'FREE_TRIAL',
                                location: 'REDEEM_PAGE',
                              });
                              dispatch({
                                type: 'USER_IS_APPLYING',
                                payload: true,
                              });

                              if (state.email.length > 0) {
                                GTM.login({
                                  userId: state.email,
                                  email: state.email,
                                });
                                GTM.track(gtmEvents.EMAIL_SUBMITTED, {
                                  type: 'FREE_TRIAL',
                                  location: 'REDEEM_PAGE',
                                });

                                navigate('/registration', {
                                  state: {
                                    link: freeTrialData.typeform,
                                    program: freeTrialData,
                                    type: 'FREE_TRIAL',
                                    location: 'REDEEM_PAGE',
                                  },
                                });
                              } else {
                                setApplyNowHero(true);
                              }
                            }}
                            style={{
                              width: '250px',
                              background: '#FFB533',
                              color: '#022F2A',
                              background:
                                'linear-gradient(107.15deg, #FFC772 -9.95%, #FF980E 108.2%)',
                            }}
                          >
                            Book your trial
                            <FontAwesomeIcon
                              icon={['fas', 'arrow-up']}
                              className="ml-2 rotate-45"
                              style={{ marginBottom: '2px' }}
                            ></FontAwesomeIcon>
                          </Button>{' '} */}
                          <LeadGenerationButton
                            text="Apply Now"
                            buttonLocation={LEAD_GEN_REDEEM_PROGRAM_CARD}
                            className="h-[40px] w-full px-12 py-2 md:w-auto"
                          />
                          <a
                            href={
                              '/software-development-fellowship-program/' + str
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Button
                              variant="contained"
                              className=" mb-3  text-center font-bold capitalize md:mr-3"
                              // type="secondary"
                              style={{
                                width: '250px',
                                background: '#fff',
                                border: '1px solid #FFB533',
                                color: '#FFB533',
                              }}
                            >
                              Learn More
                            </Button>{' '}
                          </a>
                        </div>
                      )}
                    </div>
                  </div>{' '}
                </div>
              </div>
            </div>
          </section>
          <section
            className="pb-24 pt-24 text-center "
            style={{
              //   backgroundImage: `url(${platform})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div>
              <h2 className="container    mb-4">
                Developers love Crio Platform
              </h2>
            </div>
            <br></br>
            <div className="container  ">
              <div className={`${styles.testimonialCard} mb-4 p-4`}>
                <div className="row flex justify-center">
                  <div className="col-6  col-lg-3 mb-3 md:mb-0">
                    <StaticImage
                      loading="lazy"
                      src="../../images/testimonials/jumbotail.png"
                      placeholder="blurred"
                      alt="Jumbotail"
                      className={styles.userImage}
                    />
                    {/* <Image
                      filename="testimonials/jumbotail.png"
                      className={styles.userImage}
                    ></Image> */}
                  </div>
                  <div className="col-lg-9 text-left">
                    <div className="bodyText1 mb-2">
                      “It was a wonderful journey with several practical
                      learning experiences. Crio made me realize that the best
                      way to learn is by doing. I experienced the power of
                      systematic coding, test-driven development, and scientific
                      debugging. The constant support and push from mentors were
                      marvelous. I honed my skills in software development,
                      problem-solving, and debugging.”
                    </div>
                    <div className="subtitle1 mb-1  flex items-center">
                      <div className="mr-1 ">
                        - Anubhav Bhargava, Software Engineer{' '}
                      </div>
                      <a
                        href={'https://www.linkedin.com/in/anubhavbhargava/'}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={['fab', 'linkedin']}
                          style={{
                            color: '#52A5EF',
                            // marginRight: "1rem",
                            // marginTop: "-4px",
                            // fontSize: "30px",
                          }}
                          size="lg"
                        />{' '}
                      </a>
                    </div>
                    <div className="subtitle1 font-light">
                      <i> Jumbotail</i>
                    </div>
                  </div>
                </div>
              </div>{' '}
              <div className={`${styles.testimonialCard} mb-4 p-4`}>
                <div className="row flex justify-center">
                  <div className="col-6  col-lg-3  order-md-last mb-3 md:mb-0">
                    <StaticImage
                      loading="lazy"
                      src="../../images/testimonials/ninjacart.png"
                      placeholder="blurred"
                      alt="Ninjakart"
                      className={styles.userImage}
                    />
                    {/* <Image
                      filename="testimonials/ninjacart.png"
                      className={styles.userImage}
                    ></Image> */}
                  </div>
                  <div className="col-lg-9 text-left">
                    <div className="bodyText1 mb-2">
                      “Enrolling for Crio’s program proved to be a master stroke
                      for me. When I joined this program, I was low on
                      confidence about my skills, lost touch with programming -
                      it was all low. This program helped me find back my love
                      for programming. I grew more confident in solving
                      problems. My development skills improved big time. The
                      mentors responded to my doubts promptly and helped us find
                      the right path by ourselves. The program was incremental.
                      It started from the basics and after every completed
                      module I saw myself making a big stride forward. Each
                      module was demanding in itself and required a lot of
                      googling and understanding of concepts to implement them.
                      The team at Crio is excellent. They were so patient and
                      professional. The four months I spent for this program are
                      valuable. I learned a lot by implementing a lot of things
                      in a work-like environment. I am elated that I was able to
                      crack the very first company that I appeared for -
                      Ninjacart. ”
                    </div>
                    <div className="subtitle1 flex  items-center">
                      <div className="mr-1">
                        - Venkata Amaresh, Software Engineer
                      </div>
                      <a
                        href={'https://www.linkedin.com/in/vamareshi/'}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={['fab', 'linkedin']}
                          style={{
                            color: '#52A5EF',
                          }}
                          size="lg"
                        />{' '}
                      </a>
                    </div>
                    <div className="subtitle1 font-light">
                      <i> Ninjacart</i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="pt-24 pb-24 text-center"
            style={{
              //   backgroundImage: `url(${upskill})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div>
              <h2 className="container    mb-4">
                Our learning platform emulates a work-like environment in which
                developers build real projects
              </h2>
            </div>
            <br></br>
            <div className="container   text-center ">
              {' '}
              <div className={`${styles.testimonialCard} mb-4 p-4`}>
                <div className="row flex justify-center">
                  <div className="col-6  col-lg-3 mb-3 md:mb-0">
                    <StaticImage
                      loading="lazy"
                      src="../../images/testimonials/cred.png"
                      placeholder="blurred"
                      alt="CRED"
                      className={styles.userImage}
                    />
                    {/* <Image
                      filename="testimonials/cred.png"
                      className={styles.userImage}
                    ></Image> */}
                  </div>
                  <div className="col-lg-9 text-left">
                    <div className="bodyText1 mb-2">
                      “It was a wonderful journey with a lot of practical
                      learning. With no experience in many of the tech stacks
                      like Spring Boot, MongoDB, etc I still managed to build a
                      scalable distributed backend system that is running live
                      in Google Cloud. All this was possible because of the
                      wonderful team at Crio who made us realise that "learning
                      is doing". The experience that Crio provides cannot be
                      provided to the video courses out there. Crio is
                      completely different because you work just like a
                      developer works in a company and build real stuff that you
                      can showcase. Also, the capstone project at the end was a
                      thrilling experience where we built a working feature in a
                      limited time on our own. All thanks to the team at Crio
                      for their wonderful support and mentorship.”
                    </div>
                    <div className="subtitle1 flex  items-center">
                      <div className="mr-1">
                        - Piyush Pant, Backend Engineer{' '}
                      </div>
                      <a
                        href={'https://www.linkedin.com/in/piyushpant17/'}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={['fab', 'linkedin']}
                          style={{
                            color: '#52A5EF',
                            // marginRight: "1rem",
                            // marginTop: "-4px",
                            // fontSize: "30px",
                          }}
                          size="lg"
                        />{' '}
                      </a>
                    </div>
                    <div className="subtitle1 font-light">
                      <i> CRED</i>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles.testimonialCard} mb-4 p-4`}>
                <div className="row flex justify-center ">
                  <div className="col-6  col-lg-3   order-md-last mb-3 md:mb-0">
                    <StaticImage
                      loading="lazy"
                      src="../../images/testimonials/vogo.png"
                      placeholder="blurred"
                      alt="Vogo"
                      className={styles.userImage}
                    />
                    {/* <Image
                      filename="testimonials/vogo.png"
                      className={styles.userImage}
                    ></Image> */}
                  </div>
                  <div className="col-lg-9 text-left">
                    <div className="bodyText1 mb-2">
                      “Crio Micro-Experiences are the best thing that has
                      happened to me. When I applied for this program I thought
                      it might be similar to the other programs in the market -
                      watching assigned videos and implementing things. But I
                      was wrong. The learning I got from Crio is incomparable.
                      The modules were designed in such a way that without
                      learning about the concepts enough, you won't be able to
                      implement it. There's no shortcut. Proper time must be
                      allotted to reading documentation, tutorials, blogs and
                      more to cross the modules. The program made sure you get
                      out of your comfort zone and thrive. Completing Crio
                      experience helped me in appearing for interviews
                      confidently and cracking one. I got placed in a
                      fast-growing startup - VOGO automotive.”
                    </div>
                    <div className="subtitle1 flex  items-center">
                      <div className="mr-1">
                        - Roshan Dawande, Software Engineer
                      </div>
                      <a
                        href={'https://www.linkedin.com/in/rdroshan/'}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={['fab', 'linkedin']}
                          style={{
                            color: '#52A5EF',
                            // marginRight: "1rem",
                            // marginTop: "-4px",
                            // fontSize: "30px",
                          }}
                          size="lg"
                        />{' '}
                      </a>
                    </div>
                    <div className="subtitle1 font-light">
                      <i>Vogo</i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="pt-24 pb-24 text-center"
            style={{
              //   backgroundImage: `url(${platform})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div>
              <h2 className="container   mb-4">
                Crio mentors come with years of experience in top product
                companies
              </h2>
            </div>
            <br></br>
            <div className="container   text-center ">
              {' '}
              <div className={`${styles.testimonialCard} mb-4 p-4`}>
                <div className="row flex justify-center">
                  <div className="col-6  col-lg-3  mb-3 md:mb-0">
                    <StaticImage
                      loading="lazy"
                      src="../../images/testimonials/wd.png"
                      placeholder="blurred"
                      alt="WD"
                      className={styles.userImage}
                    />
                    {/* <Image
                      filename="testimonials/wd.png"
                      className={styles.userImage}
                    ></Image> */}
                  </div>
                  <div className="col-lg-9 text-left">
                    <div className="bodyText1 mb-2">
                      “My Crio journey was awesome and it feels great that I was
                      a part of it! Through the Micro-Experiences I have learnt
                      a bunch of industry relevant skills. There were several
                      modules where I was stuck for long, but the team at Crio
                      always kept me motivated and guided me in the right
                      direction. My major takeaways from the program are the
                      importance of learning how best to learn and pushing
                      myself harder.”
                    </div>
                    <div className="subtitle1 flex  items-center">
                      <div className="mr-1">
                        - Aarshiya Khandelwal, Engineer
                      </div>
                      <a
                        href={
                          'https://www.linkedin.com/in/aarshiya-khandelwal/'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={['fab', 'linkedin']}
                          style={{
                            color: '#52A5EF',
                            // marginRight: "1rem",
                            // marginTop: "-4px",
                            // fontSize: "30px",
                          }}
                          size="lg"
                        />{' '}
                      </a>
                    </div>
                    <div className="subtitle1 font-light">
                      <i>Western Digital</i>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles.testimonialCard} mb-4 p-4`}>
                <div className="row flex justify-center">
                  <div className="col-6  col-lg-3 order-md-last mb-3 md:mb-0">
                    <StaticImage
                      loading="lazy"
                      src="../../images/testimonials/vit.png"
                      placeholder="blurred"
                      alt="VIT"
                      className={styles.userImage}
                    />
                    {/* <Image
                      filename="testimonials/vit.png"
                      className={styles.userImage}
                    ></Image> */}
                  </div>
                  <div className="col-lg-9 text-left">
                    <div className="bodyText1 mb-2">
                      “I have completed multiple bootcamps, tutorials, video
                      lectures and tried many types of learning methodologies
                      that are claimed to be the best, but Crio begs to differ.
                      An e-learning startup with a completely novel approach to
                      hand-on learning was the best learning experience I had
                      until now. I got to work on 4 different projects and a
                      mini project which covered tracks like REST API,
                      networking and network security, OS concepts, unit
                      testing,Java libraries and frameworks (JUnit, Spring,
                      Jackson, Mockito, among others), python libraries and
                      frameworks, build tools (Gradle, Maven) and applied data
                      structures. They also taught some uncommon skills like
                      regression testing and debugging which are of importance
                      while working on a big project but are often neglected by
                      tutorials. Apart from that, the projects are completely
                      scale-able applications which are modular to every need,
                      as it's supposed to be in a product company. They deliver
                      on their promise of providing "Work-like Experience"
                      through their methodology.“
                    </div>
                    <div className="subtitle1 flex  items-center">
                      <div className="mr-1">
                        - Apoorv Goyal, Student Developer
                      </div>
                      <a
                        href={'https://www.linkedin.com/in/apoorv-goyal/'}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={['fab', 'linkedin']}
                          style={{
                            color: '#52A5EF',
                            // marginRight: "1rem",
                            // marginTop: "-4px",
                            // fontSize: "30px",
                          }}
                          size="lg"
                        />{' '}
                      </a>
                    </div>
                    <div className="subtitle1 font-light">
                      <i> Vellore Institute of Technology</i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="pt-24 pb-24 text-center"
            style={{
              //   backgroundImage: `url(${platform})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div>
              <h2 className="container   mb-4">
                Our community is filled with like-minded developers, passionate
                about learning by doing{' '}
              </h2>
            </div>
            <br></br>
            <div className="container   text-center ">
              {' '}
              <div className={`${styles.testimonialCard} mb-4 p-4`}>
                <div className="row flex justify-center ">
                  <div className="col-6  col-lg-3 mb-3 md:mb-0">
                    <StaticImage
                      loading="lazy"
                      src="../../images/testimonials/tino.png"
                      placeholder="blurred"
                      alt="Tino"
                      className={styles.userImage}
                    />
                    {/* <Image
                      filename="testimonials/tino.png"
                      className={styles.userImage}
                    ></Image> */}
                  </div>
                  <div className="col-lg-9 text-left">
                    <div className="bodyText1 mb-2">
                      I have seen many online YouTube videos and various courses
                      on programming, coding and software development in
                      general. Most of these involve only a few topics and give
                      a certain level of understanding around these topics. Also
                      this understanding is limited to what is taught by these
                      courses unless students themselves take effort to learn
                      more about them. Real learning happens only when we join a
                      company or startup. <br></br>
                      <br></br>Crio’s program has proven to be an exception
                      among all these . You will feel the philosophy of Crio,
                      "Learning by Doing", imbibed rightfully into their
                      extensive program. This is really a once in a lifetime
                      opportunity for those who want to experience what software
                      development is all about. This 10 weeks program taught me
                      many things which seldom I could have learned from all
                      these courses. From working my way around writing shell
                      scripts to interacting with APIs in Java and then applying
                      algorithms to real life problems, it was a joyful 10 week
                      ride.
                    </div>
                    <div className="subtitle1 flex  items-center">
                      <div className="mr-1">
                        - Pratik Naik, Software Development Intern
                      </div>
                      <a
                        href={
                          'https://www.linkedin.com/in/pratik-naik-aa9262157/'
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={['fab', 'linkedin']}
                          style={{
                            color: '#52A5EF',
                            // marginRight: "1rem",
                            // marginTop: "-4px",
                            // fontSize: "30px",
                          }}
                          size="lg"
                        />{' '}
                      </a>
                    </div>
                    <div className="subtitle1 font-light">
                      <i>TINO IQ</i>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles.testimonialCard} mb-4 p-4`}>
                <div className="row flex justify-center ">
                  <div className="col-6  col-lg-3  order-md-last mb-3 md:mb-0">
                    <StaticImage
                      loading="lazy"
                      src="../../images/testimonials/bitmesra.png"
                      placeholder="blurred"
                      alt="BIT Mesra"
                      className={styles.userImage}
                    />
                    {/* <Image
                      className={styles.userImage}
                      filename="testimonials/bitmesra.png"
                    ></Image> */}
                  </div>
                  <div className="col-lg-9 text-left">
                    <div className="bodyText1 mb-2">
                      “The past 10 weeks were nothing short of a roller coaster
                      ride to me. From solving those tough real world problems
                      to those countless nights that I have spent brainstorming,
                      everything was extremely useful and will surely help me in
                      my future.
                      <br></br>
                      <br></br> In terms of learning the program is well formed
                      and organised. The Micro-Experiences that are lined up
                      have well organised modules covering in-demand interview
                      and industry relevant skills. I would like to thank the
                      Crio team for such a great learning experience. I would
                      highly recommend this program to anyone who wants to learn
                      the art of "learning by doing" and wants to get an
                      internship-like experience. Thanks Crio.Do, and I'm
                      looking forward to participating in future programs as
                      well.“
                    </div>
                    <div className="subtitle1 flex  items-center">
                      <div className="mr-1">
                        - Ayush Shah, Student Developer
                      </div>
                      <a
                        href={'https://www.linkedin.com/in/ayushshah1234/'}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={['fab', 'linkedin']}
                          style={{
                            color: '#52A5EF',
                            // marginRight: "1rem",
                            // marginTop: "-4px",
                            // fontSize: "30px",
                          }}
                          size="lg"
                        />{' '}
                      </a>
                    </div>
                    <div className="subtitle1 font-light">
                      <i>BIT Mesra</i>
                    </div>
                  </div>
                </div>
              </div>{' '}
              <div className=" flex  items-center justify-center ">
                <a href={'#programs'}>
                  <Button
                    color="primary"
                    variant="contained"
                    className="mt-5 font-bold capitalize text-white"
                  >
                    Explore Crio Programs
                  </Button>
                </a>
              </div>
            </div>
          </section>
        </>
      ) : (
        ''
      )}
      <FooterImage
        style={{ background: state.unlocked ? 'transparent' : '#EAE8DF' }}
      ></FooterImage>
      {/* <Footer></Footer> */}
      <FooterV2 />
    </>
  ) : (
    <div
      className="flex  items-center justify-center"
      style={{ width: '100vw', height: '100vh' }}
    >
      <Loader></Loader>
    </div>
  );
}

// export default AboutUsPage;

export const pageQuery = graphql`
  query {
    ...programImagesFragment

    strapiSale {
      ...strapiSaleFragment
    }

    allStrapiCompanies {
      ...allStrapiCompaniesFragment
    }

    allStrapiPrograms {
      ...allStrapiProgramsFragment
    }
  }
`;
