export const jobAssistanceMessages = [
  {
    message:
      "Crio provides extensive job assistance to maximize your chances of landing a job. Here's how Crio supports you in securing a placement:",
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'first',
    type: 'text',
  },
  {
    message: 'Job Assistance Features',
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'normal',
    type: 'section',
    payload: [
      {
        title: '<strong>Job Referrals and Industry Connections</strong>',
        description:
          'Crio has partnerships with various tech companies, including startups, MNCs, and product-based firms, providing referrals and connecting learners with potential employers after program completion.',
      },
      {
        title:
          '<strong>Portfolio Development with Real-World Projects</strong>',
        description:
          "By working on industry-grade projects throughout the program, you'll build a portfolio that demonstrates your job-ready skills. This portfolio serves as concrete proof of your expertise and makes a compelling addition to your job applications.",
      },
      {
        title: '<strong>Mock Interviews and Technical Preparation</strong>',
        description:
          'Crio offers extensive interview preparation, including mock interviews and sessions on common technical challenges. This prepares you for various aspects of the interview process, including coding tests, problem-solving rounds, and system design discussions.',
      },
      {
        title: '<strong>Resume and GitHub Profile Enhancement</strong>',
        description:
          'Crio helps you craft a polished resume and optimize your GitHub profile to showcase the work you completed during the program. These assets are invaluable when applying for jobs and discussing your skills with recruiters.',
      },
    ],
  },
  {
    message:
      'With this combination of industry connections, technical readiness, and targeted career support, Crio effectively prepares you to secure a job in the tech field. You can start with a free trial session to learn more about these resources.',
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'normal',
    type: 'text',
  },
  {
    message: 'Start with a free trial session',
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'last',
    type: 'callToAction',
    payload: {
      text: 'Start with a free trial session',
      link: 'https://crio.do/free-trial',
    },
  },
];
