import useRegisterMetadata from './useRegisterMetadata';
import React from 'react';
import {
  generateMeta,
  generateMetaDescription,
  generateScript,
  generateTitle,
} from '@src/utils/Masterclass/postRegisterSeo';

export default function useMasterclassPostRegisterSeo() {
  // Destructure necessary functions from useRegisterMetadata hook
  const {
    getEventDetails,
    getCurrentProgram,
    getEventCardSessionImageForSeo,
    getEventSlug,
  } = useRegisterMetadata();

  // Function to generate SEO data for the registration page
  const getPostRegisterationPageSeo = () => {
    // Get event details
    const { sessionName, sessionStartDateTime, shareInfo } = getEventDetails();

    // Get additional metadata
    const program = getCurrentProgram();
    const eventSlug = getEventSlug();
    const eventCardSessionImage = getEventCardSessionImageForSeo();
    const script = generateScript(
      sessionName,
      program,
      sessionStartDateTime,
      eventSlug,
      shareInfo?.whatsappGroupLink,
    );

    // Generate SEO data
    return {
      title: generateTitle(sessionName),
      metaDescription: generateMetaDescription(sessionName),
      meta: generateMeta(eventCardSessionImage, eventSlug),
      script: <script type="application/ld+json">{script}</script>,
      canonicalUrl: `https://www.crio.do/masterclass/${eventSlug}/registered_user/`,
    };
  };

  return { getPostRegisterationPageSeo };
}
