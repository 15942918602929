export const dataAnalyticsCurriculumMessages = [
  {
    message: 'Here is the curriculum of Data Analytics that Crio is offering.',
    sentTime: 'just now',
    sender: 'bot',
    direction: 'incoming',
    position: 'first',
    type: 'text',
  },
  {
    message: 'Data Analytics Fellowship Curriculum',
    sentTime: 'just now',
    sender: 'bot',
    direction: 'incoming',
    position: 'normal',
    type: 'section',
    payload: [
      {
        title: 'Data Analytics and Python Foundations',
        content:
          '<p>This section introduces <strong>Data Analytics</strong> and <strong>Data Science</strong>, covering key tools like <strong>Excel</strong> for data manipulation, and <strong>SQL</strong> for querying relational databases. Learners also begin their <strong>Python programming</strong> journey, mastering data structures, control flow, and file handling, setting the stage for more advanced data analysis techniques.</p>',
      },
      {
        title: 'Intermediate Python, Statistics, and Data Analytics',
        content:
          '<p>Learners expand their <strong>Python</strong> skills with <strong>error handling</strong>, <strong>object-oriented programming (OOP)</strong>, and <strong>API integration</strong>. This section also covers <strong>statistics</strong> and <strong>probability</strong>, essential for analyzing real-world data. With libraries like <strong>NumPy</strong> and <strong>Pandas</strong>, learners gain practical experience in data manipulation and visualization using <strong>Matplotlib</strong>.</p>',
      },
      {
        title: 'Data Visualization & Storytelling',
        content:
          '<p>This section focuses on <strong>data visualization</strong> and <strong>storytelling</strong>. Learners use tools like <strong>Seaborn</strong>, <strong>Tableau</strong>, and <strong>Power BI</strong> to create interactive and impactful visualizations. The emphasis is on designing visuals that communicate data insights clearly and effectively, making complex data accessible and understandable for various audiences.</p>',
      },
      {
        title: 'Advanced Python, GenAI, and Automation',
        content:
          '<p>Learners explore advanced <strong>Python</strong> techniques for <strong>web scraping</strong> using <strong>BeautifulSoup</strong>, <strong>Generative AI (GenAI)</strong> for enhanced data analysis, and <strong>automation</strong> using <strong>Linux</strong> and <strong>Git</strong> for version control. This section enables learners to automate data workflows and integrate AI to improve efficiency in data analysis.</p>',
      },
      {
        title: 'Machine Learning, MLOps, and Big Data Engineering',
        content:
          '<p>This section covers <strong>machine learning</strong> algorithms, <strong>MLOps</strong> for deploying models, and <strong>cloud platforms</strong> like <strong>Docker</strong> for containerization. Learners also dive into <strong>Big Data</strong> concepts, working with tools like <strong>PySpark</strong> and <strong>ETL processes</strong> to handle large datasets and build scalable data pipelines.</p>',
      },
      {
        title: 'Data Structures, Algorithms, and Interview Prep',
        content:
          '<p>This section focuses on mastering <strong>Data Structures</strong> and <strong>Algorithms (DSA)</strong>, helping learners solve coding challenges efficiently. It also includes comprehensive <strong>interview prep</strong>, with practice on conceptual questions, mock interviews, and aptitude tests to build confidence for technical interviews in data analytics and data science roles.</p>',
      },
    ],
  },
  {
    message: 'Industry-grade hands-on Projects',
    sentTime: 'just now',
    sender: 'bot',
    direction: 'incoming',
    position: 'normal',
    type: 'carousel',
    payload: [
      {
        title: 'Stock Analyst at Zerodha',
        content: 'Analyze the stock performance trends of top tech companies.',
        imageType: 'Zerodha',
      },
      {
        title: 'Business Intelligence Analyst at Flipkart',
        content: 'Create dynamic dashboards highlighting trends and insights.',
        imageType: 'Flipkart',
      },
      {
        title: 'Business Analyst at Zomato',
        content: 'Unlock order insights and customer patterns from data.',
        imageType: 'Zomato',
      },
      {
        title: 'Data Analyst at Uber',
        content: 'Uncover behavioral data from Uber rides.',
        imageType: 'Uber',
      },
      {
        title: 'Price Analyst at Spinny',
        content: 'Analyze data to predict best selling prices for used cars.',
        imageType: 'Spinny',
      },
      {
        title: 'ML Engineer at MakeMyTrip',
        content: 'Create a multi-agent holiday itinerary planner.',
        imageType: 'MMT',
      },
    ],
  },
  {
    message: 'Book a free trial with Crio',
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'last',
    type: 'callToAction',
    payload: {
      text: 'Book a free trial with Crio',
      link: 'https://crio.do/free-trial',
      programInterested: 'Data Analytics',
    },
  },
];
