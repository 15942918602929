import { useLocation } from '@reach/router';

const useProvisionalEnrollment = () => {
  const location = useLocation();

  const isProvisionalEnrollment = location.pathname.includes(
    'provisional-enrollment',
  );

  return { isProvisionalEnrollment };
};

export default useProvisionalEnrollment;
