// Time constants
export const MS_PER_DAY = 1000 * 60 * 60 * 24;

// Threshold percentages
export const FIRST_THRESHOLD_PERCENT = 20;
export const SECOND_THRESHOLD_PERCENT = 60;
export const FINAL_THRESHOLD_PERCENT = 100;

// Seat factors
export const FIRST_SEAT_FACTOR = 0.4;
export const SECOND_SEAT_FACTOR = 0.8;
export const FINAL_SEAT_FACTOR = 1.0;

// Seat decrease constants
export const MIN_SEATS_MORNING = 50;
export const MAX_SEATS_MORNING = 60;
export const MIN_SEATS_BEFORE_SESSION = 20;
export const MAX_SEATS_BEFORE_SESSION = 40;
