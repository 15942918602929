import masterClassData from '@src/data/masterclass_db.json';
import { useLocation } from '@reach/router';
import { getImage } from 'gatsby-plugin-image';
import {
  filterImagesByEventSlug,
  filterMasterclassImages,
  findAboutCrioImg,
  findBannerImage,
  findEventCardSessionImage,
  findSessionHostImage,
  getCurrentMasterclassEventDetail,
  getFormattedDate,
  sanitizeEventDetails,
} from '@src/utils/Masterclass';
import useGraphqlImage from './useGraphqlImage';
import useResizer from '@components/extra/useResizer';
import { useContext } from 'react';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '@src/context/GlobalContextProvider';
import { navigate } from '@reach/router';
import { getCountryCodeAndPhone } from '@src/utils/helper';
import webinarjamService from '../../../utils/webinarjam-service';
import {
  DEFAULT_CALENDAR_LOCATION,
  GOOGLE_CALENDAR_URL,
  TIME_ZONE,
} from '@src/constants/masterclass';

export default function useRegisterMetadata() {
  const location = useLocation();
  const isMobile = useResizer();
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const pathName = location?.pathname;

  // Get masterclass details from strapi db:
  const masterClassDetails = masterClassData?.strapi_metadata;

  // Get current masterclass event details based on slug present in URL:
  const currentMasterclassEventDetail = getCurrentMasterclassEventDetail(
    masterClassDetails?.masterClassEvents,
    pathName,
  );

  // Get common details which are same across all masterclasses pages. Eg: About Crio, Landing Page details etc.
  const masterClassCommonDetails =
    masterClassData?.strapi_metadata?.masterClassDetails?.[0]?.attributes;

  // Get event slug for current masterclass event:
  const eventSlug = currentMasterclassEventDetail?.eventSlug;

  // Get all gatsby image nodes for masterclass events:
  const { masterclassEventsImgNode } = useGraphqlImage();
  const allMasterclassImg = masterclassEventsImgNode?.Thumbnail?.nodes;

  // Filter images based on event slug:
  const filteredEventImages = filterImagesByEventSlug(
    masterclassEventsImgNode.Thumbnail.nodes,
    eventSlug,
  );

  // Filter images which are general to all masterclass pages(not specific to any event), Eg: Landing Page Hero Image, About Crio Image etc.
  const filteredMasterclassImages = filterMasterclassImages(
    masterclassEventsImgNode?.Thumbnail?.nodes,
  );

  //HANDLE FUNCTIONS:

  // Get banner image for current masterclass event:
  const getBannerImage = () => {
    const bannerImage = findBannerImage(filteredEventImages, isMobile);
    return getImage(bannerImage?.childImageSharp);
  };

  // Get banner image src for current masterclass event:
  const getBannerImgSrc = () => {
    const bannerImage = findBannerImage(filteredEventImages, isMobile);
    return bannerImage?.childImageSharp.gatsbyImageData.images.fallback.src;
  };

  // Get session host image for current masterclass event:
  const getSessionHostImage = () => {
    const sessionHostImg = findSessionHostImage(filteredEventImages);
    return getImage(sessionHostImg?.childImageSharp);
  };

  // Get coupon details for current masterclass event:
  const getCouponDetails = () => {
    return currentMasterclassEventDetail?.coupon_details;
  };

  // Get event details for current masterclass event:
  const getEventDetails = (event) => {
    return sanitizeEventDetails(
      event ? event : currentMasterclassEventDetail,
      allMasterclassImg,
    );
  };

  // Get about masterclass details for current masterclass event:
  const getAboutMasterclass = () => {
    return currentMasterclassEventDetail?.sessionDetails?.aboutSession;
  };

  // Get masterclass outcomes for current masterclass event:
  const getMasterclassOutcomes = () => {
    return currentMasterclassEventDetail?.sessionDetails?.sessionOutcomes;
  };

  // Get session host info for current masterclass event:
  const getSessionHostInfo = () => {
    return currentMasterclassEventDetail?.sessionDetails?.sessionHostInfo;
  };

  // Get about crio details for current masterclass event:
  const getAboutCrioDo = () => {
    return masterClassCommonDetails?.aboutCrio;
  };

  // Get about crio image for current masterclass event:
  const getAboutCrioImg = () => {
    const aboutCrioImg = findAboutCrioImg(filteredMasterclassImages);
    return getImage(aboutCrioImg?.childImageSharp);
  };

  // Get current program for current masterclass event:
  const getCurrentProgram = () => {
    return currentMasterclassEventDetail?.program;
  };

  // Helper function to filter and sort events
  const filterAndSortEvents = (events, filterCondition) => {
    return events.filter(filterCondition).sort((a, b) => {
      const dateA = new Date(a.attributes.sessionDetails.sessionStartDateTime);
      const dateB = new Date(b.attributes.sessionDetails.sessionStartDateTime);
      return dateB - dateA;
    });
  };

  // Get upcoming/past events for current masterclass event:
  const getPastAndUpcomingEvents = () => {
    const currentEventProgramType = currentMasterclassEventDetail?.program;
    const upcomingEvents = masterClassDetails?.masterClassEvents;
    const currentDateTime = new Date();

    if (!upcomingEvents) {
      return { events: [], isShowingPreviousEvents: false };
    }

    // Filter and sort upcoming events
    const sortedUpcomingEvents = filterAndSortEvents(
      upcomingEvents,
      (event) => {
        const eventDateTime = new Date(
          event.attributes.sessionDetails.sessionStartDateTime,
        );
        return (
          event.attributes.program === currentEventProgramType &&
          event.attributes.eventSlug !== eventSlug &&
          eventDateTime > currentDateTime
        );
      },
    );

    if (sortedUpcomingEvents.length > 0) {
      return {
        events: sortedUpcomingEvents.map((event) =>
          getEventDetails(event.attributes),
        ),
        isShowingPreviousEvents: false,
      };
    }

    // Filter and sort previous events
    const sortedPreviousEvents = filterAndSortEvents(
      upcomingEvents,
      (event) => {
        const eventDateTime = new Date(
          event.attributes.sessionDetails.sessionStartDateTime,
        );
        return (
          event.attributes.program === currentEventProgramType &&
          event.attributes.eventSlug !== eventSlug &&
          eventDateTime <= currentDateTime
        );
      },
    );

    return {
      events: sortedPreviousEvents.map((event) =>
        getEventDetails(event.attributes),
      ),
      isShowingPreviousEvents: true,
    };
  };

  // Get current event webinar id for current masterclass event:
  const getCurrentEventWebinarId = () => {
    return currentMasterclassEventDetail?.everWebinarDetails?.webinarjamId;
  };

  // Get event card session image for current masterclass event:
  const getEventCardSessionImage = () => {
    const eventCardSessionImage =
      findEventCardSessionImage(filteredEventImages);
    return getImage(eventCardSessionImage);
  };

  // Get event card session image src for current masterclass event:
  const getEventCardSessionImageSrc = () => {
    const eventCardSessionImage =
      findEventCardSessionImage(filteredEventImages);
    return eventCardSessionImage?.childImageSharp.gatsbyImageData.images
      .fallback.src;
  };

  // Get event card session image for SEO for current masterclass event:
  const getEventCardSessionImageForSeo = () => {
    const eventCardSessionImage =
      findEventCardSessionImage(filteredEventImages);
    return eventCardSessionImage?.relativePath.split('/').pop();
  };

  // Get event slug for current masterclass event:
  const getEventSlug = () => {
    return eventSlug;
  };

  // Register for current masterclass event:
  const onSubmitL2 = async (payload) => {
    const webinarjamId = getCurrentEventWebinarId();
    const { isPastEvent } = getEventDetails();

    try {
      const registerPayload = {
        firstName: payload.name.split(' ')?.[0] ?? '',
        lastName: payload.name.split(' ')?.[1] ?? '',
        email: state.email,
        webinarId: webinarjamId,
        phoneCountryCode: getCountryCodeAndPhone(state.phone)?.countryCode,
        phone: getCountryCodeAndPhone(state.phone)?.phoneNum,
      };

      const response = await webinarjamService.register(
        registerPayload,
        isPastEvent ? 'everwebinar' : 'webinarjam',
      );

      dispatch({
        type: 'SET_THANK_YOU_URL',
        payload: [
          ...(state.thankYouUrl ?? []),
          { [eventSlug]: response.status.user.thank_you_url },
        ],
      });
      return response.status.user.thank_you_url;
    } catch (error) {
      console.error('Error registering for webinar', error);
    }
  };

  const getCurrentProgramUpcomingEvents = () => {
    const currentEventProgramType = currentMasterclassEventDetail?.program;
    const upcomingEvents = masterClassDetails?.masterClassEvents;
    const currentDateTime = new Date();

    // Filter and sort upcoming events
    const sortedUpcomingEvents = filterAndSortEvents(
      upcomingEvents,
      (event) => {
        const eventDateTime = new Date(
          event.attributes.sessionDetails.sessionStartDateTime,
        );
        return (
          event.attributes.program === currentEventProgramType &&
          event.attributes.eventSlug !== eventSlug &&
          eventDateTime > currentDateTime
        );
      },
    );

    return sortedUpcomingEvents.map((event) =>
      getEventDetails(event.attributes),
    );
  };

  // Post OTP Verification flow:
  // Redirect to thank you page after successful registration and OTP verification
  const onSubmitOtp = async (thankYouUrl) => {
    const { isPastEvent } = getEventDetails();

    // If the event is past event, then redirect to thank you url obtained from
    if (isPastEvent && thankYouUrl) {
      navigate(thankYouUrl);
    } else {
      navigate(`/masterclass/${eventSlug}/registered_user${location.search}`);
    }
  };

  //Handle add to calendar:
  const handleAddToCalendar = () => {
    const sessionDetail = getEventDetails();

    const thankYouUrl =
      state?.thankYouUrl?.find((entry) => entry[eventSlug])?.[eventSlug] ??
      DEFAULT_CALENDAR_LOCATION;

    const eventDetails = {
      text: sessionDetail?.sessionName,
      details: sessionDetail?.sessionDescription,
      location: thankYouUrl,
      dates: getFormattedDate(sessionDetail?.sessionStartDateTime),
      ctz: TIME_ZONE,
    };

    // Convert eventDetails to query string
    const queryString = Object.entries(eventDetails)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      )
      .join('&');

    const googleCalendarUrl = `${GOOGLE_CALENDAR_URL}${queryString}`;
    window.open(googleCalendarUrl, '_blank');
  };
  return {
    masterClassDetails,
    currentMasterclassEventDetail,
    getBannerImage,
    getBannerImgSrc,
    getSessionHostImage,
    getEventDetails,
    getAboutMasterclass,
    getMasterclassOutcomes,
    getSessionHostInfo,
    getAboutCrioDo,
    getAboutCrioImg,
    onSubmitL2,
    onSubmitOtp,
    getPastAndUpcomingEvents,
    getCurrentProgramUpcomingEvents,
    getCurrentEventWebinarId,
    getCurrentProgram,
    getEventCardSessionImage,
    getEventCardSessionImageSrc,
    getEventCardSessionImageForSeo,
    getEventSlug,
    handleAddToCalendar,
    getCouponDetails,
  };
}
