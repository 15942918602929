import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export const ChatFAB = ({ onClick, showTooltip }) => {
  return (
    <div className="fixed bottom-4 right-4 flex flex-col items-end md:bottom-12 md:right-12">
      {showTooltip && (
        <div className="relative mb-1 -mr-2 h-fit w-[100px] rounded-lg bg-white p-2 shadow-xl after:absolute after:left-1/2 after:top-[100%] after:h-0 after:w-0 after:-translate-x-1/4 after:border-l-[8px] after:border-r-[8px] after:border-t-[8px] after:border-l-transparent after:border-r-transparent after:border-t-white md:-mr-4 md:w-[120px]">
          <p className="text-[10px] leading-snug text-gray-800 md:text-[12px]">
            Hi, I am Kiran. How may I help you?
          </p>
        </div>
      )}
      <button
        onClick={onClick}
        className="mt-2 flex h-[80px] w-[80px] items-center justify-center p-0 transition-transform duration-200 hover:scale-105 md:h-[80px] md:w-[80px]"
      >
        <div className="relative h-full w-full">
          <StaticImage
            src="../../../images/Chatbot/chatLogoV2.png"
            alt="Chat with Kiran"
            className="!absolute !h-full !w-full"
            placeholder="blurred"
            layout="fullWidth"
            transformOptions={{
              fit: 'cover',
              cropFocus: 'centre',
            }}
          />
        </div>
      </button>
    </div>
  );
};
