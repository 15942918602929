import { backendCurriculumMessages } from '../data/messages/curriculum/backend-curriculum';
import { dataAnalyticsCurriculumMessages } from '../data/messages/curriculum/data-analytics-curriculum';
import { fullStackCurriculumMessages } from '../data/messages/curriculum/full-stack-development';
import { qaAutomationCurriculumMessages } from '../data/messages/curriculum/qa-automation-curriculum';
import { systemDesignCurriculumMessages } from '../data/messages/curriculum/system-design-curriculum';
import { exploreMessages } from '../data/messages/exploreMessages';
import { fresherMessages } from '../data/messages/fresherMessages';
import { jobAssistanceMessages } from '../data/messages/jobAssistanceMessages';
import { noCodingMessages } from '../data/messages/noCodingMessages';
import { programMessages } from '../data/messages/programMessages';
import { switchJobMessages } from '../data/messages/switchJobMessages';
import { upskillMessages } from '../data/messages/upskillMessages';

export const formatResponseToMessages = (responseId) => {
  switch (responseId) {
    case 'r8':
      return switchJobMessages;
    case 'r9':
      return fresherMessages;
    case 'r10':
      return upskillMessages;
    case 'r11':
      return exploreMessages;
    case 'r17':
      return programMessages;
    case 'r18':
      return jobAssistanceMessages;
    case 'r20':
      return noCodingMessages;
    case 'r21':
      return fullStackCurriculumMessages;
    case 'r22':
      return backendCurriculumMessages;
    case 'r23':
      return systemDesignCurriculumMessages;
    case 'r24':
      return qaAutomationCurriculumMessages;
    case 'r25':
      return dataAnalyticsCurriculumMessages;
    default:
      return [
        {
          message:
            "I'll help you with that. What specific information would you like to know?",
          sentTime: new Date().toISOString(),
          sender: 'bot',
          direction: 'incoming',
          position: 'single',
          type: 'text',
        },
      ];
  }
};
