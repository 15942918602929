import Button from '@components/Button/index';
import { navigate } from 'gatsby'; //import navigate from gatsby
import React, { useContext, useState } from 'react';
import { GTM } from '../../analytics/gtm';
import { gtmEvents } from '../../analytics/gtmEventsConstant';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../../context/GlobalContextProvider';
import * as styles from './LeadCaptureVersionTwo.module.scss';

export default function LeadCaptureVersionTwo(props) {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const [email, setEmail] = useState('');
  const [clicked, setClicked] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  return !clicked ? (
    <span
      onClick={() => {
        if (state.email.length < 2) {
          setClicked(true);
          props.onClick && props.onClick();

          return;
        } else {
          dispatch({
            type: 'SET_EMAIL',
            payload: state.email,
          });
          GTM.setEmail(state.email);
          GTM.login({
            userId: state.email,
            email: state.email,
          });
          //GTM Event for Email Capture
          if (!state.isEmailEventSubmitted) {
            GTM.track(gtmEvents.EMAIL_SUBMITTED, {
              type: props.type,
              location: props.location,
            });
            dispatch({
              type: 'SET_IS_EMAIL_EVENT_SUBMITTED',
              payload: true,
            });
          }
          navigate('/registration', {
            state: {
              link: props.link,
              type: props.type,
              // logo: "Crio_Light.png",
            },
          });
        }
      }}
    >
      <Button {...props}></Button>
    </span>
  ) : (
    <span
      id={styles.parent}
      className=" mb-4 flex flex-col items-center text-black md:mb-0 md:mr-3"
    >
      <form
        id={styles.myForm}
        className="mb-3"
        onSubmit={(e) => {
          e.preventDefault();
          dispatch({
            type: 'SET_EMAIL',
            payload: email,
          });
          GTM.setEmail(email);
          GTM.login({
            userId: email,
            email: email,
          });
          //GTM Event for Email Capture
          if (!state.isEmailEventSubmitted) {
            GTM.track(gtmEvents.EMAIL_SUBMITTED, {
              type: props.type,
              location: props.location,
            });
            dispatch({
              type: 'SET_IS_EMAIL_EVENT_SUBMITTED',
              payload: true,
            });
          }
          if (process.env.GATSBY_ENV == 'production') {
            fetch(
              `https://script.google.com/macros/s/AKfycbzZu0sfE3ZdlifBokz_l_ei9duEq9YZ3mEN8mf5cDsM8JZyF-2_Et7aiwRW7i7jnQ-6/exec?endpoint=website-leads&email=${email
                .trim()
                .toLowerCase()}&country=${state.country}&type=${
                props.type
              }&location=${props.location}&utm=${encodeURIComponent(
                state.utm,
              )}&adblocker=${
                typeof window.webengage == 'undefined' ? true : false
              }&url=${window.location.href}`,
            );
          }
          navigate('/registration', {
            state: {
              link: props.link,
              type: props.type,
              // logo: "Crio_Light.png",
            },
          });
        }}
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <input
          type="email"
          className="bodyText1 font-light"
          placeholder="Enter your Email"
          style={{
            flexGrow: '2',
            padding: '12px 1rem',
            border: 'none',
          }}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          required
        ></input>
        <button
          type="submit"
          className="subtitle1"
          style={{ background: props.background, color: props.color }}
        >
          {props.children}
        </button>{' '}
      </form>{' '}
      <button
        data-aos="fade-up"
        data-aos-duration="1000"
        onClick={() => {
          setClicked(false);
          props.onCloseClick && props.onCloseClick();
        }}
        className="subtitle1"
        style={{
          background: props.background,
          height: '50px',
          color: props.color,
        }}
      >
        Close
      </button>
    </span>
  );
}
