import React from 'react';

export default function Divider({ className, color, text }) {
  return (
    <div className={`flex h-full flex-col items-center ${className}`}>
      <div className={`w-[2px] flex-1 ${color}`} />
      {text && (
        <span className="text-muted-foreground my-2 font-rubik text-lg">
          {text}
        </span>
      )}
      <div className={`w-[2px] flex-1 ${color}`} />
    </div>
  );
}
