import { GTM } from '../../../analytics/gtm';
import { gtmEvents } from '../../../analytics/gtmEventsConstant';
import { generateLead } from '../hooks/useLeadGeneration';

export const validationCallbacks = {
  // Email related callbacks
  saveEmailToGlobalState: (email, dispatch, state) => {
    dispatch({ type: 'SET_EMAIL', payload: email });
  },

  trackEmailInGTM: (email, dispatch, state) => {
    if (!state.isEmailSubmitted) {
      GTM.track(gtmEvents.EMAIL_SUBMITTED, {
        type: 'CHATBOT',
      });
      GTM.setEmail(email);
      //   dispatch({ type: 'SET_IS_EMAIL_SUBMITTED', payload: true });
    }
  },

  // Phone related callbacks
  savePhoneToGlobalState: (phone, dispatch, state) => {
    dispatch({ type: 'SET_PHONE', payload: phone });
  },

  trackPhoneInGTM: (phone, dispatch, state) => {
    GTM.login({
      phone: phone,
      whatsappNumber: phone,
    });
  },

  //Create a new lead in leadsquared
  createLeadInLeadSquared: async (phone, dispatch, state) => {
    await generateLead(state.email, phone, dispatch);
  },
};
