import { graphql } from 'gatsby';
import SEO from '@components/Common/SEO/index';
import '@fortawesome/fontawesome-svg-core/styles.css';
import React, { useContext, useState } from 'react';
import FooterV2 from '@components/FooterV2/index';
import CrackedCrioCareersSection from '@components/v5/CrackedCrioCareersSection/index';
import StatsPanel from '@components/v5/StatsPanel/index';
import ProjectsSection from '@components/v5/ProjectsSection/index';
import RoundedLayout from '@components/v5/layouts/RoundedLayout/index';
import VideoTestimonialSection from '@components/v5/VideoTestimonialSection/index';
import CrioComparisionSection from '@components/v5/CrioComparisionSection/index';
import FAQSection from '@components/v5/FAQSection/index';
import WhyCrioSection from '@components/v5/WhyCrioSection/index';
import CrioButton from '@components/v5/CrioButton/index';
import PostContentCTALayout from '@components/v5/layouts/PostContentCTALayout/index';
import { Link } from 'gatsby';
import { ERoutes } from '@src/constants/routes/index';
import { EProgramIDs } from '@src/constants/data/programs/index';
import SaleMiddleStrip from '@components/v5/Sale/SaleMiddleStrip';
import { stripData } from '@components/Sale/data';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import ScrollButtonComponent from '@components/ScrollButtonComponent/index';
import {
  LEAD_GEN_HOME_PROJECTS,
  LEAD_GEN_FLOATING_CTA,
  LEAD_GEN_WHY_CRIO,
  LEAD_GEN_PROJECTS,
  LEAD_GEN_CAREER_ASSISTANCE,
  LEAD_GEN_TESTIMONIALS,
  LEAD_GEN_CRIO_COMPARISION,
  LEAD_GEN_FAQ,
  LEAD_GEN_DEVELOPERS_CRACKED,
  LEAD_GEN_CURRICULUM_PRICING_CARD,
  LEAD_GEN_MENTOR_SECTION,
} from '../constants/LeadGenerationConstants/index';
import { useWebEngage } from '@src/hooks/useWebEngage';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';
// import FspStylePageHero from '@components/v5/FspStylePageHero/index';
import qa from '../seo/jsonld/qa.json';
import CurriculumV4 from '@components/v5/CurriculumV4';
import PPCNavbar from '@components/PPCNavbar';
import FspPricingCardSection from '@components/v5/FspPricingCardSection';
import SkillSection from '@components/v5/SkillSection';
import CustomHeroSection from '@components/CustomHeroSection';
import BottomStrip from '@components/PPCNavbar/BottomStrip';
import MentorTestimonialsSection from '@components/MentorSection';
import useProvisionalEnrollment from '@src/hooks/useProvisionalEnrollment';
import useAffiliate from '@src/hooks/useAffiliate';
import { ConfirmSeatButton } from './{FspStylePpcPagesYaml.slug}';
import { qaCurriculumData } from '@src/constants/qaCurriculumData';
import AffiliateSuccessPopup from '@components/Popups/AffiliateSuccessPopup';
import useSendActivities from '@src/hooks/useSendActivities';

export default function QaStylePpcPage(props) {
  const { captureUserClickEvent } = useWebEngage();
  const { isAffiliatePage } = useAffiliate();
  const state = useContext(GlobalStateContext);
  const { sendActivityHandler } = useSendActivities();

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const onPlacementButtonClick = () => {
    captureUserClickEvent('Get our Placement Stats', {
      placementLocation: 'QA_PAGE',
    });

    sendActivityHandler(
      ELeadSquaredActivityCode.QA_PAGE_GET_OUR_PLACEMENT_STATS_CLICKED,
    );
  };

  const { data: queryData } = props;
  const { qaStylePpcPagesYaml: data } = queryData;
  const program = props.data.allStrapiPrograms.nodes.find(
    (ele) => ele.PrimaryProgramID == data.PrimaryProgramID,
  );

  const sale = props.data.strapiSale.SaleID;
  const getProjectsSectionPreContent = (sale) => {
    switch (sale) {
      case 'true':
        return (
          <div className="mx-4 mb-10 flex justify-center">
            <SaleMiddleStrip data={stripData[program.PrimaryProgramID]}>
              <LeadGenerationButton
                type="Book Your Trial, Now"
                buttonLocation={LEAD_GEN_HOME_PROJECTS}
              />
            </SaleMiddleStrip>
          </div>
        );

      default:
        return <></>;
    }
  };

  const shouldShowAffiliatePagePopup = (shouldShowPopup = false) => {
    setIsPopupOpen(shouldShowPopup);
  };

  const { isProvisionalEnrollment } = useProvisionalEnrollment();

  // return <></>;

  return (
    <div id="top">
      <SEO
        title={data.SEOTitle}
        description={data.SEODescription}
        canonicalUrl={data.canonicalUrl}
        meta={data.metatags || []}
      >
        <script type="application/ld+json">{JSON.stringify(qa)}</script>
      </SEO>
      <ScrollButtonComponent
        component={
          <LeadGenerationButton
            type="Book Your Trial, Now"
            buttonLocation={LEAD_GEN_FLOATING_CTA}
          />
        }
        startAt={600}
      ></ScrollButtonComponent>
      <PPCNavbar />
      <div className="bg-white">
        <div className="">
          <CustomHeroSection
            props={{
              shouldShowAffiliatePagePopup: shouldShowAffiliatePagePopup,
            }}
            data={{
              slug: 'default',
              pageUrl: 'QA',
              heroStyles: null,
              sectionWrapperStyles: null,
              sectionOneStyles: null,
              sectionTwoStyles: null,
              formStyles: null,
              isInfoCardVisible: true,
              secondFold:
                '<p className="text-[14px] md:text-[20px]"><strong>Faster product releases</strong> and <strong>higher software reliability</strong> have skyrocketed the demand for skilled QA engineers. Seize the growing opportunity with <strong>high-demand testing tools</strong>.</p>',
              firstFold:
                '<h1 class="text-center font-manrope text-[28px] font-bold md:text-left md:text-[48px]">Fellowship Program in QA Automation (SDET)</h1>',
              createdAt: '2024-10-27T17:04:23.525Z',
              updatedAt: '2024-10-28T04:07:09.072Z',
              publishedAt: '2024-10-27T17:04:24.681Z',
            }}
          />
        </div>
        <AffiliateSuccessPopup
          isOpen={isPopupOpen}
          onClose={() => setIsPopupOpen(false)}
        />
        {isProvisionalEnrollment || isAffiliatePage ? (
          <div>
            <VideoTestimonialSection
              quote={data.testimonialQuote}
              disableCareerHandbook={
                data.PrimaryProgramID === EProgramIDs.QA_AUTOMATION_TESTING
              }
              postContent={<ConfirmSeatButton />}
            ></VideoTestimonialSection>
            <CurriculumV4
              heading={''}
              description={''}
              quote={data.curriculumQuote}
              type={data.PrimaryProgramID}
              curriculum={qaCurriculumData.curriculum}
              advancedCurriculum={qaCurriculumData.advancedCurriculum}
              dreamCompanies={data.dreamCompanies}
              superDreamCompanies={data.superDreamCompanies}
            />
            <div className="min-h-[800px] py-10">
              <ProjectsSection
                heading={data.projectsTitle}
                description={data.projectsDescriptionV2}
                projects={data.projects}
                preContent={getProjectsSectionPreContent(sale)}
              ></ProjectsSection>
            </div>
          </div>
        ) : (
          <>
            <div className="mt-10">
              <div id="curriculum" className="section-target"></div>
              <CurriculumV4
                heading={''}
                description={''}
                quote={data.curriculumQuote}
                type={data.PrimaryProgramID}
                curriculum={data.curriculum}
                advancedCurriculum={data.advancedCurriculum}
                dreamCompanies={data.dreamCompanies}
                superDreamCompanies={data.superDreamCompanies}
              />
              <div id="mentor-section" className="section-target"></div>
              <MentorTestimonialsSection
                postContent={
                  <PostContentCTALayout>
                    <LeadGenerationButton
                      type="Request 1:1 Expert Session"
                      text="Request 1:1 Expert Session"
                      buttonLocation={LEAD_GEN_MENTOR_SECTION}
                    />
                  </PostContentCTALayout>
                }
              ></MentorTestimonialsSection>
            </div>
            <div id="crio-success-stories" className="section-target"></div>
            <CrackedCrioCareersSection
              postContent={
                <PostContentCTALayout>
                  <div>
                    <div className="-mb-10">
                      <StatsPanel></StatsPanel>
                    </div>
                    <PostContentCTALayout className="mb-10">
                      <Link to={ERoutes.PLACEMENTS}>
                        <CrioButton
                          onClick={onPlacementButtonClick}
                          variant="secondary"
                          className="h-[64px] w-[300px] border-v5-neutral-600 py-6 text-v5-neutral-600 sm:w-auto"
                        >
                          Get our Placement Stats
                        </CrioButton>
                      </Link>
                      <LeadGenerationButton
                        type="Book Your Trial, Now"
                        buttonLocation={LEAD_GEN_DEVELOPERS_CRACKED}
                        className="h-[64px] w-[300px]"
                      />
                    </PostContentCTALayout>
                  </div>
                </PostContentCTALayout>
              }
            ></CrackedCrioCareersSection>
            <SkillSection
              dreamCompanies={data.skills}
              heading={
                'Gain the Competitive Edge with Mastery of Essential QA Automation Tools'
              }
              description={
                'Master leading QA Automation tools like Selenium, TestNG, API Testing, XPath.'
              }
            />
            <div id="projects" className="section-target"></div>

            <div className="min-h-[800px] py-10">
              <ProjectsSection
                heading={data.projectsTitle}
                description={data.projectsDescription}
                projects={data.projects}
                postContent={
                  <PostContentCTALayout>
                    <LeadGenerationButton
                      type="Book Your Trial, Now"
                      buttonLocation={LEAD_GEN_PROJECTS}
                    />
                  </PostContentCTALayout>
                }
                preContent={getProjectsSectionPreContent(sale)}
              ></ProjectsSection>
            </div>
            {/* <CompanySection dreamCompanies={data.dreamCompanies} /> */}
            <div id="career-services" className="section-target"></div>
            <WhyCrioSection
              heading={data.careerTitle}
              description={data.careerDescription}
              data={data.careers}
              postContent={
                <PostContentCTALayout>
                  <LeadGenerationButton
                    type="Book Your Trial, Now"
                    buttonLocation={LEAD_GEN_CAREER_ASSISTANCE}
                  />
                </PostContentCTALayout>
              }
            ></WhyCrioSection>
            <FspPricingCardSection
              price={data.price}
              duration={data.priceDuration}
              sale={sale}
              program={program.PrimaryProgramID}
              postContent={
                <PostContentCTALayout>
                  <LeadGenerationButton
                    type="Book Your Trial, Now"
                    buttonLocation={LEAD_GEN_CURRICULUM_PRICING_CARD}
                  />
                </PostContentCTALayout>
              }
            ></FspPricingCardSection>
            <VideoTestimonialSection
              isTypeQa
              quote={data.testimonialQuote}
              programID={EProgramIDs.QA_AUTOMATION_TESTING}
              postContent={
                <PostContentCTALayout>
                  <div className="-mt-16 xxs:-mt-10 sm:-mt-0">
                    <LeadGenerationButton
                      type="Book Your Trial, Now"
                      buttonLocation={LEAD_GEN_TESTIMONIALS}
                    />
                  </div>
                </PostContentCTALayout>
              }
            ></VideoTestimonialSection>
            <WhyCrioSection
              heading={data.highlightsTitle}
              description={data.highlightsDescription}
              data={data.highlights}
              postContent={
                <PostContentCTALayout>
                  <LeadGenerationButton
                    type="Book Your Trial, Now"
                    buttonLocation={LEAD_GEN_WHY_CRIO}
                  />
                </PostContentCTALayout>
              }
            ></WhyCrioSection>
            <CrioComparisionSection
              isTypeQA
              postContent={
                <div className="-mt-12">
                  <PostContentCTALayout>
                    <LeadGenerationButton
                      type="Book Your Trial, Now"
                      buttonLocation={LEAD_GEN_CRIO_COMPARISION}
                    />
                  </PostContentCTALayout>
                </div>
              }
            ></CrioComparisionSection>
            <div id="faq" className="section-target"></div>
            <RoundedLayout
              roundedBg="bg-v5-green-100"
              className="bg-white pb-16"
              bottom
            >
              <FAQSection
                data={data.faqs}
                postContent={
                  <PostContentCTALayout>
                    <LeadGenerationButton
                      type="Book Your Trial, Now"
                      buttonLocation={LEAD_GEN_FAQ}
                    />
                  </PostContentCTALayout>
                }
              ></FAQSection>
            </RoundedLayout>
          </>
        )}
      </div>

      <FooterV2></FooterV2>
      <BottomStrip />
    </div>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    qaStylePpcPagesYaml(id: { eq: $id }) {
      PrimaryProgramID
      slug
      canonicalUrl
      SEOTitle
      SEODescription
      metatags {
        property
        content
      }
      heroTitle
      heroDescription
      heroImage {
        childImageSharp {
          gatsbyImageData(
            width: 500
            quality: 100
            blurredOptions: {}
            placeholder: NONE
            formats: [AUTO, WEBP]
          )
        }
      }

      stats {
        title
        subtitle
      }
      statsSubtitle

      highlightsTitle
      highlightsDescription
      highlightsQuote
      highlights {
        description
        image {
          childImageSharp {
            gatsbyImageData(
              width: 200
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }

      projectsTitle
      projectsDescription
      projects {
        title
        subtitle
        tag
        image
        skills
      }

      careerTitle
      careerDescription
      careers {
        description
        image {
          childImageSharp {
            gatsbyImageData(
              width: 200
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }

      curriculumTitle
      curriculumDescription
      curriculumQuote
      curriculum {
        title
        image {
          childImageSharp {
            gatsbyImageData(
              width: 100
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        duration
        tag
        data
        skills
        anchor

        projects {
          title
          description
        }
      }
      advancedCurriculum {
        title
        image {
          childImageSharp {
            gatsbyImageData(
              width: 100
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        duration
        data
        anchor
        skills
      }

      testimonialQuote
      steps

      price
      priceDuration

      faqs {
        question
        answer
      }

      skills {
        image {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        originalImage {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }

      dreamCompanies {
        image {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        originalImage {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      superDreamCompanies {
        image {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        originalImage {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }

      jobGuarantee {
        question
        answer
        image {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }

    allStrapiPrograms {
      ...allStrapiProgramsFragment
    }

    qaTestimonials: allStrapiTestimonialWithQuotes(
      filter: { type: { eq: "QA" } }
    ) {
      nodes {
        Quote
        strapiId
        YoutubeVideoLink
        LinkedInLink
        type
        graduate {
          id
          company
          Name
          From
          To
        }
      }
    }

    ...strapiDataFragment
  }
`;
