import React from 'react';
import { cn } from '@/lib/utils';
import Layout from '@components/v5/layouts/Layout';
import { GatsbyImage } from 'gatsby-plugin-image';
import SectionHeading from '../SectionHeading';

export default function MasterclassSectionLayout({
  heading,
  children,
  sectionImage,
  layoutClassName,
  sectionWrapperClassName,
  headingClassName,
}) {
  return (
    // Layout
    <Layout className={cn(`masterclass`, layoutClassName)}>
      {/* Section wrapper */}
      <div className={cn('flex flex-col', sectionWrapperClassName)}>
        {/* Section Heading */}
        {heading && (
          <SectionHeading
            heading={heading}
            headingClassName={headingClassName}
          />
        )}

        {/* Optional Section Banner */}
        {sectionImage && (
          <GatsbyImage
            image={sectionImage}
            alt={'Masterclass Banner'}
            className="lg:mt-2"
          />
        )}

        {/* Section Content */}
        {children}
      </div>
    </Layout>
  );
}
