import { useCustomToast } from '@components/extra/useCustomToast';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import { LEAD_GEN_BOTTOM_STRIP_CHATBOT_CALLBACK } from '@src/constants/LeadGenerationConstants/index';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '@src/context/GlobalContextProvider';
import React, { useContext } from 'react';
import Cookies from 'js-cookie';
import useSendActivities from '@src/hooks/useSendActivities';

const BottomStrip = () => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const { sendActivityHandler } = useSendActivities();

  const triggerSuccessToast = useCustomToast(
    'success',
    <div className="">
      <h2 className="font-base font-rubik text-lg">Success!</h2>
      <p className="font-rubik text-xs">
        Thanks! We will give you a call or email you between 9 AM to 9 PM
      </p>
    </div>,
  );

  function onSubmit(email, phone) {
    GTM.login({
      userId: email,
      email: email,
      phone: phone,
      whatsappNumber: phone,
    });
    GTM.setEmail(email);

    //GTM Event for Email Capture
    if (!state.isEmailEventSubmitted) {
      GTM.track(gtmEvents.EMAIL_SUBMITTED, {
        type: 'CALL_ME_POPUP',
      });
      dispatch({
        type: 'SET_IS_EMAIL_EVENT_SUBMITTED',
        payload: true,
      });
    }

    sendActivityHandler(ELeadSquaredActivityCode.CALL_ME_BACK_SUBMITTED);

    window.webengage &&
      window.webengage.user.setAttribute('we_whatsapp_opt_in', true);

    if (Cookies.get('isPhoneVerified') === 'true') triggerSuccessToast();
  }

  return (
    <div className="fixed bottom-0 left-0 z-[1000] hidden h-[32px] w-[100dvw] items-center justify-center bg-v5-green-100 font-manrope text-[16px] md:flex">
      <div>
        Feel free to reach out to us at{' '}
        <a href="tel:063665 28148" className="font-bold">
          063665 28148
        </a>{' '}
        or simply{' '}
        <LeadGenerationButton
          wrapperClassName={'inline-block cursor-pointer'}
          buttonComponent={
            <strong className="font-bold underline">Request a Callback</strong>
          }
          isOnePageForm
          formHeading="Request Callback"
          pageOneButtonText="Call me back"
          buttonLocation={LEAD_GEN_BOTTOM_STRIP_CHATBOT_CALLBACK}
          onSubmitPageOne={onSubmit}
          clickEvent={() => {
            GTM.track(gtmEvents.CALLME_OPENED);
            sendActivityHandler(
              ELeadSquaredActivityCode.REQUEST_CALL_BACK_CLICKED,
            );
          }}
        ></LeadGenerationButton>
        , and we’ll get in touch with you shortly.
      </div>
    </div>
  );
};

export default BottomStrip;
