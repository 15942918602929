/**
 * Utilities for handling input validation in the chatbot
 */

// Track validation status for different input types
const validationStatus = new Map();

/**
 * Checks if a specific validation type has been completed
 * @param {string} validationType - Type of validation (e.g., 'phone', 'email')
 * @returns {boolean} Whether validation is complete
 */
export const isValidationComplete = (validationType) => {
  return validationStatus.get(`${validationType}_validated`) || false;
};

/**
 * Marks a specific validation type as complete
 * @param {string} validationType - Type of validation to mark complete
 */
export const setValidationComplete = (validationType) => {
  validationStatus.set(`${validationType}_validated`, true);
};

/**
 * Resets all validation status
 */
export const resetValidationStatus = () => {
  validationStatus.clear();
};
