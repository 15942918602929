export const noCodingMessages = [
  {
    message:
      "Crio's programs are specifically designed to help individuals from non-IT backgrounds—such as mechanical engineering, customer support, sales, and even the medical field—transition into the tech industry. Many of our graduates have successfully secured jobs in tech after completing our structured, hands-on learning programs.",
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'first',
    type: 'text',
  },
  {
    message: 'How We Help Non-Coders',
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'normal',
    type: 'section',
    payload: [
      {
        title: '<strong>Practical, Hands-on Learning</strong>',
        description:
          'The program focuses on experiential learning, where you build real-world projects. This allows you to gain the skills needed for a tech job even without prior coding or software development experience.',
      },
      {
        title: '<strong>Structured Curriculum</strong>',
        description:
          'The courses are structured to gradually introduce you to technical concepts. For example, you will work on full-stack development, backend projects, or automation testing, which are critical for modern tech roles. This step-by-step approach ensures that even someone without a tech background can follow and master the material.',
      },
      {
        title: '<strong>Career Services</strong>',
        description:
          'Crio provides strong career support, including personalized career counseling, mock interviews, and job referrals to help you break into the tech industry.',
      },
    ],
  },
  {
    message: 'Success Stories',
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'normal',
    type: 'carousel',
    payload: [
      {
        imageType: 'KartikBajaj',
        title: 'Kartik Bajaj',
        description: 'Consulting to Developer at Adobe',
      },
      {
        imageType: 'RishavRaj',
        title: 'Rishav Raj',
        description: 'Customer Service to Developer at Walmart',
      },
      {
        imageType: 'ShubhamSharma',
        title: 'Shubham Sharma',
        description: 'Service to Product at Cisco',
      },
      {
        imageType: 'VimalS',
        title: 'Vimal S',
        description: 'Transportation Analyst to Developer at Innbuilt',
      },
    ],
  },
  {
    message:
      "By joining Crio, you'll build a strong portfolio of projects and gain in-demand tech skills, making you job-ready for the IT field. You can experience this firsthand by booking a free trial.",
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'normal',
    type: 'text',
  },
  {
    message: 'Book a free trial',
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'last',
    type: 'callToAction',
    payload: {
      text: 'Book a free trial',
      link: 'https://crio.do/free-trial',
    },
  },
];
