import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export const AdrizaMishraImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Profiles/Adriza Mishra.webp"
    alt="Adriza Mishra"
    placeholder="blurred"
    className="h-full w-full object-cover object-center"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'cover',
    }}
  />
);

export const SuryaSainiImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Profiles/Surya Saini.webp"
    alt="Surya Saini"
    placeholder="blurred"
    className="h-full w-full object-cover object-center"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'cover',
    }}
  />
);

export const LopamudraRathImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Profiles/Lopamudra Rath.webp"
    alt="Lopamudra Rath"
    placeholder="blurred"
    className="h-full w-full object-cover object-center"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'cover',
    }}
  />
);

export const SubitDuttaImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Profiles/Subir Dutta.webp"
    alt="Subit Dutta"
    placeholder="blurred"
    className="h-full w-full object-cover object-center"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'cover',
    }}
  />
);

export const AyushSinhaImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Profiles/Ayush Sinha.webp"
    alt="Ayush Sinha"
    placeholder="blurred"
    className="h-full w-full object-cover object-center"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'cover',
    }}
  />
);

export const RitikBhattImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Profiles/Ritik Bhatt.webp"
    alt="Ritik Bhatt"
    placeholder="blurred"
    className="h-full w-full object-cover object-center"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'cover',
    }}
  />
);

export const KanchanRathoreImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Profiles/Kanchan Rathore.webp"
    alt="Kanchan Rathore"
    placeholder="blurred"
    className="h-full w-full object-cover object-center"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'cover',
    }}
  />
);

export const PijushGhoshImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Profiles/Pijush Ghosh.webp"
    alt="Pijush Ghosh"
    placeholder="blurred"
    className="h-full w-full object-cover object-center"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'cover',
    }}
  />
);

export const AbhishekSinghImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Profiles/Abhishek Kumar Singh.webp"
    alt="Abhishek Kumar Singh"
    placeholder="blurred"
    className="h-full w-full object-cover object-center"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'cover',
    }}
  />
);

export const KartikBajajImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Profiles/Kartik-Bajaj.webp"
    alt="Kartik Bajaj"
    placeholder="blurred"
    className="h-full w-full object-cover object-center"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'cover',
    }}
  />
);

export const RishavRajImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Profiles/Rishav Raj.webp"
    alt="Rishav Raj"
    placeholder="blurred"
    className="h-full w-full object-cover object-center"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'cover',
    }}
  />
);

export const ShubhamSharmaImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Profiles/Shubham Sharma.png"
    alt="Shubham Sharma"
    placeholder="blurred"
    className="h-full w-full object-cover object-center"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'cover',
    }}
  />
);

export const VimalSImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Profiles/Vimal S.webp"
    alt="Vimal S"
    placeholder="blurred"
    className="h-full w-full object-cover object-center"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'cover',
    }}
  />
);
