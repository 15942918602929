import Layout from '@components/v5/layouts/Layout';
import VideoTestimonialSection from '@components/v5/VideoTestimonialSection';
import React from 'react';

export default function CrioFeedback() {
  return (
    <Layout className="container pt-20">
      <div className="flex flex-col gap-0">
        <div className="leading-1 text-center font-manrope text-[18px] font-bold text-v5-neutral-600 md:text-left md:text-[40px]">
          Meet People who transformed their careers with Crio.Do
        </div>
        <VideoTestimonialSection
          disableGreenBanner
          disableCareerHandbook
          removeDescription
          disableSectionHeading
          className="relative top-0"
        />
      </div>
    </Layout>
  );
}
