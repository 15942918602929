import useRegisterMetadata from '@src/hooks/Masterclass/useRegisterMetadata';
import { BeaverLogo } from '@src/images/events/icons/BeaverLogo';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

export default function SessionInfo() {
  const { getEventDetails, getSessionHostImage } = useRegisterMetadata();
  const {
    isPastEvent,
    sessionDate,
    sessionTime,
    sessionName,
    sessionHostName,
    sessionHostDesignation,
  } = getEventDetails();

  const sessionHostImg = getSessionHostImage();

  return (
    <>
      {/* SessionHostImage */}
      <div className="self-start rounded-full border border-v5-neutral-300">
        <div className="m-2 h-[80px] w-[80px] rounded-full md:h-[100px] md:w-[100px]">
          {sessionHostImg ? (
            <GatsbyImage image={sessionHostImg} className="m-0 rounded-full" />
          ) : (
            <BeaverLogo className="h-[100px] w-[100px]" />
          )}
        </div>
      </div>

      {/* Event Details */}
      <div className="break-keep max-w-[400px] self-center break-words">
        <div className=" font-manrope text-lg font-bold leading-none md:text-[30px] lg:text-[40px]">
          {sessionName}
        </div>
        <div className="mt-2 text-xs leading-none text-v5-neutral-500 md:mt-3 md:text-xl lg:text-2xl">
          By {sessionHostName}, {sessionHostDesignation}
        </div>

        <div className="mt-2 whitespace-nowrap font-rubik text-[10px] leading-[14px] text-v5-neutral-500 md:mt-3 md:text-base lg:text-lg">
          <span className="font-medium">
            {isPastEvent ? 'Held On: ' : 'Date: '}
          </span>
          <span className="font-normal">{sessionDate}</span>
          &nbsp;
          <span className="font-medium">Time: </span>
          <span className="font-normal">{sessionTime}</span>
        </div>
      </div>
    </>
  );
}
