//FSD Pages
export const FSD_PAGES = [
  '/software-development-fellowship-program/',
  '/programs/software-development-fellowship-program/',
  '/backend-developer-track/',
  '/programs/software-development-fellowship-program/backend-track/',
  '/programs/software-development-fellowship-program/full-stack-track/',
  '/programs/software-development-fellowship-program/mern-stack/',
  '/programs/software-development-fellowship-program/software-development-fellowship/',
  '/programs/software-development-fellowship-program/web-development/',
];

//QA Pages
export const QA_PAGES = [
  '/qa-automation-testing/',
  '/programs/qa-automation-testing/',
  '/programs/qa-automation-testing/manual-to-automation/',
  '/programs/qa-automation-testing/selenium/',
  '/programs/qa-automation-testing/software-testing/',
];

//Data Analytics Pages
export const DATA_ANALYTICS_PAGES = [
  '/ai-data-analytics/',
  '/programs/ai-data-analytics/',
];

//System Design Pages
export const SYSTEM_DESIGN_PAGES = [
  '/fellowship-program-system-design/',
  '/software-development-fellowship-program-plus/',
];

//Accelerate Pages
export const ACCELERATE_PAGES = ['/accelerate/'];

//On Demand Pages
export const ON_DEMAND_PAGES = [
  '/on-demand/ai-data-analytics/',
  '/on-demand/software-development-fellowship-program/',
  '/on-demand/qa-automation-testing/',
];

export const getProgramTypeFromPath = (path) => {
  if (FSD_PAGES.includes(path)) return 'FSD';
  if (QA_PAGES.includes(path)) return 'QA';
  if (DATA_ANALYTICS_PAGES.includes(path)) return 'DATA_ANALYTICS';
  if (SYSTEM_DESIGN_PAGES.includes(path)) return 'SYSTEM_DESIGN';
};

export const getEMIPrice = (programType) => {
  if (programType === 'FSD') return '9,928';
  if (programType === 'QA') return '8,901';
  if (programType === 'DATA_ANALYTICS') return '7,874';
  if (programType === 'SYSTEM_DESIGN') return '6,163';
};

export const checkIsOnDemandPage = (path) => {
  return ON_DEMAND_PAGES.includes(path);
};
