import { cn } from '@/lib/utils';
import {
  PAST_EVENT_SECTION_ID,
  programTabOptions,
} from '@src/constants/masterclass';
import React, { useEffect } from 'react';

/**
 * ProgramSelectionTab Component
 *
 * Renders a sticky tab selection interface with smooth scrolling to past events
 *
 * @param {Object} props - Component properties
 * @param {string} props.selectedTab - The currently selected tab ID
 * @param {Function} props.handleTabClick - Callback for tab selection
 * @param {boolean} props.isPastEventsAvailable - Indicates if past events are available
 * @param {string} [props.className] - Additional CSS classes for the component
 *
 * @returns {React.ReactElement} Rendered ProgramSelectionTab component
 */

export default function ProgramSelectionTab({
  selectedTab,
  handleTabClick,
  isPastEventsAvailable,
}) {
  // Function to scroll to the past events section
  const scrollToPastEvents = () => {
    const pastEventsElement = document.getElementById(PAST_EVENT_SECTION_ID);
    if (pastEventsElement) {
      pastEventsElement.scrollIntoView({ behavior: 'smooth' });
      window.location.hash = `#${PAST_EVENT_SECTION_ID}`;
    }
  };

  useEffect(() => {
    if (window.location.hash === `#${PAST_EVENT_SECTION_ID}`) {
      scrollToPastEvents();
    }
  }, []);

  return (
    <div className="container sticky hidden h-full -translate-y-1/2 px-5 md:block">
      <div className="flex h-[44px] items-center justify-between  gap-x-3 rounded-[4px] bg-v5-green-200 px-3 md:h-[100px] md:gap-x-7 md:rounded-[14px] md:px-14">
        {/* Program Selection Tab Options */}
        <div className="flex h-full gap-x-3 overflow-auto md:gap-x-5">
          {programTabOptions.map((option) => {
            const isSelected = selectedTab === option.id;
            const borderClass = isSelected
              ? 'border-[#004246]'
              : 'border-transparent';

            return (
              <div
                key={option.id}
                className={`flex cursor-pointer items-center whitespace-nowrap border-b-2 ${borderClass}`}
                onClick={() => handleTabClick(option.id)}
              >
                <div className="text-[7px] text-v5-neutral-100 md:text-lg">
                  {option.name}
                </div>
              </div>
            );
          })}
        </div>

        {/* Previous Webinars */}
        <div
          className={cn(
            'flex h-5 w-[80px] cursor-pointer items-center justify-center rounded-[3px] border border-v5-neutral-100 text-[7px] text-v5-neutral-100 md:h-16 md:w-[220px] md:rounded-lg md:text-lg',
            !isPastEventsAvailable && 'cursor-not-allowed',
          )}
          onClick={scrollToPastEvents}
        >
          Previous Webinars
        </div>
      </div>
    </div>
  );
}
