import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export const accelerateCurriculumData = {
  curriculum: [
    {
      title: 'Intermediate DSA',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/One.png"
          placeholder="blurred"
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '',
      tag: null,
      data: [
        'For learners familiar with DSA and Javascript basics, aspiring to be Full stack MERN developers. Build 4 Work-like Projects and 15+ Micro skilling projects in real work-like environment.',
      ],
      skills: null,
      anchor: 'programming-foundations',
      cardTitle: null,
      cards: [
        {
          image: null,
          data: [],
          skills: [
            'Array and String-related Patterns',
            'Two Pointers',
            'Sliding Window',
            'Sorting',
            'Searching',
          ],
          title: 'DSA-JS-102',
        },
        {
          image: null,
          data: [],
          skills: [
            'Stack',
            'Queue',
            'Hash',
            'Linked List',
            'Binary Tree',
            'Binary Search Tree',
          ],
          title: 'DSA-201',
        },
      ],
    },
    {
      title: 'Frontend - HTML/CSS/JS',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Two.png"
          placeholder="blurred"
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '',
      tag: null,
      data: [
        'For learners familiar with DSA and Javascript basics, aspiring to be Full stack MERN developers. Build 4 Work-like Projects and 15+ Micro skilling projects in real work-like environment.',
      ],
      skills: [],
      anchor: 'dsa',
      cardTitle: null,
      cards: [
        {
          data: [],
          skills: [
            'HTML',
            'CSS',
            'Flexbox',
            'CSS Grid',
            'Bootstrap',
            'Responsive Design',
            'Git',
            'Linux Basics',
          ],
          title: 'FE-101',
          subCards: null,
          tag: 'dsa-basics',
        },
        {
          data: [],
          title: 'FE-102',
          projects: ['QTrip'],
          skills: [
            'Bootstrap',
            'CSS Positioning',
            'CSS Inheritance',
            'DOM Manipulation',
            'Promises',
            'Async-await',
            'Deployment',
          ],
          tag: 'dsa-intermediate',
        },
        {
          data: [],
          title: 'FE-103',
          projects: ['QTrip', 'XBoard'],
          skills: [
            'REST APIs',
            'Forms',
            'Fetch API',
            'Local Storage',
            'Debugging',
          ],
          tag: 'dsa-intermediate',
        },
      ],
    },
    {
      title: 'Frontend (React)',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Three.png"
          placeholder="blurred"
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '',
      tag: null,
      data: [
        'For learners familiar with DSA and Javascript basics, aspiring to be Full stack MERN developers. Build 4 Work-like Projects and 15+ Micro skilling projects in real work-like environment.',
      ],
      skills: [],
      anchor: 'tech-stack',
      cardTitle: null,
      showOptionsAboveTitle: true,
      cards: [
        {
          data: [],
          title: 'FE-201',
          projects: ['QKart Frontend'],
          skills: [
            'Functional Programming',
            'Class-based Components',
            'Lifecycle methods',
            'Functional Components',
            'React Hooks',
            'Controlled Components',
            'React Router',
            'Material UI',
          ],
          tag: 'fullstack-specialization',
        },
        {
          data: [],
          skills: [
            'Debouncing',
            'Mocking API calls',
            'Promise Combinators',
            'Advanced Hooks',
            'Custom Hooks',
          ],
          title: 'FE-202',
          projects: ['QKart Frontend', 'QTify'],
          tag: 'fullstack-specialization',
        },
      ],
    },
    {
      title: 'Backend Node',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Four.png"
          placeholder="blurred"
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '',
      tag: null,
      data: [
        'For learners familiar with DSA and Javascript basics, aspiring to be Full stack MERN developers. Build 4 Work-like Projects and 15+ Micro skilling projects in real work-like environment.',
      ],
      skills: [],
      anchor: 'tech-stack-advanced',
      cardTitle: null,
      showOptionsAboveTitle: true,
      cards: [
        {
          data: [],
          title: 'NODE-201',
          projects: ['QKart Backend'],
          skills: [
            'Node.js',
            'Express.js',
            'MongoDB',
            'JOI Data Validation',
            'Authentication',
            'JWT Token',
            'Passport',
          ],
          tag: 'frontend-advanced',
        },
        {
          data: [],
          skills: ['Testing', 'Jest', 'TDD', 'MongoDB Atlas'],
          spanToWidth: true,
          title: 'NODE-202',
          projects: ['QKart Backend', 'XFlix'],
          tag: 'frontend-advanced',
        },
      ],
    },
    {
      title: 'Frontend Design',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Five.png"
          placeholder="blurred"
          width={300}
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '',
      tag: null,
      data: [
        'For learners familiar with DSA and Javascript basics, aspiring to be Full stack MERN developers. Build 4 Work-like Projects and 15+ Micro skilling projects in real work-like environment.',
      ],
      skills: [],
      anchor: 'system-design',
      cardTitle: null,
      cards: [
        {
          image: null,
          data: [],
          title: 'FE-301',
          skills: ['Global State Management', 'Redux'],
          tag: 'lld-foundation',
        },
        {
          image: null,
          data: [],
          title: 'FE-302',
          skills: [
            'CDN',
            'Performance',
            'Optimization',
            'Server Side Rendering',
          ],
          tag: 'lld-foundation',
        },
      ],
    },
    {
      title: 'Advanced DSA',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Six.png"
          placeholder="blurred"
          width={300}
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '',
      tag: null,
      data: [
        'For learners familiar with DSA and Javascript basics, aspiring to be Full stack MERN developers. Build 4 Work-like Projects and 15+ Micro skilling projects in real work-like environment.',
      ],
      skills: [],
      anchor: 'interview',
      cardTitle: null,
      cards: [
        {
          image: null,
          data: [],
          title: 'DSA-202',
          skills: [
            'Heap',
            'Backtracking',
            'Greedy',
            'Graph',
            'BFS',
            'DFS',
            'Bipartite',
            'Connected Components',
            'Shortest Path',
            'Spanning Tree',
          ],
          tag: 'lld-foundation',
        },
      ],
    },
  ],
  advancedCurriculum: [
    {
      title: 'Intermediate DSA',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Six.png"
          placeholder="blurred"
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '',
      tag: null,
      data: [
        'For learners familiar with DSA basics, aspiring to become Java Developers. Build 4 Work-like Projects and 15+ Micro skilling projects in real work-like environment.',
      ],
      skills: [],
      anchor: 'inter-dsa',
      cardTitle: null,
      cards: [
        {
          data: [],
          title: 'DSA-102',
          skills: [
            'Array and String-related Patterns',
            'Two Pointers',
            'Sliding Window',
            'Sorting',
            'Searching',
          ],
          tag: 'lld-intermediate',
        },
        {
          data: [],
          skills: [
            'Stack',
            'Queue',
            'Hash',
            'Linked List',
            'Binary Tree',
            'Binary Search Tree',
          ],
          title: 'DSA-201',
          subCards: null,
          tag: 'lld-intermediate',
        },
      ],
    },
    {
      title: 'Core Java',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Six.png"
          placeholder="blurred"
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '',
      tag: null,
      data: [
        'For learners familiar with DSA basics, aspiring to become Java Developers. Build 4 Work-like Projects and 15+ Micro skilling projects in real work-like environment.',
      ],
      skills: [],
      anchor: 'core-java',
      cardTitle: null,
      cards: [
        {
          data: [],
          skills: [
            'Java Syntax Basics',
            'Loops',
            'Conditionals',
            'Strings',
            'Arrays',
            'Collections',
            'Debugging 101',
            'Classes and Objects',
          ],
          title: 'JAVA-111',
          subCards: null,
          tag: 'hld-choice',
        },
        {
          data: [],
          title: 'JAVA-112',
          skills: [
            'Constructors',
            'Access Modifiers',
            'Static',
            'Final',
            'OOP Principles',
            'Exception Handling',
            'Problem-solving',
            'GIT',
            'Linux Basics',
          ],
          tag: 'lld-intermediate',
          projects: ['QCalc', 'XQuiz'],
        },
        {
          data: [],
          title: 'JAVA-201',
          skills: [
            'Advanced OOP',
            'Gradle',
            'Streams and Lambda',
            'JSON',
            'Jackson',
          ],
          projects: ['QMoney'],
          tag: 'lld-intermediate',
        },
        {
          data: [],
          title: 'JAVA-202',
          skills: [
            'HTTP',
            'REST API',
            'Unit Testing (JUnit, Mockito)',
            'Debugging',
            'Multithreading',
          ],
          projects: ['QMoney'],
          tag: 'lld-intermediate',
        },
        {
          data: [],
          title: 'JAVA-203',
          projects: ['QContest'],
          skills: [
            'Object Modeling',
            'SOLID Principles',
            'Design Patterns',
            'SQL',
            'Machine Coding Basics',
          ],
          tag: 'lld-intermediate',
        },
      ],
    },
    {
      title: 'SpringBoot',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Two.png"
          placeholder="blurred"
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '',
      tag: null,
      data: [],
      skills: [],
      anchor: 'spring-boot',
      cardTitle: null,
      cards: [
        {
          data: [],
          skills: [
            'MVC',
            'MongoDB',
            'Spring Basics',
            'Lombok',
            'SpringBoot',
            'Spring Data',
            'Redis Cache',
          ],
          projects: ['QEats'],
          title: 'JAVA-301',
          subCards: null,
          tag: 'low-level-design',
        },
        {
          data: [],
          title: 'JAVA-302',
          skills: [
            'The Architecture of Web Applications',
            'Deployment',
            'Hibernate',
            'Schema Design',
            'Logging',
            'AWS Basics',
          ],
          projects: ['QEats', 'XMeme'],
          tag: 'hld-challenges',
        },
      ],
    },
    {
      title: 'High Level Design Basics',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Two.png"
          placeholder="blurred"
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '',
      tag: null,
      data: [
        'For learners familiar with DSA basics, aspiring to become Java Developers. Build 4 Work-like Projects and 15+ Micro skilling projects in real work-like environment.',
      ],
      skills: [],
      anchor: 'hld-basics',
      cardTitle: null,
      cards: [
        {
          data: [],
          skills: [
            'Micro-Services',
            'Docker',
            'Kafka',
            'CDN',
            'Load Balancer',
            'Deployment',
            'CI/CD',
            'Cloud',
          ],
          title: 'HLD-101',
          subCards: null,
          tag: 'low-level-design',
        },
      ],
    },
    {
      title: 'Advanced DSA',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Two.png"
          placeholder="blurred"
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '',
      tag: null,
      data: [
        'For learners familiar with DSA basics, aspiring to become Java Developers. Build 4 Work-like Projects and 15+ Micro skilling projects in real work-like environment.',
      ],
      skills: [],
      anchor: 'advanced-dsa',
      cardTitle: null,
      cards: [
        {
          data: [],
          skills: [
            'Heap',
            'Backtracking',
            'Greedy',
            'Graph',
            'BFS',
            'DFS',
            'Bipartite',
            'Connected Components',
            'Shortest Path',
            'Spanning Tree',
          ],
          title: 'DSA-202',
          subCards: null,
          tag: 'low-level-design',
        },
      ],
    },
  ],
};
