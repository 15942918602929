import RegistrationSuccessMessage from '@components/Masterclass/RegistrationSuccessMessage';
import React from 'react';
import SessionInfo from '../SessionInfo';
import EventStartTimer from '@components/Masterclass/EventStartTimer';
import JoinSessionButton from '@components/Masterclass/JoinSessionButton';
import AddToCalendar from '@components/Masterclass/AddToCalendar';

export default function MobileHero({ className }) {
  return (
    <div className={className}>
      <div className="flex flex-col items-center justify-center gap-4 rounded-[10px] border border-v5-neutral-300 p-7 md:gap-[24px]">
        <RegistrationSuccessMessage className={'text-center'} />
        <div className="flex items-start gap-4 md:gap-5">
          <SessionInfo />
        </div>
        <EventStartTimer />
        <JoinSessionButton />
      </div>
      <AddToCalendar
        wrapperClassName={'pt-8'}
        ctaClassName={'max-w-[200px] mx-auto'}
      />
    </div>
  );
}
