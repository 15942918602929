import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export const XCruiseImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Projects/XCruise.png"
    alt="XCruise"
    placeholder="blurred"
    className="!h-auto max-h-full w-auto max-w-full object-contain"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'contain',
    }}
  />
);

export const QTripImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Projects/QTrip.png"
    alt="QTrip"
    placeholder="blurred"
    className="!h-auto max-h-full w-auto max-w-full object-contain"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'contain',
    }}
  />
);

export const XBoardImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Projects/XBoard.png"
    alt="XBoard"
    placeholder="blurred"
    className="!h-auto max-h-full w-auto max-w-full object-contain"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'contain',
    }}
  />
);

export const QKartImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Projects/QKart.png"
    alt="QKart"
    placeholder="blurred"
    className="!h-auto max-h-full w-auto max-w-full object-contain"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'contain',
    }}
  />
);

export const QScoreImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Projects/QScore.png"
    alt="QScore"
    placeholder="blurred"
    className="!h-auto max-h-full w-auto max-w-full object-contain"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'contain',
    }}
  />
);

export const QTifyImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Projects/QTify.png"
    alt="QTify"
    placeholder="blurred"
    className="!h-auto max-h-full w-auto max-w-full object-contain"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'contain',
    }}
  />
);

export const ExitEaseImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Projects/Exit Ease.png"
    alt="Exit Ease"
    placeholder="blurred"
    className="!h-auto max-h-full w-auto max-w-full object-contain"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'contain',
    }}
  />
);

export const QMoneyImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Projects/QMoney.png"
    alt="QMoney"
    placeholder="blurred"
    className="!h-auto max-h-full w-auto max-w-full object-contain"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'contain',
    }}
  />
);

export const XMemeImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Projects/XMeme.png"
    alt="XMeme"
    placeholder="blurred"
    className="!h-auto max-h-full w-auto max-w-full object-contain"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'contain',
    }}
  />
);

export const XNewsImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Projects/XNews.png"
    alt="XNews"
    placeholder="blurred"
    className="!h-auto max-h-full w-auto max-w-full object-contain"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'contain',
    }}
  />
);

export const XLidoImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Projects/XLido.png"
    alt="XLido"
    placeholder="blurred"
    className="!h-auto max-h-full w-auto max-w-full object-contain"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'contain',
    }}
  />
);

export const XPollImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Projects/XPoll.png"
    alt="XPoll"
    placeholder="blurred"
    className="!h-auto max-h-full w-auto max-w-full object-contain"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'contain',
    }}
  />
);

export const QEatsImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Projects/QEats.png"
    alt="QEats"
    placeholder="blurred"
    className="!h-auto max-h-full w-auto max-w-full object-contain"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'contain',
    }}
  />
);

export const ZCommerceImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Projects/ZCommerce.png"
    alt="ZCommerce"
    placeholder="blurred"
    className="!h-auto max-h-full w-auto max-w-full object-contain"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'contain',
    }}
  />
);

export const QContestImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Projects/QContest.png"
    alt="QContest"
    placeholder="blurred"
    className="!h-auto max-h-full w-auto max-w-full object-contain"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'contain',
    }}
  />
);

export const QCalcImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Projects/QCalc.png"
    alt="QCalc"
    placeholder="blurred"
    className="!h-auto max-h-full w-auto max-w-full object-contain"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'contain',
    }}
  />
);

export const ZerodhaImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Projects/Zerodha.png"
    alt="Zerodha"
    placeholder="blurred"
    className="!h-auto max-h-full w-auto max-w-full object-contain"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'contain',
    }}
  />
);

export const FlipkartImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Projects/Flipkart.png"
    alt="Flipkart"
    placeholder="blurred"
    className="!h-auto max-h-full w-auto max-w-full object-contain"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'contain',
    }}
  />
);

export const UberImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Projects/Uber.png"
    alt="Uber"
    placeholder="blurred"
    className="!h-auto max-h-full w-auto max-w-full object-contain"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'contain',
    }}
  />
);

export const ZomatoImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Projects/Zomato.png"
    alt="Zomato"
    placeholder="blurred"
    className="!h-auto max-h-full w-auto max-w-full object-contain"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'contain',
    }}
  />
);

export const MMTImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Projects/MMT.png"
    alt="MakeMyTrip"
    placeholder="blurred"
    className="!h-auto max-h-full w-auto max-w-full object-contain"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'contain',
    }}
  />
);

export const SpinnyImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Projects/Spinny.png"
    alt="Spinny"
    placeholder="blurred"
    className="!h-auto max-h-full w-auto max-w-full object-contain"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'contain',
    }}
  />
);
