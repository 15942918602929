export const chatResponseMap = new Map([
  [
    'r1',
    {
      responseId: 'r1',
      text: 'I am planning to switch my job',
      selectionResponseId: 'r8',
      followupQuestionId: 'q2',
    },
  ],
  [
    'r2',
    {
      responseId: 'r2',
      text: 'I am a fresher & looking for my first job',
      selectionResponseId: 'r9',
      followupQuestionId: 'q2',
    },
  ],
  [
    'r3',
    {
      responseId: 'r3',
      text: 'I am looking to up-skill',
      selectionResponseId: 'r10',
      followupQuestionId: 'q2',
    },
  ],
  [
    'r4',
    {
      responseId: 'r4',
      text: 'I am only exploring',
      selectionResponseId: 'r11',
      followupQuestionId: 'q2',
    },
  ],
  [
    'r5',
    {
      responseId: 'r5',
      text: 'Tell me about the program offerings.',
      selectionResponseId: 'r17',
      followupQuestionId: 'q3',
    },
  ],
  [
    'r6',
    {
      responseId: 'r6',
      text: 'How do you provide job assistance?',
      selectionResponseId: 'r18',
      followupQuestionId: 'q4',
    },
  ],
  [
    'r7',
    {
      responseId: 'r7',
      text: 'I do not know coding. How can I benefit from you?',
      selectionResponseId: 'r20',
      followupQuestionId: 'q5',
    },
  ],
  [
    'r12',
    {
      responseId: 'r12',
      text: 'Full Stack Development',
      selectionResponseId: 'r21',
      followupQuestionId: 'q6',
    },
  ],
  [
    'r13',
    {
      responseId: 'r13',
      text: 'Backend Development',
      selectionResponseId: 'r22',
      followupQuestionId: 'q6',
    },
  ],
  [
    'r14',
    {
      responseId: 'r14',
      text: 'System Design',
      selectionResponseId: 'r23',
      followupQuestionId: 'q6',
    },
  ],
  [
    'r15',
    {
      responseId: 'r15',
      text: 'QA Automation (SDET)',
      selectionResponseId: 'r24',
      followupQuestionId: 'q6',
    },
  ],
  [
    'r16',
    {
      responseId: 'r16',
      text: 'Data Analytics with AI',
      selectionResponseId: 'r25',
      followupQuestionId: 'q6',
    },
  ],
  [
    'r19',
    {
      responseId: 'r19',
      text: 'View curriculum of your programs',
      selectionResponseId: 'r26',
      followupQuestionId: 'q3',
    },
  ],
  [
    'r26',
    {
      responseId: 'r26',
      text: 'There are five programs for you to view the curriculum for.',
      selectionResponseId: 'r26',
      followupQuestionId: 'q3',
    },
  ],
]);
