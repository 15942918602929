import SEO from '@components/Common/SEO';
import FooterV2 from '@components/FooterV2';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import AboutCrioDo from '@components/Masterclass/AboutCrioDo';
import AboutMasterclass from '@components/Masterclass/AboutMasterclass';
import CrioFeedback from '@components/Masterclass/CrioFeedback';
import EventDetails from '@components/Masterclass/EventDetails';
import EventRegistrationHero from '@components/Masterclass/EventRegistrationHero';
import HostInfo from '@components/Masterclass/HostInfo';
import MasterclassOutcomes from '@components/Masterclass/MasterclassOutcomes';
import UpcomingEvents from '@components/Masterclass/UpcomingEvents';
import NavigationV2 from '@components/NavigationV2';
import BottomStrip from '@components/PPCNavbar/BottomStrip';
import ScrollButtonComponent from '@components/ScrollButtonComponent';
import { LEAD_GEN_FLOATING_CTA } from '@src/constants/LeadGenerationConstants';
import { ERoutes } from '@src/constants/routes';
import useMasterclassRegisterSeo from '@src/hooks/Masterclass/useMasterclassRegisterSeo';
import useRegisterMetadata from '@src/hooks/Masterclass/useRegisterMetadata';
import { checkIfMasterclassEndedAndNotMarkedPastOnStrapi } from '@src/utils/Masterclass';
import { navigate } from 'gatsby';
import React from 'react';

export default function CrioMasterclassRegisterPage(props) {
  const pageData = props.pageContext?.pageData;
  const { getRegisterationPageSeo } = useMasterclassRegisterSeo();
  const { getEventDetails } = useRegisterMetadata();
  const eventDetails = getEventDetails();
  const needsMasterclassHomeRedirect =
    checkIfMasterclassEndedAndNotMarkedPastOnStrapi(eventDetails);

  const seoData = getRegisterationPageSeo(pageData);

  // Redirect to masterclass home if the event has ended and the event has not been marked as past event on strapi:
  if (needsMasterclassHomeRedirect) {
    navigate(ERoutes.MASTERCLASS);
    return;
  }

  return (
    <>
      <SEO
        title={seoData?.title}
        description={seoData.metaDescription}
        meta={seoData.meta}
        canonicalUrl={seoData.canonicalUrl}
      >
        {seoData.script}
      </SEO>
      <div className="relative">
        <ScrollButtonComponent
          component={
            <LeadGenerationButton
              type="Register"
              text="Register Now"
              onClick={() => {
                document.body.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                });
              }}
              buttonLocation={LEAD_GEN_FLOATING_CTA}
            />
          }
          startAt={600}
        />
        <NavigationV2 hideCTA hideTopStrip />
        <EventRegistrationHero />
        <EventDetails />
        <AboutMasterclass />
        <MasterclassOutcomes />
        <HostInfo />
        <CrioFeedback />
        <AboutCrioDo />
        <UpcomingEvents />
      </div>
      <FooterV2 />
      <BottomStrip />
    </>
  );
}
