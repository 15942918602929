import React from 'react';
import MasterclassSectionLayout from '../Layouts/MasterclassSectionLayout';
import RawHTML from '@components/Common/RawHTML';
import useRegisterMetadata from '@src/hooks/Masterclass/useRegisterMetadata';

export default function HostInfo() {
  const { getSessionHostInfo } = useRegisterMetadata();
  const sessionHostInfo = getSessionHostInfo();

  if (!sessionHostInfo) return null;
  return (
    <MasterclassSectionLayout
      layoutClassName="container grid grid-cols-1 pt-[26px] md:pt-12 lg:grid-cols-[1.4fr_1fr]"
      sectionWrapperClassName={
        'md:gap-[30px] gap-[18px] md:bg-v5-neutral-200 md:rounded-[10px] rounded-[4px] md:shadow-[0px_4px_9.4px_0px_#00000014]'
      }
      heading={'Meet Our Host'}
      headingClassName={'md:pt-8 md:px-7'}
    >
      <RawHTML className="px-2 font-rubik text-[12px] leading-normal text-v5-neutral-600 md:px-7 md:pb-8 md:text-[18px] md:leading-[28px]">
        {sessionHostInfo}
      </RawHTML>
    </MasterclassSectionLayout>
  );
}
