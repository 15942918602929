import React from 'react';
import CertificateIcon from '@src/images/masterclassIcons/certificate-icon.svg';
import PrepIcon from '@src/images/masterclassIcons/prep-material.svg';
import LinkIcon from '@src/images/masterclassIcons/link-icon.svg';
import NoSpamIcon from '@src/images/masterclassIcons/no-spam-icon.svg';
import IconRole from '@src/images/masterclassIcons/role-card-icon.svg';

export const programTabOptions = [
  { id: 'All', name: 'All' },
  { id: 'Software Development', name: 'Software Development' },
  { id: 'QA Automation', name: 'QA Automation' },
  { id: 'Data Analytics', name: 'Data Analytics' },
];

export const EEventType = {
  UPCOMING_EVENTS: 'Upcoming Events',
  PAST_EVENTS: 'Past Events',
};

export const UPCOMING_EVENTS_SECTION_ID = 'upcoming-events';
export const PAST_EVENT_SECTION_ID = 'past-events';

export const EEventDisplayType = {
  GRID: 'grid',
  SWIPER: 'swiper',
};

export const landingPageDefaultTitle =
  '<div class="text-[#ffffff]">Crio Masterclass</div><div class="text-[#2DF8C5]">Learn, Build and Master</div>';

export const landingPageDefaultSubtitle =
  '<div class="text-[#ffffff]">Master the fundamentals with our interactive session and build real-life projects guided by our expert mentors.&nbsp;Also, receive a certificate to highlight in your resume post-project completion.</div>';

export const WHATSAPP_GROUP_METADATA = [
  {
    id: '1',
    title: 'Free Prep Material',
    subTitle: (
      <>
        Get Important notes and <br />
        materials
      </>
    ),
    icon: <PrepIcon />,
  },
  {
    id: '2',
    title: 'Get Event Joining Link',
    subTitle: (
      <>
        Get a joining link before the <br />
        session
      </>
    ),
    icon: <LinkIcon />,
  },
  {
    id: '3',
    title: 'Get Certificate Link',
    subTitle: (
      <>
        Share and show off of new <br />
        skill
      </>
    ),
    icon: <CertificateIcon />,
  },
  {
    id: '4',
    title: 'No Spam',
    subTitle: (
      <>
        Exclusive group for bonus <br />
        material and reminders
      </>
    ),
    icon: <NoSpamIcon />,
  },
];

export const CRIO_ROLE = [
  {
    id: '1',
    title: 'Lorem Ipsum',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod sit amet consectetur.',
    icon: <IconRole />,
  },
  {
    id: '2',
    title: 'Lorem Ipsum',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod sit amet consectetur.',
    icon: <IconRole />,
  },
  {
    id: '3',
    title: 'Lorem Ipsum',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod sit amet consectetur.',
    icon: <IconRole />,
  },
];

export const TIME_ZONE = 'Asia/Calcutta';
export const DEFAULT_CALENDAR_LOCATION = 'https://crio.do/masterclass';
export const GOOGLE_CALENDAR_URL =
  'https://calendar.google.com/calendar/u/0/r/eventedit?';

export const SESSION_END_OFFSET = 2;
