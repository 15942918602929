import QRCode from 'react-qr-code';
import JoinViaPc from '../JoinViaPc';
import React from 'react';

const QRCodeSection = ({ whatsappGroupLink }) => (
  <div className="flex flex-col items-center gap-3">
    <div className="max-w-[180px] bg-[#F2FBF7] p-2">
      <QRCode value={whatsappGroupLink} className="h-full w-full" />
    </div>
    <JoinViaPc />
  </div>
);

export default QRCodeSection;
