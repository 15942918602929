export const exploreMessages = [
  {
    message:
      "That's great! Exploring Crio.Do gives you insights into the unique approach the platform uses to help learners upskill. Crio.Do focuses on experiential learning, enabling learners to build hands-on, real-world projects that enhance their portfolios.",
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'first',
    type: 'text',
  },
  {
    message: 'Key Features of Crio.Do',
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'normal',
    type: 'section_with_sub_section',
    payload: [
      {
        title: '<strong>Programs and Learning Approach</strong>',
        description:
          'Crio.Do offers comprehensive programs designed for practical skill development.',
        items: [
          {
            title:
              '<span style="text-decoration: underline;">Programs Offered</span>',
            description:
              'Fellowship Programs in Full Stack Development, Backend Development, System Design, QA Automation (SDET), and Data Analytics. Each program emphasizes building industry-grade projects and mastering job-ready skills.',
          },
          {
            title:
              '<span style="text-decoration: underline;">Project-Based Learning</span>',
            description:
              "Crio's programs are designed around practical, work-like projects that mimic real-world developer environments. This builds confidence and improves problem-solving skills.",
          },
        ],
      },
      {
        title: '<strong>Career Success and Support</strong>',
        description:
          'Crio.Do provides comprehensive support to ensure your career success.',
        items: [
          {
            title:
              '<span style="text-decoration: underline;">Career Outcomes</span>',
            description:
              'Check the Placement Stats section for details about companies hiring Crio graduates and how the programs help secure roles in startups, MNCs, and product-based companies.',
          },
          {
            title:
              '<span style="text-decoration: underline;">Testimonials</span>',
            description:
              'Success stories and testimonials highlight the journey of Crio learners who landed roles in top-tier companies, leveraging skills gained from Crio programs.',
          },
        ],
      },
    ],
  },
];
