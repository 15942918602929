import { EActions } from '@src/constants/general/index';
import React, { useEffect } from 'react';

export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

const initialState = {
  id: null,
  email: '',
  phone: '',
  theme: 'light',
  isEmailEventSubmitted: false,
  unlocked: false,
  utm: null,
  cookie: null,
  chatbotOpened: false,
  registrationDetails: null,
  country: null,
  isLoggedIn: false,
  projectDownloadCount: {},
  userIsApplying: false,
  isLeadGenFormOpen: false,
  globalLeadDocumentId: '',
  userLocation: {},
  userEventSlugsAppliedFor: [],
  popupData: {},
  popupShownTimestamp: null,
  programInterested: null,
  openFormStoredData: null,
  thankYouUrl: null,
  redeemedCodes: [],
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_USER_EVENT_SLUGS_APPLIED_FOR': {
      return {
        ...state,
        userEventSlugsAppliedFor: action.payload,
      };
    }
    case 'SET_USER_LOCATION': {
      return {
        ...state,
        userLocation: action.payload,
      };
    }
    case 'RESET_PROJECT_DOWNLOAD_COUNT': {
      return {
        ...state,
        projectDownloadCount: {},
      };
    }
    case 'SET_PROJECT_DOWNLOAD_COUNT': {
      return {
        ...state,
        projectDownloadCount: {
          ...state.projectDownloadCount,
          timestamp: Date.now(),
          count: state.projectDownloadCount.count
            ? state.projectDownloadCount.count + 1
            : 1,
        },
      };
    }
    case 'SET_CHATBOT_OPENED': {
      return {
        ...state,
        chatbotOpened: action.payload,
      };
    }
    case 'TOGGLE_THEME': {
      return {
        ...state,
        theme: state.theme === 'light' ? 'dark' : 'light',
      };
    }
    case 'SET_EMAIL': {
      return {
        ...state,
        email: action.payload,
      };
    }
    case 'SET_PHONE': {
      return {
        ...state,
        phone: action.payload,
      };
    }
    case 'SET_AFFILIATE_UNLOCKED': {
      return {
        ...state,
        unlocked: action.payload,
      };
    }
    case 'SET_UTM': {
      return {
        ...state,
        utm: action.payload,
      };
    }
    case 'SET_COOKIE': {
      return {
        ...state,
        cookie: action.payload,
      };
    }
    case 'USER_IS_APPLYING': {
      return {
        ...state,
        userIsApplying: action.payload,
      };
    }
    case 'SET_REGISTRATION_DETAILS': {
      return {
        ...state,
        registrationDetails: {
          ...state.registrationDetails,
          ...action.payload,
        },
      };
    }
    case 'SET_USER_ID': {
      return {
        ...state,
        id: action.payload,
      };
    }
    case 'SET_REDEEMED_CODES': {
      return {
        ...state,
        redeemedCodes: Array.isArray(state.redeemedCodes)
          ? [...state.redeemedCodes, ...action.payload]
          : [...action.payload], // Fallback to just action.payload
      };
    }
    case 'SET_COUNTRY': {
      return {
        ...state,
        country: action.payload,
      };
    }
    case 'USER_IS_LOGGED_IN': {
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    }

    case EActions.SET_POPUP_DATA: {
      return {
        ...state,
        popupData: action.payload,
      };
    }

    case EActions.SET_POPUP_SHOWN_TIMESTAMP: {
      return {
        ...state,
        popupShownTimestamp: action.payload,
      };
    }

    case 'SET_IS_LEAD_GEN_FORM_OPEN': {
      return {
        ...state,
        isLeadGenFormOpen: action.payload,
      };
    }
    case 'SET_GLOBAL_LEAD_DOCUMENT_ID': {
      return {
        ...state,
        globalLeadDocumentId: action.payload,
      };
    }
    case 'SET_PROGRAM_INTERESTED': {
      return {
        ...state,
        programInterested: action.payload,
      };
    }
    case 'SET_IS_EMAIL_EVENT_SUBMITTED': {
      return {
        ...state,
        isEmailEventSubmitted: action.payload,
      };
    }
    case 'SET_STORED_OPEN_FORM_DATA': {
      return {
        ...state,
        openFormStoredData: action.payload,
      };
    }
    case 'SET_THANK_YOU_URL': {
      return {
        ...state,
        thankYouUrl: action.payload,
      };
    }

    default:
      throw new Error('Bad Action Type');
  }
}
let localState;

let blacklist = ['userIsApplying', 'isLeadGenFormOpen'];

if (typeof window !== 'undefined') {
  localState = JSON.parse(localStorage.getItem('state'));
  if (localState) {
    blacklist.map((ele, idx) => {
      localState[ele] = initialState[ele];
    });
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    localState || initialState,
  );
  useEffect(() => {
    localStorage.setItem('state', JSON.stringify(state));
  }, [state]);

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export default GlobalContextProvider;
