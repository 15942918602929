export const chatFlowMap = new Map([
  [
    'q1',
    {
      questionId: 'q1',
      question: 'Welcome to Crio.Do! I am Kiran. How can I help you?',
      responseIds: ['r1', 'r2', 'r3', 'r4'],
    },
  ],
  [
    'q2',
    {
      questionId: 'q2',
      question: 'What do you wish to know more?',
      responseIds: ['r5', 'r6', 'r7'],
    },
  ],
  [
    'q3',
    {
      questionId: 'q3',
      question: 'Which program curriculum would wish to know?',
      responseIds: ['r12', 'r13', 'r14', 'r15', 'r16'],
    },
  ],
  [
    'q4',
    {
      questionId: 'q4',
      question: 'What do you wish to know more?',
      responseIds: ['r19', 'r5', 'r7'],
    },
  ],
  [
    'q5',
    {
      questionId: 'q5',
      question: 'What do you wish to know more?',
      responseIds: ['r19', 'r5', 'r6'],
    },
  ],
  [
    'q6',
    {
      questionId: 'q6',
      question: 'What do you wish to know more?',
      responseIds: ['r19', 'r5', 'r6', 'r7'],
    },
  ],
]);
