import React from 'react';
import RawHTML from '@components/Common/RawHTML';
import { ProgramCard } from './MessageTypes/ProgramCard';

export const ChatGrid = ({ items }) => {
  return (
    <div className="-mx-4 grid grid-cols-1 gap-4 p-2 md:grid-cols-2">
      {items.map((item, index) => {
        // If it's a program card with imageType, use ProgramCard
        if (item.imageType) {
          return <ProgramCard key={index} program={item} />;
        }

        // For other cards (without images or with direct content)
        return (
          <div
            key={index}
            className="flex h-[220px] flex-col rounded-lg border bg-transparent shadow-md"
          >
            {(item.image || item.imageComponent) && (
              <div className="relative h-24 w-full">
                {item.imageComponent ? (
                  item.imageComponent
                ) : (
                  <img
                    src={item.image}
                    alt={item.title}
                    className="h-full w-full object-cover"
                  />
                )}
              </div>
            )}
            <div className="flex flex-1 flex-col overflow-hidden p-3">
              <h3 className="mb-2 text-sm font-semibold text-black">
                <RawHTML>{item.title}</RawHTML>
              </h3>
              <div className="scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-300 hover:scrollbar-thumb-gray-400 flex-1 overflow-y-hidden">
                <RawHTML className="text-xs text-black">
                  {item.description || item.content}
                </RawHTML>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
