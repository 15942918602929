export const upskillMessages = [
  {
    message:
      'Crio offers several upskilling programs tailored to different career goals and expertise levels in fields such as software development, QA automation, data analytics, and system design. Here are some key features and options that might align with your goals:',
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'first',
    type: 'text',
  },
  {
    message: 'Fellowship Programs',
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'normal',
    type: 'section',
    payload: [
      {
        title:
          '<span style="text-decoration: underline;">Software Development (Full Stack or Backend)</span>',
        imageType: 'FullStack',
        description:
          'Hands-on project-based learning. Covers programming foundations, JavaScript or Java, data structures and algorithms (DSA), and advanced topics like REST APIs and microservices.',
      },
      {
        title: '<span style="text-decoration: underline;">System Design</span>',
        imageType: 'SystemDesign',
        description:
          'Includes low-level and high-level system design, advanced data structures, and dynamic programming.',
      },
      {
        title:
          '<span style="text-decoration: underline;">QA Automation (SDET)</span>',
        imageType: 'SDET',
        description:
          'Focuses on QA automation, web UI testing, API testing, and DevOps practices.',
      },
      {
        title:
          '<span style="text-decoration: underline;">Data Analytics with AI, SQL and Python</span>',
        imageType: 'DataAnalytics',
        description:
          'Explores data science fundamentals, Python, SQL, and AI-powered analytics.',
      },
    ],
  },
  {
    message: 'Learning Approach & Career Support',
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'normal',
    type: 'section_with_sub_section',
    payload: [
      {
        title: '<strong>Unique Learning Approach</strong>',
        description:
          'Crio offers a unique learning approach with real-world projects and flexible scheduling.',
        items: [
          {
            title:
              '<span style="text-decoration: underline;">Real-World Projects</span>',
            description:
              "Crio emphasizes building and working on professional-grade projects. You'll learn by doing, enhancing your portfolio and employability.",
          },
          {
            title:
              '<span style="text-decoration: underline;">Industry Mentorship</span>',
            description:
              'Access to live sessions and 1:1 mentorship with industry experts.',
          },
          {
            title:
              '<span style="text-decoration: underline;">Flexible Learning</span>',
            description:
              'Tailored for working professionals with flexible weekly schedules.',
          },
        ],
      },
      {
        title: '<strong>Career Support</strong>',
        description:
          'Crio provides career support with job assistance and success stories.',
        items: [
          {
            title:
              '<span style="text-decoration: underline;">Comprehensive job assistance</span>',
            description:
              'It includes mock interviews, resume building, and assured referrals to hiring companies.',
          },
          {
            title:
              '<span style="text-decoration: underline;">Success stories</span>',
            description:
              'Success stories highlight graduates excelling in top-tier companies like Google and startups.',
          },
        ],
      },
    ],
  },
  {
    message:
      "You can explore these programs and even try a free trial to evaluate your fit. For more details or to get started, book a free trial. Let me know if you'd like help deciding on a specific program or have further questions!",
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'normal',
    type: 'text',
  },
  {
    message: 'Book a free trial with Crio',
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'last',
    type: 'callToAction',
    payload: {
      text: 'Book a free trial with Crio',
      link: 'https://www.crio.do/',
    },
  },
];
