import React, { useState } from 'react';
import { Chatbot } from './components/Chatbot';
import { ChatFAB } from './components/ChatFAB';

const ExternalChatbot = ({ showTooltip, setHasTrackedOpen }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    setHasTrackedOpen(false);
  };

  return isOpen ? (
    <Chatbot onClose={handleClose} />
  ) : (
    <ChatFAB onClick={() => setIsOpen(true)} showTooltip={showTooltip} />
  );
};

export default ExternalChatbot;
