import { WHATSAPP_GROUP_METADATA } from '@src/constants/masterclass';
import React from 'react';

export default function Metadata() {
  return (
    <div className="grid grid-cols-1 grid-rows-2 gap-x-16 gap-y-4 md:grid-cols-2 md:gap-y-12">
      {WHATSAPP_GROUP_METADATA.map((metadata) => {
        return (
          <div
            key={metadata.id}
            className="grid grid-cols-[auto_1fr] grid-rows-[auto_1fr]  gap-x-[8px] gap-y-1 md:gap-x-[10px] md:gap-y-3"
          >
            <div className="row-[1_/_3] h-[20px] w-[20px] md:h-[30px] md:w-[30px]">
              {metadata.icon}
            </div>
            <div className="font-manrope text-[12px] font-bold leading-[16px] text-v5-green-700 md:text-lg">
              {metadata.title}
            </div>
            <div className="leading-1 font-rubik text-[8px] text-v5-neutral-500 md:text-sm">
              {metadata.subTitle}
            </div>
          </div>
        );
      })}
    </div>
  );
}
