import { Button } from '@mui/material';
import { openLinkInNewTab } from '@src/utils/Masterclass';
import React from 'react';

const JoinWhatsappButton = ({ whatsappGroupLink }) => (
  <Button
    className="natural sm flex h-10 items-center whitespace-nowrap rounded-[4px] border-2 border-v5-yellow-200 
       bg-v5-yellow-200 px-6 font-manrope text-[12px] font-bold 
       capitalize leading-[7px] text-black hover:bg-v5-yellow-100 md:h-[44px] 
       md:rounded-[10px] md:text-[14px] lg:hidden lg:w-48"
    type="contained"
    onClick={() => openLinkInNewTab(whatsappGroupLink)}
  >
    Join Whatsapp Group Now
  </Button>
);

export default JoinWhatsappButton;
