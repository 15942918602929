import React from 'react';
import RawHTML from '@components/Common/RawHTML';
import {
  AdrizaMishraImage,
  SuryaSainiImage,
  LopamudraRathImage,
  SubitDuttaImage,
  AyushSinhaImage,
  RitikBhattImage,
  KanchanRathoreImage,
  PijushGhoshImage,
  AbhishekSinghImage,
  KartikBajajImage,
  RishavRajImage,
  ShubhamSharmaImage,
  VimalSImage,
} from './ProfileImages';

export const ProfileCard = ({ profile }) => {
  const getImage = (imageType) => {
    switch (imageType) {
      case 'AdrizaMishra':
        return <AdrizaMishraImage />;
      case 'SuryaSaini':
        return <SuryaSainiImage />;
      case 'LopamudraRath':
        return <LopamudraRathImage />;
      case 'SubitDutta':
        return <SubitDuttaImage />;
      case 'AyushSinha':
        return <AyushSinhaImage />;
      case 'RitikBhatt':
        return <RitikBhattImage />;
      case 'KanchanRathore':
        return <KanchanRathoreImage />;
      case 'PijushGhosh':
        return <PijushGhoshImage />;
      case 'AbhishekSingh':
        return <AbhishekSinghImage />;
      case 'KartikBajaj':
        return <KartikBajajImage />;
      case 'RishavRaj':
        return <RishavRajImage />;
      case 'ShubhamSharma':
        return <ShubhamSharmaImage />;
      case 'VimalS':
        return <VimalSImage />;
      default:
        return null;
    }
  };

  return (
    <div className="flex h-full min-w-[165px] flex-col rounded-xl border border-v5-neutral-300 bg-v5-neutral-200 shadow-lg md:min-w-[210px]">
      <div className="relative  mx-auto mt-2 flex h-fit w-[90%] items-center justify-center overflow-hidden rounded-lg bg-v5-neutral-300 ">
        <div className=" h-full w-[144px]">{getImage(profile.imageType)}</div>
      </div>
      <div className="flex flex-1 flex-col overflow-hidden p-3">
        <h3 className="mb-2 text-sm font-semibold text-black">
          <RawHTML>{profile.title}</RawHTML>
        </h3>
        <div className="scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-300 hover:scrollbar-thumb-gray-400 flex-1 overflow-y-auto">
          <RawHTML className="text-xs text-black">
            {profile.description}
          </RawHTML>
        </div>
      </div>
    </div>
  );
};
