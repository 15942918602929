import { cn } from '@/lib/utils';
import React from 'react';

export default function ContextLabel({ text, className }) {
  return (
    <div
      className={cn(
        'leading-1 rounded-t-lg py-1 px-4 font-rubik text-[6px] text-white',
        className,
      )}
    >
      {text}
    </div>
  );
}
