import {
  FINAL_SEAT_FACTOR,
  FINAL_THRESHOLD_PERCENT,
  FIRST_SEAT_FACTOR,
  FIRST_THRESHOLD_PERCENT,
  MS_PER_DAY,
  SECOND_SEAT_FACTOR,
  SECOND_THRESHOLD_PERCENT,
} from '@src/constants/masterclass/dynamicSeatCount';

/**
 * Calculates the total duration between two dates in days
 */
export const calculateDurationDays = (startDate, endDate) => {
  const durationMs = endDate - startDate;
  return durationMs / MS_PER_DAY;
};

/**
 * Creates threshold array based on total duration
 */
export const createThresholds = (totalDurationDays) => {
  return [
    {
      percentage: FIRST_THRESHOLD_PERCENT,
      seatFactor: FIRST_SEAT_FACTOR,
      targetDays: totalDurationDays * (FIRST_THRESHOLD_PERCENT / 100),
    },
    {
      percentage: SECOND_THRESHOLD_PERCENT,
      seatFactor: SECOND_SEAT_FACTOR,
      targetDays: totalDurationDays * (SECOND_THRESHOLD_PERCENT / 100),
    },
    {
      percentage: FINAL_THRESHOLD_PERCENT,
      seatFactor: FINAL_SEAT_FACTOR,
      targetDays: totalDurationDays,
    },
  ];
};

/**
 * Calculates days passed between two dates
 */
export const calculateDaysPassed = (startDate, currentDate) => {
  return Math.floor((currentDate - startDate) / MS_PER_DAY);
};

/**
 * Seeded random generator
 */
export const seededRandom = (seed) => {
  let x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
};
