import * as React from 'react';
import { Dialog, Slide } from '@mui/material';
import LeadGenerationFormComponent from './LeadGenerationFormComponent';
import FormContextProvider from '@src/context/FormContextProvider';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Mobile view wrapper for lead generation form
export default function LeadGenerationFormMobileView({
  open,
  closeDialog,
  buttonLocation,
  programType,
  isOnePageForm,
  onSubmitPageOne,
  pageOneButtonText,
  pageTwoButtonText,
  applicationPage,
  formHeading,
  pageOneGradYear,
  pageOneWorkingProfessional,
  isFullStackPpcVl,
  customUtmParams,
  programInterested,
}) {
  return (
    <FormContextProvider>
      <Dialog
        fullScreen
        open={open}
        onClose={closeDialog}
        TransitionComponent={Transition}
      >
        <div className="h-screen w-screen overflow-y-auto bg-v5-green-700 px-5 py-6 transition-all scrollbar-hide">
          <LeadGenerationFormComponent
            closeDialog={closeDialog}
            programType={programType}
            mobile={true}
            buttonLocation={buttonLocation}
            isOnePageForm={isOnePageForm}
            onSubmitPageOne={onSubmitPageOne}
            pageOneButtonText={pageOneButtonText}
            pageTwoButtonText={pageTwoButtonText}
            applicationPage={applicationPage}
            formHeading={formHeading}
            pageOneGradYear={pageOneGradYear}
            pageOneWorkingProfessional={pageOneWorkingProfessional}
            isFullStackPpcVl={isFullStackPpcVl}
            customUtmParams={customUtmParams}
          />
        </div>
      </Dialog>
    </FormContextProvider>
  );
}
