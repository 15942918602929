import React from 'react';
import WhatsappIcon from '@src/images/whatsapp-icon.svg';
import useRegisterMetadata from '@src/hooks/Masterclass/useRegisterMetadata';
import { getShareContent } from '@src/utils/Masterclass';

export default function MobileInviteLink() {
  const { getEventDetails } = useRegisterMetadata();

  const handleGetOnWhatsapp = () => {
    const { shareInfo, sessionName } = getEventDetails();
    const whatsappGroupLink = shareInfo.whatsappGroupLink;
    const url = getShareContent(sessionName, whatsappGroupLink);
    window.open(url, '_blank');
  };

  return (
    <div className="flex flex-col items-center gap-y-5">
      <div className="leading-1 text-center font-rubik text-sm text-v5-neutral-500">
        Receive the Whatsapp
        <br />
        Group joining link on mobile
      </div>
      <div
        className="flex cursor-pointer flex-col items-center gap-2 rounded-lg border border-v5-neutral-300 py-4 px-5"
        onClick={handleGetOnWhatsapp}
      >
        <WhatsappIcon />
        <div className="leading-1 text-center font-manrope text-sm font-bold text-v5-green-700">
          Get On <br />
          Whatsapp
        </div>
      </div>
    </div>
  );
}
