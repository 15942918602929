import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Pagination } from 'swiper';
import EventCard from '@components/Masterclass/EventCardV2';
import { cn } from '@/lib/utils';

export default function EventSwiper({ events, id }) {
  return (
    <div
      className="masterclass-event-list flex items-start justify-center"
      key={id}
    >
      <Swiper
        grabCursor={true}
        spaceBetween={20}
        key={id}
        slidesPerView="auto"
        centeredSlides={events.length <= 1 ? true : false}
        className={cn('mx-auto p-2 md:mx-0 lg:max-w-[650px]')}
        pagination={{ el: '.swiper-pagination', clickable: true }}
        modules={[Pagination, Navigation]}
      >
        {events.map((event) => (
          <SwiperSlide
            key={event?.sessionSlug}
            className="h-auto w-[180px] items-start md:w-[300px]"
          >
            <div className="h-full">
              <EventCard event={event} />
            </div>
          </SwiperSlide>
        ))}

        {events.length > 1 && (
          <div className="slider-controler">
            <div className="swiper-pagination"></div>
          </div>
        )}
      </Swiper>
    </div>
  );
}
