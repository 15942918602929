import React from 'react';
import WhatsappIcon from '@src/images/whatsapp-icon.svg';

export default function Header() {
  return (
    <div className="leading-1 flex items-center justify-center gap-x-2 rounded-t-[10px] bg-v5-neutral-200 py-4 px-6 font-manrope text-[10px] font-bold md:justify-start md:gap-x-5 md:py-5 md:text-[40px]">
      <WhatsappIcon className="h-[18px] w-[18px] md:h-[40px] md:w-[40px]" />
      <div className="mb-1">Join event's Whatsapp Group</div>
    </div>
  );
}
