import Layout from '@components/v5/layouts/Layout/index';
import React from 'react';
import TestimonialsHeader from './TestimonialsHeader/index';
import TestimonialsSection from './TestimonialsSection/index';

export default function TestimonialsV4({ header, sub, testimonials, id }) {
  return (
    <Layout className="container flex flex-col items-center gap-2" id={id}>
      <TestimonialsHeader header={header} sub={sub} />
      <TestimonialsSection testimonials={testimonials} />
    </Layout>
  );
}
