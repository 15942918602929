import React from 'react';
import ChatSwiperCarousel from '../ChatSwiperCarousel';

export const ChatSectionWithCarousel = ({ items }) => {
  return (
    <div className="chat-section-with-carousel -mx-4 flex flex-col gap-4  ">
      {items.map((section, index) => (
        <div key={index} className="rounded-lg  px-4">
          <h3 className="mb-2 text-[14px] font-semibold text-black">
            {section.title}
          </h3>
          {section.type === 'carousel' ? (
            <>
              <p className="mb-4 text-sm text-black">{section.content}</p>
              <ChatSwiperCarousel items={section.payload} />
            </>
          ) : (
            <p className="text-sm text-black">{section.content}</p>
          )}
        </div>
      ))}
    </div>
  );
};
