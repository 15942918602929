export const systemDesignCurriculumMessages = [
  {
    message: 'Here is the curriculum of System Design that Crio is offering.',
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'first',
    type: 'text',
  },
  {
    message: 'System Design Fellowship Curriculum',
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'normal',
    type: 'section',
    payload: [
      {
        title: 'Low-Level Design and Application of SOLID Principles',
        content:
          'This section covers Low-Level Design (LLD) fundamentals, focusing on OOP and SOLID principles to create modular, reusable, and testable software. Learners practice designing systems that follow these principles, ensuring scalable and maintainable code. The Advanced LLD module deepens understanding of schema design, helping learners build complex systems incrementally using design patterns like Singleton, Factory, and Strategy. This section prepares learners for practical, real-world design problems, focusing on writing code that is both testable and extensible.',
      },
      {
        title: 'High-Level Design and Real-World Application',
        content:
          'This section focuses on High-Level Design (HLD), teaching design choices for large-scale distributed systems with hands-on exercises using a live application on the Cloud. Learners explore microservices architecture, event-driven architecture, and cloud design patterns. It covers concepts like Containers, Kibana, Kafka, Redis, CDN, Gateways, Monitoring, Scaling and Load Balancing to address performance and scalability challenges in real world scenarios. The HLD Interview Blitz prepares learners for system design interviews, where they apply concepts to real-world problems like designing Twitter, BookMyShow, and Hotstar, making key trade-offs in system design.',
      },
      {
        title: 'Introduction to DSA and Problem Solving',
        content:
          'In this section, learners are introduced to Data Structures and Algorithms (DSA), focusing on core concepts like logic-building, recursion, and problem-solving techniques. They study key structures like LinkedLists and Trees, and learn how to evaluate time and space complexity. Through practical problems, particularly involving arrays and strings, learners build the foundational skills needed for tackling algorithmic challenges and preparing for technical interviews.',
      },
      {
        title: 'Arrays, Advanced Problem Solving, and Data Structures',
        content:
          'This section dives into advanced problem-solving techniques for arrays and strings, such as two-pointer, sliding window, bit manipulation, and binary search. Learners solve common algorithmic problems using these patterns and develop expertise in Matrix and Prefix Sum problems. The Advanced Data Structures section covers Stacks, Queues, Linked Lists, and Trees, emphasizing BFS/DFS to solve tree-related problems. Learners deepen their understanding of data structures and how to use them to solve complex, real-world problems.',
      },
      {
        title: 'Advanced Algorithms and Backtracking',
        content:
          'This section covers advanced topics such as Heaps, Graphs, Greedy Algorithms, and Backtracking. Learners apply algorithms like Prim’s, Kruskal’s, and Dijkstra’s for solving graph theory and traversal problems. The section also covers optimization problems like Knapsack and spanning trees, enhancing problem-solving skills for complex algorithmic challenges. Learners develop the ability to solve more intricate problems using advanced algorithms and deepen their understanding of algorithmic techniques.',
      },
      {
        title: 'Dynamic Programming and Interview Preparation',
        content:
          'In this section, learners explore Dynamic Programming (DP) techniques like Memoization and Tabulation. They tackle complex problems involving Tries and Segment Trees, learning to identify common patterns. The Interview Blitz helps learners prepare for technical interviews, with a focus on common DP challenges and mock assessments. By the end of this section, learners are equipped to solve advanced DP problems and confidently handle algorithmic challenges in DSA interviews.',
      },
    ],
  },
  {
    message: 'Book a free trial with Crio',
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'last',
    type: 'callToAction',
    payload: {
      text: 'Book a free trial with Crio',
      link: 'https://crio.do/free-trial',
      programInterested: 'Software Development',
    },
  },
];
