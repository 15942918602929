import {
  MAX_SEATS_BEFORE_SESSION,
  MAX_SEATS_MORNING,
  MIN_SEATS_BEFORE_SESSION,
  MIN_SEATS_MORNING,
} from '@src/constants/masterclass/dynamicSeatCount';
import {
  calculateDaysPassed,
  calculateDurationDays,
  createThresholds,
  seededRandom,
} from './dynamicSeatCountUtils';

/**
 *  The seat count is updated daily based on the time elapsed since the creation of the event.
 *  There is a field on strapi isSeatIncreasing/isSeatDecreasing. Based on this field, the seat count is increased or decreased.
 *  If isSeatIncreasing is true, the count increases to indicate more people are registering.
 *  If isSeatDecreasing is true, the count decreases to show that seats are filling up.
 *  This creates a sense of urgency and excitement for users, encouraging them to register sooner.
 */

//Logic to calculate the increasing seat count:
export const getIncreasingDynamicSeatCount = (
  seatCount,
  createdAt,
  sessionStartDateTime,
) => {
  const sessionSeats = seatCount;
  const creationDate = new Date(createdAt);
  const sessionStartDate = new Date(sessionStartDateTime);
  const currentDateTime = new Date();

  // 1. Get total days between event creation and session start
  const totalDurationDays = calculateDurationDays(
    creationDate,
    sessionStartDate,
  );

  // 2. Create thresholds that determine how the seat count increases over time
  const thresholds = createThresholds(totalDurationDays);

  // 3. Loop through thresholds to find the current growth phase
  for (let i = 0; i < thresholds.length; i++) {
    const threshold = thresholds[i];
    const targetDate = new Date(creationDate);

    // 3.1. Calculate when this threshold phase ends
    targetDate.setDate(targetDate.getDate() + Math.floor(threshold.targetDays));

    // 3.2. If we are still within this threshold's duration:
    if (currentDateTime < targetDate) {
      // 3.3. Get how many days have passed since event creation
      const daysPassed = calculateDaysPassed(creationDate, currentDateTime);

      //3.4. Calculate how many seats should be added per day for this phas
      const dailyIncrement = Math.floor(
        (sessionSeats * threshold.seatFactor) / threshold.targetDays,
      );

      //3.5. Compute the total added seats so far, but limit it to the max allowed in this phase
      const gradualSeats = Math.min(
        sessionSeats * threshold.seatFactor,
        dailyIncrement * daysPassed,
      );

      //3.6. Return the updated seat count (rounded down to an integer)
      return Math.floor(gradualSeats);
    }
  }

  return sessionSeats; // If all thresholds are passed, return max seats
};

//Logic to calculate the decreasing seat count:
export const getDecreasingDynamicSeatCount = (
  seatCount,
  createdAt,
  sessionStartDateTime,
) => {
  const sessionSeats = seatCount;
  const creationDate = new Date(createdAt);
  const sessionStartDate = new Date(sessionStartDateTime);

  // 1. Define key time points on the day of the session
  const midnightOfSessionDate = new Date(sessionStartDate);
  midnightOfSessionDate.setHours(0, 0, 0, 0); // Midnight

  const fourHoursBeforeSession = new Date(sessionStartDate);
  fourHoursBeforeSession.setHours(fourHoursBeforeSession.getHours() - 4); // 4 hours before session

  const oneHourAfterSession = new Date(sessionStartDate);
  oneHourAfterSession.setHours(oneHourAfterSession.getHours() + 1); // 1 hour after session

  const currentDateTime = new Date();

  // 2. Generate a consistent random value for seat variation(so that every session which has the same start date has different seat count)
  const sessionSeed = creationDate.getTime() + sessionStartDate.getTime();
  const randomValue = seededRandom(sessionSeed); // Ensures the same random value per session so that seat count is consistent across page loads and navigation

  // 3. If today is the session day, adjust seat count dynamically

  // 3.1. If it's between midnight and 4 hours before the session
  if (
    currentDateTime >= midnightOfSessionDate &&
    currentDateTime < fourHoursBeforeSession
  ) {
    // Randomly reduce seat count within the morning range
    return Math.ceil(
      Math.min(
        sessionSeats,
        MIN_SEATS_MORNING +
          randomValue * (MAX_SEATS_MORNING - MIN_SEATS_MORNING),
      ),
    );
  }

  // 3.2. If it's between 4 hours before and 1 hour after the session starts
  if (
    currentDateTime >= fourHoursBeforeSession &&
    currentDateTime < oneHourAfterSession
  ) {
    // Randomly reduce seat count within the pre-session range
    return Math.ceil(
      MIN_SEATS_BEFORE_SESSION +
        randomValue * (MAX_SEATS_BEFORE_SESSION - MIN_SEATS_BEFORE_SESSION),
    );
  }

  // 3.3. If it's 1 hour past session start, show zero seats available
  if (currentDateTime >= oneHourAfterSession) {
    //3.3.1. No seats available after session has been running for 1 hour
    return 0;
  }

  // 4. For days before the session date, gradually reduce seat count

  // 4.1. Calculate total days between event creation and session start
  const totalDurationDays = calculateDurationDays(
    creationDate,
    sessionStartDate,
  );

  // 4.2. Create thresholds that determine how the seat count decreases over time
  const thresholds = createThresholds(totalDurationDays);

  // 4.3. Loop through thresholds to find the current reduction phase
  for (let i = 0; i < thresholds.length; i++) {
    const threshold = thresholds[i];
    const targetDate = new Date(creationDate);

    // 4.3.1. Calculate when this threshold phase ends
    targetDate.setDate(targetDate.getDate() + Math.floor(threshold.targetDays));

    //4.3.2. Check if today is before the target date for this threshold
    if (currentDateTime < targetDate) {
      // 4.3.3. Get how many days have passed since event creation
      const daysPassed = calculateDaysPassed(creationDate, currentDateTime);

      // 4.3.4. Calculate how many seats should be removed per day for this phase
      const dailyDecrement = Math.floor(
        (sessionSeats * threshold.seatFactor) / threshold.targetDays,
      );

      // 4.3.5. Compute the total reduced seats so far, but ensure a minimum limit
      const gradualSeats = Math.max(
        sessionSeats - dailyDecrement * daysPassed,
        sessionSeats * (1 - threshold.seatFactor),
      );

      // 4.3.6. Return the updated seat count (rounded up to an integer)
      return Math.ceil(gradualSeats);
    }
  }

  // 5. If all thresholds are passed, return zero seats available
  return 0;
};
