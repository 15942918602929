import React from 'react';
import { X, RotateCcw } from 'lucide-react';
import { Tooltip } from './Tooltip';

export const ChatControls = ({ onClose, onReset }) => {
  return (
    <div className="flex items-center gap-2 text-white ">
      <button
        onClick={onReset}
        className="chat-header-restart-chat flex items-center gap-2 rounded-full px-2 py-2 transition-all duration-200  hover:scale-105  md:px-4 "
        aria-label="Restart chat"
      >
        <span className=" chat-header-restart-chat text-xs  font-medium md:text-sm">
          Restart Chat
        </span>
        <RotateCcw
          size={16}
          color="black"
          className="chat-header-restart-chat"
        />
      </button>
      <Tooltip content="Close chat">
        <button
          onClick={onClose}
          className="rounded-full p-2 text-white transition-all duration-200 hover:scale-125 "
          aria-label="Close chat"
        >
          <X size={20} />
        </button>
      </Tooltip>
    </div>
  );
};
