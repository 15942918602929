/**
 * Handles insertion of special messages in the chat flow
 */

import { validationCallbacks } from '../actions/validationCallbacks';
import { createBotMessage } from './chatUtils';
import { validateInput } from './validationFlow';
import { isValidationComplete } from './validationUtils';

// Message type constants
export const INSERT_MESSAGE_TYPE = {
  NORMAL: 'normal',
  VALIDATION_REQUIRED: 'validation_required',
};

// Configuration for messages that need to be inserted at specific query counts
const queryBasedMessages = new Map([
  [
    3,
    {
      type: INSERT_MESSAGE_TYPE.VALIDATION_REQUIRED,
      validation: {
        type: 'email',
        regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        errorMessage:
          'Please enter a valid email address (e.g., ping@criodo.com)',
      },
      preMessage:
        "Great! Now, I'd like to get your email address to share more information...",
      messages: [
        {
          message: 'Please enter your email address',
          type: 'text',
        },
      ],
      onSuccess: [
        validationCallbacks.saveEmailToGlobalState,
        validationCallbacks.trackEmailInGTM,
      ],
    },
  ],
  [
    4,
    {
      type: INSERT_MESSAGE_TYPE.VALIDATION_REQUIRED,
      validation: {
        type: 'phone',
        regex: /^(?:\+91)?[6-9]\d{9}$/,
        errorMessage:
          'Please enter a valid 10-digit Indian mobile number (optionally with +91 prefix)',
      },
      preMessage:
        "Before we continue, I'd like to get your contact number to better assist you...",
      messages: [
        {
          message: 'Please enter your phone number (e.g., 06366528148)',
          type: 'text',
        },
      ],
      onSuccess: [
        validationCallbacks.savePhoneToGlobalState,
        validationCallbacks.trackPhoneInGTM,
        validationCallbacks.createLeadInLeadSquared,
      ],
    },
  ],
]);

/**
 * Checks if a message should be inserted at current query count
 * @param {number} queryCount - Current number of user queries
 * @returns {boolean} Whether to insert message
 */
export const shouldInsertMessage = (queryCount) => {
  if (!queryBasedMessages.has(queryCount)) return false;

  const messageConfig = queryBasedMessages.get(queryCount);
  if (messageConfig.type === INSERT_MESSAGE_TYPE.VALIDATION_REQUIRED) {
    return !isValidationComplete(messageConfig.validation.type);
  }
  return true;
};

/**
 * Gets messages to be inserted at current query count
 * @param {number} queryCount - Current number of user queries
 * @returns {Object} Messages and configuration for insertion
 */
export const getInsertedMessages = (queryCount) => {
  const messageConfig = queryBasedMessages.get(queryCount);
  if (!messageConfig) return { messages: [], config: null };

  const messages = [];
  if (messageConfig.preMessage) {
    messages.push(createBotMessage(messageConfig.preMessage));
  }

  messageConfig.messages.forEach((msg) => {
    messages.push(createBotMessage(msg.message, msg.type, msg.payload));
  });

  return {
    messages,
    config: messageConfig,
  };
};

/**
 * Validates a message against validation configuration
 * @param {string} message - Message to validate
 * @param {Object} validationConfig - Validation configuration
 * @param {Object} messageConfig - Message configuration containing onSuccess callbacks
 * @param {Function} dispatch - Global state dispatch function
 * @param {Object} state - Global state
 * @returns {Object} Validation result
 */
export const validateMessage = (
  message,
  validationConfig,
  messageConfig,
  dispatch,
  state,
) => {
  if (!validationConfig) return { isValid: true };

  const validationResult = validateInput(message, validationConfig);

  // If validation successful, execute onSuccess callbacks
  if (validationResult.isValid && messageConfig?.onSuccess) {
    messageConfig.onSuccess.forEach((callback) => {
      callback(message, dispatch, state);
    });
  }

  return validationResult;
};
