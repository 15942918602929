/**
 * Import mentor section data from JSON and custom hook for handling mentor images
 */
import mentorSectionData from '@src/data/mentor_section_db.json';
import useGraphqlImage from '@src/hooks/MentorSection/useMentorSectionImage';

/**
 * Custom hook to fetch and process mentor data based on program type
 * @param {string} programType - Type of program (e.g., 'FSD', 'QA', 'DATA_ANALYTICS')
 * @returns {Array} Array of mentor objects with attached image data
 */
export default function useMentorSectionData(programType) {
  // Get image data using GraphQL hook
  const { mentorSectionImgNode } = useGraphqlImage();

  // Convert program type to standardized program name
  const programName = sanitizePath(programType);

  // Filter mentors array to only include mentors from the specified program
  const filteredMentors =
    mentorSectionData?.mentor_section_data?.mentors.filter(
      (mentor) => mentor.attributes.program_name === programName,
    );

  /**
   * Filter out large images from the image nodes
   * We only want thumbnail versions for better performance
   */
  const filteredImageNodes = mentorSectionImgNode.Thumbnail?.nodes.filter(
    (node) => !node.name.includes('large'),
  );

  /**
   * For each mentor:
   * 1. Find matching mentor image using hash
   * 2. Find matching company image using hash
   * 3. Attach both images to the mentor object
   */
  const filteredMentorDetails = filteredMentors.map((mentor) => {
    // Find mentor's profile image by matching hash
    const mentorImageNode = filteredImageNodes.find(
      (node) =>
        node.name === mentor.attributes.mentor_image.data?.attributes.hash,
    );

    // Find mentor's company logo by matching hash
    const companyImageNode = filteredImageNodes.find(
      (node) =>
        node.name === mentor.attributes.company_image.data.attributes.hash,
    );

    // Attach image nodes to mentor object
    mentor.mentor_image = mentorImageNode;
    mentor.company_image = companyImageNode;

    return mentor;
  });

  //Fetch the header details
  const headerDetails = mentorSectionData?.mentor_section_data?.headers.find(
    (header) => header.attributes.ProgramName === programName,
  );

  return {
    header:
      headerDetails?.attributes?.Heading || 'Why companies come back to us',
    sub: headerDetails?.attributes?.subHeading || '',
    testimonials: filteredMentorDetails,
  };
}

/**
 * Utility function to standardize program type names
 * Converts URL-friendly program types to internal program names
 * @param {string} program_type - Program type from URL or component prop
 * @returns {string} Standardized program name used in data
 */
export const sanitizePath = (program_type) => {
  switch (program_type) {
    case 'FSD':
      return 'SOFTWARE_DEVELOPMENT';
    case 'QA':
      return 'QA_AUTOMATION';
    case 'DATA_ANALYTICS':
      return 'DATA_ANALYTICS';
    case 'ACCELERATE':
      return 'ACCELERATE';
    default:
      return 'SOFTWARE_DEVELOPMENT';
  }
};
