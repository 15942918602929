import useRegisterMetadata from '@src/hooks/Masterclass/useRegisterMetadata';
import React from 'react';

export default function JoinViaPc() {
  const { getEventDetails } = useRegisterMetadata();
  const { shareInfo } = getEventDetails();
  const { whatsappGroupLink } = shareInfo;
  return (
    <div
      className="leading-1 cursor-pointer font-manrope text-sm font-bold text-[#2867B2] underline"
      onClick={() => window.open(whatsappGroupLink, '_blank')}
    >
      Join Via Pc
    </div>
  );
}
