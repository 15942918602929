import { cn } from '@/lib/utils';
import { Button } from '@mui/material';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { twMerge } from 'tailwind-merge';

export default function EventCard({ event }) {
  const {
    sessionCardImg,
    sessionName,
    sessionDate,
    sessionTime,
    sessionHostName,
    sessionHostDesignation,
    isSeatTypeHyped,
    sessionSeats,
    sessionSlug,
    isPastEvent,
  } = event;

  return (
    <div
      className={cn(
        'relative flex h-full max-w-[180px] flex-col rounded-[15px] shadow-[0px_3.72px_3.53px_0px_#00000026] md:max-w-[340px]',
        !sessionCardImg && 'bg-[#d9d9d9]',
      )}
    >
      {sessionCardImg ? (
        <GatsbyImage
          image={sessionCardImg}
          className="max-h-[70px] rounded-t-[15px] object-cover md:h-auto md:max-h-[128px]"
        ></GatsbyImage>
      ) : (
        <div className="h-[70px] rounded-t-[15px] md:h-[115px]"></div>
      )}

      {/* Event Details Card*/}
      <div className="flex w-full flex-grow flex-col justify-between rounded-[15px] bg-white p-[14px] pt-[8px] pb-[10px] md:gap-y-6 md:p-6 md:pb-6 md:pt-6">
        {/* Session Name + Session Schedule */}
        <div className="relative mb-4 flex flex-col gap-[6px] md:mb-0 md:gap-[7px]">
          {/* Session Name */}
          <div className="line-clamp-2 font-manrope text-[12px] font-bold leading-[12px] text-v5-green-700 md:text-[22px] md:leading-[24px]">
            {sessionName}
          </div>

          {/* Session Date and Time */}
          <div className="font-rubik text-[6px] leading-[8px] text-v5-neutral-500 md:text-xs">
            <span className="font-medium">Date : </span>
            <span className="font-normal">{sessionDate}</span>
            &nbsp;
            <span className="font-medium">Time : </span>
            <span className="font-normal">{sessionTime}</span>
          </div>
        </div>

        {/* Session Host Details */}
        <div className="mb-4 flex flex-col gap-[2px] leading-[13px] text-v5-neutral-500 md:mb-0 md:gap-2">
          <div className="font-manrope text-[8px] font-semibold leading-[8px] md:text-[16px] md:leading-[18px]">
            {sessionHostName}
          </div>
          <div className="font-rubik text-[6px] font-normal leading-[8px] md:text-[11px] md:leading-[12px]">
            {sessionHostDesignation}
          </div>
        </div>

        {/* Register Button */}
        <div className="mt-auto">
          {isPastEvent ? (
            <Link
              to={`/masterclass/register/${sessionSlug}`}
              className="inline-block w-full rounded-[4px] border border-black md:rounded-[10px]"
            >
              <Button
                className={twMerge(
                  `natural flex h-[20px] w-full items-center whitespace-nowrap rounded-[4px] bg-[#F2FBF7] font-manrope text-[8px] font-bold capitalize leading-[7px] text-black hover:bg-v5-yellow-100 md:h-[40px] md:rounded-[10px] md:text-[14px] `,
                )}
                type="contained"
              >
                Watch Now
              </Button>
            </Link>
          ) : (
            <div className="flex items-center justify-between gap-2">
              <div className="flex-1 font-rubik text-[6px] font-normal leading-[8px] text-v5-neutral-500 md:text-xs md:leading-[16px]">
                {isSeatTypeHyped ? (
                  <span>
                    <span className="font-medium">{sessionSeats}</span> People
                    have registered
                  </span>
                ) : (
                  <span>
                    Seats Left:{' '}
                    <span className="font-medium">{sessionSeats}</span>
                  </span>
                )}
              </div>
              <Link to={`/masterclass/register/${sessionSlug}`}>
                <Button
                  className={twMerge(
                    `natural sm flex h-[20px] w-16 items-center whitespace-nowrap rounded-[4px] border-2 border-v5-yellow-200 bg-v5-yellow-200 font-manrope text-[8px] font-bold capitalize leading-[7px] text-black hover:bg-v5-yellow-100 md:h-[40px] md:w-[120px]  md:rounded-[10px] md:text-[14px] `,
                  )}
                  type="contained"
                >
                  Register Now
                </Button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
