import { useLocation } from '@reach/router';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';
import { getQueryParams, getUtmAttributes } from '@src/utils/helper';
import { useContext } from 'react';
import LeadGenerationService from '../../utils/lead-generation';

const useSendActivities = () => {
  const state = useContext(GlobalStateContext);
  const location = useLocation();
  const queryParams = getQueryParams(location.search);

  const sendActivityHandler = async (activityCode) => {
    const utm = getUtmAttributes(activityCode);

    const utmParams = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_content',
      'utm_term',
      'utm_network',
      'utm_publisher',
    ];

    const fields = utmParams.reduce((acc, param) => {
      if (queryParams[param]) {
        acc.push({ SchemaName: utm[param], Value: queryParams[param] });
      }
      return acc;
    }, []);
    console.log('🚀 ~ fields ~ fields:', fields);

    const leadAndActivityPayload = {
      LeadDetails: [
        {
          Attribute: 'EmailAddress',
          Value: state?.email.toLowerCase(),
        },
      ],
      Activity: {
        ActivityEvent: activityCode,
        Fields: fields,
      },
    };

    await LeadGenerationService.queueLeadAndActivity(leadAndActivityPayload);
  };

  console.log('🚀 ~ useSendActivities ~ location:', queryParams);

  return { sendActivityHandler };
};

export default useSendActivities;
