import TestimonialsV4 from '@components/TestimonialsV4';
import React from 'react';

export default function Testimonials({ data }) {
  const { header, sub, testimonials } = data;

  function sanitizeTestimonialData() {
    return testimonials.map(({ attributes, company_image, mentor_image }) => ({
      employeeName: attributes.mentor_name,
      employeeDesignation: attributes.mentor_designation,
      employeeSocialUrl: attributes.linkedin_url,
      employeeImage: mentor_image,
      companyImage: company_image,
    }));
  }

  const mentorTestimonials = sanitizeTestimonialData();

  return (
    <TestimonialsV4
      header={header}
      sub={sub}
      testimonials={mentorTestimonials}
      id="#testimonials"
    />
  );
}
