/**
 * Configuration for all validation types in the chatbot
 * Add new validation types here with their complete configuration
 */

export const VALIDATION_TYPES = {
  PHONE: 'phone',
  EMAIL: 'email',
  // Add new validation types here
};

export const validationSchemas = {
  [VALIDATION_TYPES.PHONE]: {
    type: VALIDATION_TYPES.PHONE,
    regex: /^(?<!\d)0?(?:\+91-?|91-?)?\s*?([6-9]\d{9})(?!\d)$/,
    errorMessage:
      'Please enter a valid 10-digit Indian mobile number (optionally with +91 prefix)',
    inputPlaceholder: 'Enter your phone number...',
    retryMessage: 'Please enter your phone number again',
    preMessage:
      "Before we continue, I'd like to get your contact number to better assist you...",
    promptMessage: 'Please enter your phone number (e.g., 06366528148):',
    cleaningFunction: (input) => input.replace(/[- ]/g, ''),
    additionalValidations: [
      {
        check: (input) => input.includes('@'),
        errorMessage: 'Please enter a phone number, not an email address',
      },
      {
        check: (input) => !/^\+?\d+$/.test(input),
        errorMessage:
          'Phone number can only contain digits (and optionally +91 prefix)',
      },
    ],
  },
  [VALIDATION_TYPES.EMAIL]: {
    type: VALIDATION_TYPES.EMAIL,
    regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    errorMessage: 'Please enter a valid email address (e.g., ping@criodo.com)',
    inputPlaceholder: 'Enter your email address...',
    retryMessage: 'Please enter your email address again',
    preMessage:
      "Great! Now, I'd like to get your email address to share more information...",
    promptMessage: 'Please enter your email address',
    cleaningFunction: (input) => input.trim(),
    additionalValidations: [
      {
        check: (input) => !input.includes('@') || !input.includes('.'),
        errorMessage:
          'Email must contain @ and a domain (e.g., ping@criodo.com)',
      },
    ],
  },
};
