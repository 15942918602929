import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import useResizer from '@components/extra/useResizer';

export default function TestimonialsCard({ testimonial }) {
  const {
    employeeName,
    employeeDesignation,
    employeeImage,
    companyImage,
    employeeSocialUrl,
  } = testimonial;
  const isScreenLessThan1200 = useResizer(1200);

  const data = useStaticQuery(graphql`
    query {
      socialIcons: allFile(
        filter: { relativePath: { regex: "images/v5/Launch/Social/" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              width: 800
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `);

  const testimonialGatsbyImage = getImage(employeeImage?.childImageSharp);
  const companyGatsbyImage = getImage(companyImage?.childImageSharp);

  const linkedinLogo = data?.socialIcons?.nodes.find((node) =>
    node.name.includes('linkedin-black'),
  );

  const linkedinGatsbyImage = getImage(linkedinLogo?.childImageSharp);

  return (
    <div
      className="hidden grid-cols-[1fr,2fr] gap-14 rounded-[20px] border border-[#E6F4F2] bg-white py-2 px-5 md:grid"
      style={{
        boxShadow: isScreenLessThan1200
          ? 'none'
          : '10px 10px 10px 0px #0000000D',
      }}
    >
      {/* Employee Image */}
      <div className="my-auto h-[310px] w-[250px] overflow-hidden rounded-[20px]">
        <GatsbyImage
          image={testimonialGatsbyImage}
          className="h-full w-full object-cover"
          alt={employeeName}
        />
      </div>

      <div className="relative flex h-auto min-h-[300px] w-full max-w-md flex-col items-start py-3 font-manrope">
        {/* Employee Details */}
        <div className="text-left font-light text-v5-neutral-500 md:text-[22px] md:leading-[32px]">
          <strong className="font-extrabold">{employeeName}</strong>
        </div>
        <div className=" flex flex-col items-start justify-start  py-6">
          <div className="mb-6 text-left font-rubik font-semibold text-v5-neutral-400 md:text-base md:leading-none">
            {employeeDesignation}
          </div>
          <div className="relative h-[60px] w-full max-w-[192px]">
            <GatsbyImage
              image={companyGatsbyImage}
              className="!absolute inset-0 h-full w-full"
              imgClassName="object-contain object-left !h-[60px]"
              alt={`${employeeName}'s company logo`}
            />
          </div>
          <a
            href={employeeSocialUrl}
            target="_blank"
            className="absolute bottom-0 "
            rel="noreferrer"
          >
            <GatsbyImage
              image={linkedinGatsbyImage}
              className="-ml-3 h-24 w-24  object-contain "
              alt="LinkedIn logo"
            />
          </a>
        </div>
      </div>
    </div>
  );
}
