export const switchJobMessages = [
  {
    message:
      "If you are planning to switch jobs, Crio's programs can be a significant boost. Crio offers specialized, project-based learning programs across software development (backend, full-stack), data analytics, QA automation, and system design.",
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'first',
    type: 'text',
  },
  {
    message: "Here's how Crio can help with your job transition:",
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'normal',
    type: 'section',
    payload: [
      {
        title: 'Real-World Project Experience',
        content:
          "Crio's curriculum emphasizes hands-on experience through real-world projects, preparing you to tackle complex scenarios that are common in industry settings.",
      },
      {
        title: 'Structured Learning Path',
        content:
          'Programs in software development, system design, and data analytics cover everything from foundational concepts to advanced topics.',
      },
      {
        title: 'Industry-Ready Skills',
        content:
          'Crio programs equip you with skills that employers are looking for, such as Java, Python, SQL, DSA, and system design principles.',
      },
      {
        title: 'Job Assistance',
        content:
          'Crio provides career support, including mock interviews, referral opportunities, and resume help to improve your chances of landing a new position.',
      },
      {
        title: 'Networking with Experts',
        content:
          'Throughout the program, you will have access to 1-on-1 sessions with industry experts, helping you connect and gain insights on transitioning smoothly into a new role.',
      },
    ],
  },
  {
    message:
      'If you are interested in exploring the options or need personalized guidance, you can book a free trial with Crio, where a Crio representative will provide additional information on the programs and discuss any scholarship opportunities that might be available. Let me know if you want more specifics about any particular program or career track',
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'last',
    type: 'text',
  },
];
