import Layout from '@components/v5/layouts/Layout';
import React from 'react';
import ContextLabel from '../ContextLabel';
import Header from './Header';
import Metadata from './Metadata';
import WhatsappInvite from './Invite';

export default function WhatsappGroupDetails() {
  return (
    <Layout className="container flex flex-col items-center pt-12 md:items-start ">
      {/* Context Label */}
      <ContextLabel
        text="To do before the event"
        className={
          'left-10 bg-v5-green-200 text-sm text-[10px] md:relative md:text-[14px]'
        }
      />

      {/* Whatsapp Group Details Container */}
      <div className="w-full rounded-[10px] border border-v5-green-200 md:border-[3px]">
        <Header />
        <div className="flex flex-col items-center justify-between gap-x-6 gap-y-5 p-6 md:p-12 lg:flex-row lg:items-stretch">
          <Metadata />
          <WhatsappInvite />
        </div>
      </div>
    </Layout>
  );
}
