import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'react-hot-toast';
import useResizer from '@components/extra/useResizer';

const getToastStyles = (type) => {
  switch (type) {
    case 'success':
      return {
        color: 'rgba(#222222, 0.9)',
        background: '#29DFC0',
      };
    case 'error':
      return {
        color: 'rgba(#222222, 0.9)',
        background: '#f77f6a',
      };
    default:
      return;
  }
};

/**
 *
 * @param string type
 * @returns toast icon
 */
const getToastIcon = (type) => {
  switch (type) {
    case 'success':
      return (
        <div className="flex items-center justify-center rounded-full bg-white p-1 md:h-[26px] md:w-[26px] md:p-0">
          <FontAwesomeIcon
            icon={['fas', 'check']}
            style={{
              color: '#233532',
            }}
            size="1x"
          />
        </div>
      );
    case 'error':
      return (
        <div className="flex items-center justify-center rounded-full bg-white p-1 md:h-[26px] md:w-[26px] md:p-0">
          <FontAwesomeIcon
            icon={['fas', 'xmark']}
            style={{
              color: '#233532',
            }}
            size="1x"
          />
        </div>
      );
    default:
      return;
  }
};

/**
 *
 * @param string type
 * @param string message
 * @returns Custom Toast
 */
export const useCustomToast = (type = 'success', message = '') => {
  const isMobile = useResizer();

  return () =>
    toast[type](message, {
      id: 'toast',
      duration: 8000,
      style: {
        fontSize: '14px',
        minWidth: isMobile ? '' : 'auto',
        ...getToastStyles(type),
      },
      icon: getToastIcon(type),
      className: 'py-3 px-4 text-base rounded-lg',
    });
};
