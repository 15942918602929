import { cn } from '@/lib/utils';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default function RegistrationSuccessMessage({ className }) {
  return (
    <div
      className={cn(
        'inline-block w-auto rounded-2xl bg-[#2463CB] px-3 py-2 font-rubik text-[8px] leading-none text-white md:text-sm',
        className,
      )}
    >
      <FontAwesomeIcon icon={faCircleCheck} />
      <span className="ml-2">
        Yay! You have been registered successfully for this event
      </span>
    </div>
  );
}
