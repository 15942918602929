import { chatFlowMap } from '../data/chatFlow';
import { chatResponseMap } from '../data/chatResponses';

export const getNextQuestion = (questionId) => {
  return chatFlowMap.get(questionId);
};

export const getResponse = (responseId) => {
  return chatResponseMap.get(responseId);
};

export const createOptionsFromFlow = (flow) => {
  return flow.responseIds.map((responseId) => {
    const response = chatResponseMap.get(responseId);
    return {
      text: response?.text || '',
      value: responseId,
    };
  });
};

export const createBotMessage = (text, type = 'text', payload) => {
  return {
    message: text,
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'single',
    type,
    payload,
  };
};
