import ChatBotIcon from '@components/ChatBotIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '@src/context/GlobalContextProvider';
import React, { useContext, useEffect, useState } from 'react';
import { GTM } from '../../../src/analytics/gtm';
import { gtmEvents } from '../../../src/analytics/gtmEventsConstant';
import chatIcon from '../../../src/images/ChatIcon.webp';
import backendDev from '../../../src/images/Backend-Developer.png';
import steps from '../../misc/chatbot-steps';
import stepsi18n from '../../misc/i18n/chatbot-steps';
import countries from '../../../utils/i18n-countries';
import loadable from '@loadable/component';
import * as styles from './ChatBotComponent.module.scss';
import Loader from '@components/Loader/index';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import { LEAD_GEN_CHATBOT_CALLBACK } from '@src/constants/LeadGenerationConstants/index';
import { useCustomToast } from '@components/extra/useCustomToast';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';
import { useLocation } from '@reach/router';
import Cookies from 'js-cookie';
import useSendActivities from '@src/hooks/useSendActivities';

const ChatBot = loadable(() => import('react-simple-chatbot'), {
  fallback: <Loader></Loader>,
});

export default function ChatBotComponent(props) {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const [fabOpen, setFabOpen] = useState(false);
  const [chatbotOpen, setChatbotOpen] = useState(false);
  const [firstTimeOpen, setfirstTimeOpen] = useState(false);

  const location = useLocation();
  const showCallbackModal = location.search.includes('openCallback=true');

  const { sendActivityHandler } = useSendActivities();

  useEffect(() => {
    window.openChatBot = () => {
      dispatch({
        type: 'SET_CHATBOT_OPENED',
        payload: true,
      });
      setChatbotOpen(true);
    };

    if (showCallbackModal) {
      setFabOpen(true);
    }
  }, []);

  const triggerSuccessToast = useCustomToast(
    'success',
    <div className="">
      <h2 className="font-base font-rubik text-lg">Success!</h2>
      <p className="font-rubik text-xs">
        Thanks! We will give you a call or email you between 9 AM to 9 PM
      </p>
    </div>,
  );

  function onSubmit(email, phone) {
    GTM.login({
      userId: email,
      email: email,
      phone: phone,
      whatsappNumber: phone,
    });
    GTM.setEmail(email);

    //GTM Event for Email Capture
    if (!state.isEmailEventSubmitted) {
      GTM.track(gtmEvents.EMAIL_SUBMITTED, {
        type: 'CALL_ME_POPUP',
      });
      dispatch({
        type: 'SET_IS_EMAIL_EVENT_SUBMITTED',
        payload: true,
      });
    }

    GTM.leadSquaredEvent(
      ELeadSquaredActivityCode.CALL_ME_BACK_SUBMITTED,
      email,
    );

    sendActivityHandler(ELeadSquaredActivityCode.CALL_ME_BACK_SUBMITTED);

    window.webengage &&
      window.webengage.user.setAttribute('we_whatsapp_opt_in', true);

    if (Cookies.get('isPhoneVerified') === 'true') triggerSuccessToast();
  }

  return (
    <div
      className={`shadow-2xl ${
        state.userIsApplying && 'hidden'
      } fixed z-[1000]`}
      style={{
        display: 'block',
      }}
    >
      {chatbotOpen ? (
        <ChatBot
          steps={countries.includes(state.country) ? stepsi18n : steps}
          bubbleOptionStyle={{
            background: '#FCB848',
            boxShadow:
              '0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13)',
            color: '#08322D',
            cursor: 'pointer',
            fontWeight: 'normal',
          }}
          botAvatar={chatIcon}
          avatarStyle={{ marginRight: '7px' }}
          bubbleStyle={{ maxWidth: '70%' }}
          userAvatar={backendDev}
          floatingIcon={<ChatBotIcon isBotFirstInteration={firstTimeOpen} />}
          opened={true}
          enableMobileAutoFocus={false}
          toggleFloating={({ opened }) => {
            if (opened) {
              GTM.track(gtmEvents.CHATBOT_OPENED);

              sendActivityHandler(
                ELeadSquaredActivityCode.CHAT_WITH_ME_CLICKED,
              );
            }
            setChatbotOpen(opened);
            if (opened && !firstTimeOpen) {
              setfirstTimeOpen(true);
            }
          }}
        />
      ) : (
        ''
      )}

      <div className={styles.fab}>
        {fabOpen && (
          <div>
            <div className="mb-4 flex items-center justify-end ">
              <div className="mr-3 rounded-md bg-black p-1 text-sm text-white">
                Request Callback
              </div>
              <LeadGenerationButton
                buttonComponent={
                  <div
                    className={`${styles.circle} bg-crio-yellow-500 text-black`}
                  >
                    <FontAwesomeIcon
                      icon={['fas', 'phone-alt']}
                      size="1x"
                    ></FontAwesomeIcon>
                  </div>
                }
                isOnePageForm
                shouldOpenLeadForm={showCallbackModal}
                formHeading="Request Callback"
                pageOneButtonText="Call me back"
                buttonLocation={LEAD_GEN_CHATBOT_CALLBACK}
                onSubmitPageOne={onSubmit}
                clickEvent={() => {
                  GTM.track(gtmEvents.CALLME_OPENED);

                  sendActivityHandler(
                    ELeadSquaredActivityCode.REQUEST_CALL_BACK_CLICKED,
                  );
                }}
              ></LeadGenerationButton>
            </div>
            <div className="mb-4 flex items-center justify-end ">
              <div className="mr-3 rounded-md bg-black p-1 text-sm text-white">
                Chat with me
              </div>
              <div
                className={`${styles.circle} bg-crio-yellow-500 text-black`}
                onClick={() => {
                  GTM.track(gtmEvents.CHATBOT_OPENED);

                  sendActivityHandler(
                    ELeadSquaredActivityCode.CHAT_WITH_ME_CLICKED,
                  );
                  // setChatbotOpen(!chatbotOpen);

                  if (window && window.fcWidget) {
                    window.fcWidget.show();
                    window.fcWidget.open();
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={['fas', 'comment-alt']}
                  size="1x"
                ></FontAwesomeIcon>
              </div>
            </div>
          </div>
        )}

        <div
          className={`${styles.circle}  ${
            fabOpen ? 'bg-gray-100' : 'bg-crio-yellow-500'
          } `}
          onClick={() => {
            debugger;
            if (!fabOpen) {
              dispatch({
                type: 'SET_CHATBOT_OPENED',
                payload: true,
              });
              GTM.track(gtmEvents.FAB_CLICKED);

              sendActivityHandler(ELeadSquaredActivityCode.CHAT_ICON);
            }

            setFabOpen(!fabOpen);
            setChatbotOpen(false);
            dispatch({
              type: 'SET_CHATBOT_OPENED',
              payload: false,
            });
          }}
        >
          {!fabOpen && !state.chatbotOpened ? (
            <ChatBotIcon isBotFirstInteration={firstTimeOpen} />
          ) : (
            <FontAwesomeIcon
              icon={['fal', 'times']}
              size="1x"
            ></FontAwesomeIcon>
          )}
        </div>
      </div>
    </div>
  );
}
