import React from 'react';
import MasterclassSectionLayout from '../Layouts/MasterclassSectionLayout';
import 'swiper/css';
import useResizer from '@components/extra/useResizer';
import useRegisterMetadata from '@src/hooks/Masterclass/useRegisterMetadata';
import EventList from '../EventList';

export default function UpcomingEvents() {
  const { getPastAndUpcomingEvents } = useRegisterMetadata();
  const isMobile = useResizer();
  const { events, isShowingPreviousEvents } = getPastAndUpcomingEvents() || {};

  return events.length > 0 ? (
    <MasterclassSectionLayout
      layoutClassName="masterclass-event-list container grid grid-cols-1 md:static md:pt-[32px] pt-[26px] md:pb-[100px] lg:grid-cols-[58%_1fr] xl:grid-cols-[58%_1fr]"
      heading={isShowingPreviousEvents ? 'Previous Events' : 'Upcoming Events'}
      sectionWrapperClassName={'md:gap-[30px] gap-[18px]'}
      headingClassName={'md:py-9 md:px-7 shadow-[0px_4px_9.4px_0px_#00000014]'}
    >
      <EventList
        events={events.slice(0, 2)}
        displayType={isMobile ? 'swiper' : 'grid'}
        shouldShowSection={events.length > 0}
        gridContainerClassName={`grid-cols-${events.slice(0, 2).length}`}
        wrapperClassName={'pt-3 px-0'}
      />
    </MasterclassSectionLayout>
  ) : null;
}
