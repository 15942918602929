export const fresherMessages = [
  {
    message:
      "If you're a fresher aiming to secure your first job, Crio's programs are designed to equip you with the skills and practical experience to stand out in the job market. Here's how Crio can help you as a beginner:",
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'first',
    type: 'text',
  },
  {
    message: 'How Crio Helps Freshers',
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'normal',
    type: 'section_with_sub_section',
    payload: [
      {
        title: '<strong>Hands-On, Project-Based Learning</strong>',
        description:
          "Crio's curriculum focuses on building real-world projects that simulate industry work. This experience prepares you to work on existing codebases, collaborate with team members, and solve practical problems, giving you a clear advantage over theoretical knowledge.",
      },
      {
        title:
          '<strong>Beginner-Friendly Programs with Step-by-Step Guidance</strong>',
        description:
          'For freshers, Crio offers programs that start from the basics, whether you are interested in software development, QA automation, or data analytics. Each program begins with foundational skills, allowing you to build up your knowledge and confidence progressively. For example:',
        items: [
          {
            title:
              '<span style="text-decoration: underline;">Software Development:</span>',
            description:
              'Learn programming basics, data structures, and algorithms before diving into specialized skills in backend or full-stack development.',
          },
          {
            title:
              '<span style="text-decoration: underline;">Data Analytics:</span>',
            description:
              'Start with Excel and SQL basics, then advance to Python programming and statistical analysis.',
          },
        ],
      },
      {
        title: '<strong>Extensive Career Support and Job-Readiness</strong>',
        description:
          'Crio offers a variety of career support options, including:',
        items: [
          {
            title:
              '<span style="text-decoration: underline;">Mock Interviews and Resume Preparation:</span>',
            description:
              'You will get assistance in creating an impactful resume and undergo mock interviews to practice common interview questions and scenarios.',
          },
          {
            title:
              '<span style="text-decoration: underline;">Job Referrals:</span>',
            description:
              'Crio has connections with hiring partners across various sectors, including tech companies and startups, giving you a direct route to job opportunities.',
          },
        ],
      },
      {
        title: '<strong>GitHub Portfolio for a Strong Job Application</strong>',
        description:
          'All the projects you complete in Crio programs will be synced to a personalized GitHub profile, allowing you to showcase your work and skills to recruiters. This portfolio is a great addition to your resume, especially for freshers.',
      },
      {
        title: '<strong>Flexible Learning Options for Freshers</strong>',
        description:
          "Crio's programs are fully online with a flexible schedule, making it easy to balance your learning with other commitments. You can expect to spend around 15 hours a week, and the schedule can adapt to suit your pace.",
      },
    ],
  },
  {
    message:
      'If you are interested in exploring the options or need personalized guidance, you can book a free trial with Crio, where a Crio representative will provide additional information on the programs and discuss any scholarship opportunities that might be available.',
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'normal',
    type: 'text',
  },
  {
    message: 'Book a free trial with Crio',
    sentTime: new Date().toISOString(),
    sender: 'bot',
    direction: 'incoming',
    position: 'last',
    type: 'callToAction',
    payload: {
      text: 'Book a free trial with Crio',
      link: 'https://crio.do/free-trial',
    },
  },
];
