export const generateTitle = (sessionName) => {
  return `Prepare for ${sessionName} -  Crio Masterclass Registration Confirmation`;
};

export const generateMetaDescription = (sessionName) => {
  return `You’re registered for ${sessionName}! Get event details, join our community, and add it to your calendar to ensure you don’t miss out on this engaging learning experience.`;
};

export const generateMeta = (eventCardSessionImage, eventSlug) => {
  return [
    {
      property: `og:image`,
      content: `https://strapi.internal.crio.do/uploads/${eventCardSessionImage}`,
    },
    {
      property: 'og:url',
      content: `https://www.crio.do/masterclass/${eventSlug}/registered_user/`,
    },
    {
      name: `twitter:image`,
      content: `https://strapi.internal.crio.do/uploads/${eventCardSessionImage}`,
    },
    {
      name: 'robots',
      content: 'index, follow',
    },
  ];
};

export const generateScript = (
  sessionName,
  program,
  sessionStartDateTime,
  eventSlug,
  whatsappGroupLink,
) => {
  const script = `{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Prepare for ${sessionName} | Crio Masterclass Registration Confirmation",
  "description": "Thank you for registering for ${sessionName}! Get all the details, add it to your calendar, and join our community to stay updated.",
  "url": "https://www.crio.do/masterclass/${eventSlug}/registered_user",
  "mainEntity": {
    "@type": "Event",
    "name": "${sessionName}",
    "description": "${sessionName} is a free session focused on ${program}. Prepare for this hands-on learning experience with Crio.",
    "startDate": "${sessionStartDateTime}",
    "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode",
    "eventStatus": "https://schema.org/EventScheduled",
    "location": {
      "@type": "VirtualLocation",
      "url": "https://www.crio.do/masterclass/${eventSlug}/registered_user"
    },
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "INR",
      "availability": "https://schema.org/InStock"
    },
    "organizer": {
      "@type": "Organization",
      "name": "Crio",
      "url": "https://www.crio.do"
    }
  },
  "potentialAction": [
    {
      "@type": "JoinAction",
      "target": {
        "@type": "EntryPoint",
        "urlTemplate": "https://www.crio.do/masterclass/${eventSlug}/registered_user"
      },
      "name": "Join the Event"
    },
    {
      "@type": "CommunicateAction",
      "target": {
        "@type": "EntryPoint",
        "urlTemplate": "${whatsappGroupLink}"
      },
      "name": "Join WhatsApp Group"
    }
`;
  return script;
};
