import Button from '@components/Button/index';
// import AwesomeConfetti from '@components/Confetti/index';
import useResizer from '@components/extra/useResizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '@src/context/GlobalContextProvider';
import { format } from 'date-fns';
import { navigate } from 'gatsby'; //import navigate from gatsby
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { GTM } from '../../analytics/gtm';
import { gtmEvents } from '../../analytics/gtmEventsConstant';
import countries from './country-options';
import db from '../../data/db.json';
import * as styles from './DoubleScreenPopupWithMobileAndCouponCode.module.scss';
import { ESale } from '@src/constants/sale/index';

function DoubleScreenPopupWithMobileAndCouponCode(props) {
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const isMobile = useResizer();
  const sale = props.sale;

  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
  } = useForm({
    mode: 'all',
  });

  const [submitted, setSubmitted] = useState(false);
  const program = db.strapi_metadata.programs.find(
    (ele) => ele.PrimaryProgramID == 'FREE_TRIAL',
  );

  function onSubmit(data) {
    GTM.login({
      userId: data.email,
      email: data.email,
      phone: '+' + String(data.countryCode) + String(data.phone),
    });
    // window.webengage && webengage.user.setAttribute('we_whatsapp_opt_in', true); //WhatsApp

    //GTM Event for Email Capture
    if (!state.isEmailEventSubmitted) {
      GTM.track(gtmEvents.EMAIL_SUBMITTED, {
        type: 'EMAIL_CAPTURE_POPUP',
        variant: 'VARIANT_' + props.variant,
      });
      dispatch({
        type: 'SET_IS_EMAIL_EVENT_SUBMITTED',
        payload: true,
      });
    }
    if (process.env.GATSBY_ENV == 'production') {
      fetch(
        `https://script.google.com/macros/s/AKfycbzZu0sfE3ZdlifBokz_l_ei9duEq9YZ3mEN8mf5cDsM8JZyF-2_Et7aiwRW7i7jnQ-6/exec?endpoint=website-leads&email=${
          data.email
        }&country=${state.country}&type=EMAIL_CAPTURE_POPUP&location=${
          'VARIANT_' + props.variant
        }&utm=${encodeURIComponent(state.utm)}&adblocker=${
          typeof window.webengage == 'undefined' ? true : false
        }&url=${window.location.href}`,
      );
    }
    dispatch({
      type: 'SET_EMAIL',
      payload: data.email,
    });
    GTM.setEmail(data.email);
    setSubmitted(true);
  }
  var now = new Date();
  var current = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  if (sale === ESale.DIWALI_SALE) {
    return (
      <div
        className={`${styles.modal} bg-diwali-sale-purple-900 rounded-3xl p-8 md:py-12`}
      >
        <div
          className={`${styles.cross} bg-diwali-sale-neutral-200 text-reclaim-primary-400 -mt-6 -mr-4 flex h-6 w-6 items-center justify-center rounded-full opacity-50 md:mr-0 md:-mt-8`}
          onClick={() => {
            props.closeModal();
          }}
        >
          <FontAwesomeIcon
            icon={['fal', 'times']}
            style={{
              color: '#000',
            }}
            size="1x"
          />{' '}
        </div>
        <div className="flex flex-col items-center text-center">
          {!submitted ? (
            <>
              <h4 className="customFont text-diwali-sale-orange-300 mb-4 text-3xl">
                Earn upto Rs. 16,000 scholarship
              </h4>
              <p className="text-diwali-sale-neutral-300 mb-4 text-base md:mb-8">
                Unlock an extra Rs. 1,000 off over and above the Rs. 15,000
                scholarship for our signature Full Stack and Backend Developer
                Programs
              </p>
              <div className="">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-5">
                    <div className="mb-3 ">
                      <input
                        className={`${styles.email} ${
                          errors.email ? 'border-2 border-red-400' : ''
                        } bg-diwali-sale-purple-500 rounded-xl text-white md:min-w-[450px]`}
                        type="email"
                        placeholder="Enter Email Address"
                        name="email"
                        defaultValue={state.email}
                        {...register('email', {
                          required: true,
                        })}
                        // ref={register({ required: true })}
                      ></input>
                      {errors.email && (
                        <p className="text-left text-sm font-light text-red-400">
                          Please enter a valid email address
                        </p>
                      )}
                    </div>
                    <div className="mb-3">
                      <div className=" flex flex-wrap md:flex-nowrap">
                        <select
                          className={`${styles.countryCode} bg-diwali-sale-purple-500 rounded-xl text-white`}
                          name="countryCode"
                          {...register('countryCode', {
                            required: true,
                          })}
                          // ref={register({ required: true })}
                          defaultValue={91}
                        >
                          {countries.map((country, idx) => country)}
                        </select>
                        <input
                          className={`${styles.email} ${
                            errors.phone ? 'border-2 border-red-400' : ''
                          } bg-diwali-sale-purple-500 rounded-xl text-white`}
                          type="tel"
                          placeholder="Enter Phone Number"
                          name="phone"
                          {...register('phone', {
                            required: true,
                            pattern: /^\d{10}$/i,
                          })}
                          // ref={register({
                          //   required: true,
                          //   pattern: /^\d{10}$/i,
                          // })}
                        ></input>
                      </div>
                      {errors.phone && (
                        <p className="text-left text-sm font-light text-red-400">
                          Please enter a valid phone number
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <input
                      type="submit"
                      className={`cursor-pointer rounded-lg p-3  ${
                        formState.isValid
                          ? 'bg-crio-yellow-500 hover:bg-yellow-400'
                          : 'bg-gray-300 text-gray-600'
                      } `}
                      value="Unlock promo code"
                    ></input>
                  </div>
                </form>
              </div>
            </>
          ) : (
            <div>
              <h4 className="customFont text-diwali-sale-orange-300 mb-4 text-3xl">
                Promo Code Unlocked!
              </h4>
              <p className="text-diwali-sale-neutral-300 mb-6">
                Book a free trial using the promo code. Earn upto Rs. 16,000
                scholarship to <div className="hidden md:block"></div>Crio's
                signature Full-Stack and Backend programs by completing the
                trial.
              </p>
              <div className="flex flex-col items-center justify-center">
                <div className={`${styles.coupon} mb-7`}>
                  <h3>{props.coupon}</h3>
                </div>
                <Button
                  className="mb-3 text-center font-semibold"
                  // background="#E9C37A"
                  color="#05343A"
                  // hoverbackground="#FFB533"
                  onClick={(e) => {
                    props.closeModal();
                    navigate('/registration', {
                      state: {
                        link: program.typeform,
                        type: program.ProgramID,
                        location: 'POPUP',
                        program: program,
                      },
                    });
                  }}
                  style={{
                    background:
                      'linear-gradient(107.15deg, #FFC772 -9.95%, #FF980E 108.2%)',
                  }}
                >
                  Book Your Trial, Now
                  <FontAwesomeIcon
                    icon={['fas', 'arrow-up']}
                    className="ml-2 rotate-45"
                    style={{ marginBottom: '2px' }}
                  ></FontAwesomeIcon>
                </Button>
                <p className="text-diwali-sale-neutral-300 text-xs font-light">
                  (The promo code has also been sent to your email ID and will
                  expire on {format(new Date(current), 'dd MMM, yyyy')})
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (sale === ESale.CYBER_SALE) {
    return (
      <div
        className={`${styles.modal} bg-cyber-sale-green-900 rounded-3xl p-8 md:py-12`}
      >
        <div
          className={`${styles.cross} bg-diwali-sale-neutral-200 text-reclaim-primary-400 -mt-6 -mr-4 flex h-6 w-6 items-center justify-center rounded-full opacity-50 md:mr-0 md:-mt-8`}
          onClick={() => {
            props.closeModal();
          }}
        >
          <FontAwesomeIcon
            icon={['fal', 'times']}
            style={{
              color: '#000',
            }}
            size="1x"
          />{' '}
        </div>
        <div className="flex flex-col items-center text-center">
          {!submitted ? (
            <>
              <h4 className="text-cyber-sale-lime-400 text-bold customFont mb-4 text-2xl">
                #RelaunchYourDevCareer this Cyber Monday!
              </h4>
              <p className="text-cyber-sale-neutral-100 mb-4 text-base md:mb-8">
                Earn up to Rs. 30,000 scholarship to learn in-demand
                <br className="hidden md:block" /> Full-Stack and Backend Dev
                Skills.
              </p>
              <div className="">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-5">
                    <div className="mb-3 ">
                      <input
                        className={`${styles.email} ${
                          errors.email ? 'border-2 border-red-400' : ''
                        } bg-cyber-sale-green-800 rounded-xl text-white md:min-w-[450px]`}
                        type="email"
                        placeholder="Enter Email Address"
                        name="email"
                        defaultValue={state.email}
                        {...register('email', {
                          required: true,
                        })}
                        // ref={register({ required: true })}
                      ></input>
                      {errors.email && (
                        <p className="text-left text-sm font-light text-red-400">
                          Please enter a valid email address
                        </p>
                      )}
                    </div>
                    <div className="mb-3">
                      <div className=" flex flex-wrap md:flex-nowrap">
                        <select
                          className={`${styles.countryCode} bg-cyber-sale-green-800 rounded-xl text-white`}
                          name="countryCode"
                          {...register('countryCode', {
                            required: true,
                          })}
                          // ref={register({ required: true })}
                          defaultValue={91}
                        >
                          {countries.map((country, idx) => country)}
                        </select>
                        <input
                          className={`${styles.email} ${
                            errors.phone ? 'border-2 border-red-400' : ''
                          } bg-cyber-sale-green-800 rounded-xl text-white`}
                          type="tel"
                          placeholder="Enter Phone Number"
                          name="phone"
                          {...register('phone', {
                            required: true,
                            pattern: /^\d{10}$/i,
                          })}
                          // ref={register({
                          //   required: true,
                          //   pattern: /^\d{10}$/i,
                          // })}
                        ></input>
                      </div>
                      {errors.phone && (
                        <p className="text-left text-sm font-light text-red-400">
                          Please enter a valid phone number
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <input
                      type="submit"
                      className={`cursor-pointer rounded-lg p-3  ${
                        formState.isValid
                          ? 'bg-crio-yellow-500 hover:bg-yellow-400'
                          : 'bg-gray-300 text-gray-600'
                      } `}
                      value="Unlock promo code"
                    ></input>
                  </div>
                </form>
              </div>
            </>
          ) : (
            <div>
              <h4 className="customFont text-diwali-sale-orange-300 mb-4 text-3xl">
                Promo Code Unlocked!
              </h4>
              <p className="text-diwali-sale-neutral-300 mb-6">
                Book a free trial using the promo code. Earn upto Rs. 30,000
                scholarship to <div className="hidden md:block"></div>Crio's
                signature Full-Stack and Backend programs by completing the
                trial.
              </p>
              <div className="flex flex-col items-center justify-center">
                <div className={`${styles.coupon} mb-7`}>
                  <h3>{props.coupon}</h3>
                </div>
                <Button
                  className="mb-3 text-center font-semibold"
                  // background="#E9C37A"
                  color="#05343A"
                  // hoverbackground="#FFB533"
                  onClick={(e) => {
                    props.closeModal();
                    navigate('/registration', {
                      state: {
                        link: program.typeform,
                        type: program.ProgramID,
                        location: 'POPUP',
                        program: program,
                      },
                    });
                  }}
                  style={{
                    background:
                      'linear-gradient(107.15deg, #FFC772 -9.95%, #FF980E 108.2%)',
                  }}
                >
                  Book Your Trial, Now
                  <FontAwesomeIcon
                    icon={['fas', 'arrow-up']}
                    className="ml-2 rotate-45"
                    style={{ marginBottom: '2px' }}
                  ></FontAwesomeIcon>
                </Button>
                <p className="text-diwali-sale-neutral-300 text-xs font-light">
                  (The promo code has also been sent to your email ID and will
                  expire on {format(new Date(current), 'dd MMM, yyyy')})
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.modal}>
      <div
        className={styles.cross}
        onClick={() => {
          props.closeModal();
        }}
      >
        <FontAwesomeIcon
          icon={['fal', 'times']}
          style={{
            color: '#000',
          }}
          size="1x"
        />{' '}
      </div>
      {/* {isMobile && submitted && (
        <AwesomeConfetti
          particleCount={5}
          angle={90}
          spread={55}
          startVelocity={50}
          colors={['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a']}
        />
      )} */}

      {/* {!isMobile && submitted && (
        <>
          <AwesomeConfetti
            particleCount={40}
            angle={60}
            spread={55}
            startVelocity={70}
            origin={{ x: 0 }}
            colors={['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a']}
          />
          <AwesomeConfetti
            particleCount={40}
            startVelocity={70}
            angle={120}
            spread={55}
            origin={{ x: 1 }}
            colors={['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a']}
          />
        </>
      )} */}

      <div className="flex flex-col items-center text-center">
        {!submitted ? (
          <>
            <h4 className="mb-4 text-crio-green-200">
              Earn upto Rs. 16,000 scholarship
            </h4>
            <p className="mb-4 font-light">
              Unlock an extra Rs. 1,000 off over and above the Rs. 15,000
              scholarship for our signature Full Stack and Backend Developer
              Programs
            </p>
            <div className="">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-5">
                  <div className="mb-3 ">
                    <input
                      className={`${styles.email} ${
                        errors.email ? 'border-2 border-red-400' : ''
                      }`}
                      type="email"
                      placeholder="Enter Email Address"
                      name="email"
                      defaultValue={state.email}
                      {...register('email', {
                        required: true,
                      })}
                      // ref={register({ required: true })}
                    ></input>
                    {errors.email && (
                      <p className="text-left text-sm font-light text-red-400">
                        Please enter a valid email address
                      </p>
                    )}
                  </div>
                  <div className="mb-3">
                    <div className=" flex flex-wrap md:flex-nowrap">
                      <select
                        className={styles.countryCode}
                        name="countryCode"
                        {...register('countryCode', {
                          required: true,
                        })}
                        // ref={register({ required: true })}
                        defaultValue={91}
                      >
                        {countries.map((country, idx) => country)}
                      </select>
                      <input
                        className={`${styles.email} ${
                          errors.phone ? 'border-2 border-red-400' : ''
                        }`}
                        type="tel"
                        placeholder="Enter Phone Number"
                        name="phone"
                        {...register('phone', {
                          required: true,
                          pattern: /^\d{10}$/i,
                        })}
                        // ref={register({ required: true, pattern: /^\d{10}$/i })}
                      ></input>
                    </div>
                    {errors.phone && (
                      <p className="text-left text-sm font-light text-red-400">
                        Please enter a valid phone number
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex justify-center">
                  <input
                    type="submit"
                    className={`cursor-pointer rounded-lg p-3  ${
                      formState.isValid
                        ? 'bg-crio-yellow-500 hover:bg-yellow-400'
                        : 'bg-gray-300 text-gray-600'
                    } `}
                    // onClick={handleSubmit}
                    value="Unlock promo code"
                  ></input>
                </div>
              </form>
            </div>
          </>
        ) : (
          <div>
            <h4 className="mb-4 text-crio-green-200">Promo Code Unlocked!</h4>
            <p className="mb-6 font-light">
              Book a free trial using the promo code. Earn upto Rs. 16,000
              scholarship to <div className="hidden md:block"></div>Crio's
              signature Full-Stack and Backend programs by completing the trial.
            </p>
            <div className="flex flex-col items-center">
              <div
                className={`${styles.coupon} mb-7`}
                // onClick={() => copyToClipBoard(coupon)}
              >
                <h3>{props.coupon}</h3>
              </div>
              <Button
                className="mb-3 text-center font-semibold"
                background="#E9C37A"
                color="#05343A"
                // width={isMobile ? "350px" : "max-content"}
                hoverbackground="#FFB533"
                onClick={(e) => {
                  props.closeModal();
                  navigate('/registration', {
                    state: {
                      link: program.typeform,
                      type: program.ProgramID,
                      location: 'POPUP',
                      program: program,
                    },
                  });
                }}
                style={{
                  background:
                    'linear-gradient(107.15deg, #FFC772 -9.95%, #FF980E 108.2%)',
                }}
              >
                Book Your Trial, Now
                <FontAwesomeIcon
                  icon={['fas', 'arrow-up']}
                  className="ml-2 rotate-45"
                  style={{ marginBottom: '2px' }}
                ></FontAwesomeIcon>
              </Button>
              <p className="text-xs font-light">
                (The promo code has also been sent to your email ID and will
                expire on {format(new Date(current), 'dd MMM, yyyy')})
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DoubleScreenPopupWithMobileAndCouponCode;
