import React from 'react';

export const ChatRedirectButton = ({ text, link }) => {
  const handleRedirect = () => {
    window.open(link, '_blank', 'noopener noreferrer');
  };

  return (
    <div className="-mx-4 p-2">
      <button
        onClick={handleRedirect}
        className="w-full rounded-lg bg-[#75B612] px-4 py-3 text-center text-sm font-semibold text-black transition-colors duration-200 "
      >
        {text}
      </button>
    </div>
  );
};
