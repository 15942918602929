import React from 'react';
import Divider from '../Divider';
import MobileInviteLink from '../MobileInviteLink';
import useRegisterMetadata from '@src/hooks/Masterclass/useRegisterMetadata';
import QRCodeSection from '../Qr';
import JoinWhatsappButton from '../Join';

export default function WhatsappInvite() {
  const { getEventDetails } = useRegisterMetadata();
  const { shareInfo } = getEventDetails();

  return (
    <>
      {/* Desktop View: QR Code & Join Button */}
      <div className="hidden items-center gap-x-8 lg:flex">
        <QRCodeSection whatsappGroupLink={shareInfo.whatsappGroupLink} />
        <Divider text="or" color="bg-v5-neutral-300" />
        <MobileInviteLink />
      </div>

      {/* Mobile View: Only show join button */}
      <JoinWhatsappButton whatsappGroupLink={shareInfo.whatsappGroupLink} />
    </>
  );
}
