import Layout from '@components/v5/layouts/Layout';
import React from 'react';
import DesktopHero from './DesktopHero';
import MobileHero from './MobileHero';

export default function PostRegisterHero() {
  return (
    <Layout className="container pt-28">
      <DesktopHero className="hidden lg:block" />
      <MobileHero className="block lg:hidden" />
    </Layout>
  );
}
