import React, { useRef } from 'react';
import LeadGenerationOpenForm from '@components/LeadGenerationForm/LeadGenerationOpenForm';

import { GatsbyImage } from 'gatsby-plugin-image';
import useRegisterMetadata from '@src/hooks/Masterclass/useRegisterMetadata';

export default function EventRegistrationHero() {
  const { getBannerImage, getEventDetails, onSubmitL2, onSubmitOtp } =
    useRegisterMetadata();

  const { isPastEvent } = getEventDetails();

  const thankYouUrlRef = useRef(null);

  const handleSubmitL2 = async (payload) => {
    try {
      const thankYouUrl = await onSubmitL2(payload);
      thankYouUrlRef.current = thankYouUrl;
      return thankYouUrl;
    } catch (error) {
      console.error('Error in L2 submission:', error);
    }
  };

  const handleSubmitOtp = async () => {
    try {
      if (thankYouUrlRef.current) {
        await onSubmitOtp(thankYouUrlRef.current);
      } else {
        await onSubmitOtp();
      }
    } catch (error) {
      console.error('Error in OTP submission:', error);
    }
  };

  return (
    <div className={`w-screen pt-20 md:pt-8`}>
      <div className="shadow-[-1px_23px_25.9px_-21px_#0000001A] md:pt-8 ">
        <GatsbyImage
          image={getBannerImage()}
          alt="Crio Masterclass"
          className="h-full w-full"
        />
        {/* Desktop Lead Gen Open Form: Scrollable and sticky */}
        <div className="absolute right-[8%] top-0 hidden h-[calc(100%-200px)] w-screen justify-end lg:flex">
          <LeadGenerationOpenForm
            isOpenForm={true}
            pageTwoButtonText={isPastEvent ? 'Watch Now' : 'Register Now'}
            applicationPage="masterclass"
            onSubmitL2={handleSubmitL2}
            onSubmitPageTwo={handleSubmitOtp}
            openFormClassName={'md:w-[30vw]'}
            wrapperClassName={'lg:sticky top-24 h-[450px] z-[1000]'}
          />
        </div>
      </div>

      {/* Mobile view lead gen open form: */}
      <LeadGenerationOpenForm
        isOpenForm={true}
        pageTwoButtonText={isPastEvent ? 'Watch Now' : 'Register Now'}
        applicationPage="masterclass"
        onSubmitL2={handleSubmitL2}
        onSubmitPageTwo={handleSubmitOtp}
        wrapperClassName={`lg:hidden container pt-9`}
        openFormClassName={'mx-auto'}
      />
    </div>
  );
}
