import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '@src/context/GlobalContextProvider';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { GTM } from '../../../analytics/gtm';
import { gtmEvents } from '../../../analytics/gtmEventsConstant';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import { twMerge } from 'tailwind-merge';
import { useInView } from 'react-intersection-observer';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
// import { useDialog } from '@components/v5/Dialog/index';

export default function ApplyNowCTAV3({ as, ...props }) {
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const [isOpen, setIsOpen] = useState(false);
  const [ref, inView] = useInView();
  // const [openDialog, closeDialog] = useDialog();

  // PopOver variables
  const [popoverAnchorElement, setPopoverAnchorElement] = useState(null);
  const [popoverEmail, setPopoverEmail] = useState('');
  const popoverOpen = Boolean(popoverAnchorElement);
  const popoverId = popoverOpen ? 'register-popover' : undefined;

  useEffect(() => {
    if (isOpen) {
      ref?.current?.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!inView) {
      setIsOpen(false);
      dispatch({
        type: 'USER_IS_APPLYING',
        payload: false,
      });
    }
  }, [inView]);

  useEffect(() => {
    if (props?.open === true || props?.open === false) {
      setIsOpen(props.open);
    }
  }, [props?.open]);

  const handleCTAClick = (e) => {
    let event = props.openEvent;
    GTM.track(gtmEvents[event], {
      type: props.type,
      meId: props.meId,
      location: props.location,
    });

    setTimeout(() => {
      dispatch({
        type: 'USER_IS_APPLYING',
        payload: true,
      });
    }, 500);

    if (state.email.length > 0) {
      GTM.login({
        userId: state.email,
        email: state.email,
      });

      if (props.overrideSubmit) {
        props.overrideSubmit(state.email);
        if (state.registrationDetails && state.registrationDetails[hash]) {
          navigate(`/registered/?id=${state.registrationDetails[hash].id}`);
        } else {
          if (props.link) {
            navigate('/registration', {
              state: {
                link: props.link,
                type: props.type,
                location: props.location,
                program: props.program,
              },
            });
          } else {
            if (!props.showPopOver) setIsOpen(false);
          }
        }
        return;
      }

      //GTM Event for Email Capture
      if (!state.isEmailEventSubmitted) {
        GTM.track(gtmEvents.EMAIL_SUBMITTED, {
          type: props.type,
          location: props.location,
        });
        dispatch({
          type: 'SET_IS_EMAIL_EVENT_SUBMITTED',
          payload: true,
        });
      }

      let hash = btoa(props.type + props.link);

      if (props.onSubmit) {
        props.onSubmit();
      }

      if (state.registrationDetails && state.registrationDetails[hash]) {
        navigate(`/registered/?id=${state.registrationDetails[hash].id}`);
      } else {
        if (props.link) {
          navigate('/registration', {
            state: {
              link: props.link,
              type: props.type,
              location: props.location,
              program: props.program,
            },
          });
        } else {
          if (!props.showPopOver) setIsOpen(false);
        }
      }
    } else {
      if (!props.showPopOver) {
        if (props?.onOpen) {
          props?.onOpen();
          return;
        }
        setIsOpen(true);
        if (props.isOpen) props.isOpen();
      } else {
        setPopoverAnchorElement(e.currentTarget);
      }
    }
  };

  const onFormSubmit = (e) => {
    let email = e.target.email.value;
    e.preventDefault();

    dispatch({
      type: 'SET_EMAIL',
      payload: email,
    });
    GTM.setEmail(email);

    GTM.login({
      userId: email,
      email: email,
    });
    GTM.emailLead(email);

    // TODO: Check for this prop
    if (props.overrideSubmit) {
      props.overrideSubmit(email);
      if (props.link) {
        navigate('/registration', {
          state: {
            link: props.link,
            type: props.type,
            location: props.location,
            program: props.program,
          },
        });
      } else {
        if (!props.showPopOver) setIsOpen(false);
      }
      return;
    }

    //GTM Event for Email Capture
    if (!state.isEmailEventSubmitted) {
      GTM.track(gtmEvents.EMAIL_SUBMITTED, {
        type: props.type,
        location: props.location,
      });
      dispatch({
        type: 'SET_IS_EMAIL_EVENT_SUBMITTED',
        payload: true,
      });
    }

    if (process.env.GATSBY_ENV == 'production') {
      fetch(
        `https://script.google.com/macros/s/AKfycbzZu0sfE3ZdlifBokz_l_ei9duEq9YZ3mEN8mf5cDsM8JZyF-2_Et7aiwRW7i7jnQ-6/exec?endpoint=website-leads&email=${email
          .trim()
          .toLowerCase()}&country=${state.country}&type=${
          props.type
        }&location=${props.location}&utm=${encodeURIComponent(
          state.utm,
        )}&adblocker=${
          typeof window.webengage == 'undefined' ? true : false
        }&url=${window.location.href}`,
      );
    }

    if (props.onSubmit) {
      props.onSubmit(email);
    }
    if (props.link) {
      navigate('/registration', {
        state: {
          link: props.link,
          type: props.type,
          location: props.location,
          program: props.program,
        },
      });
    } else {
      if (!props.showPopOver) setIsOpen(false);
    }
  };

  const onCTAClose = () => {
    dispatch({
      type: 'USER_IS_APPLYING',
      payload: false,
    });

    // handle inline/popover CTA close
    if (!props.showPopOver) {
      if (props.isClosed) props.isClosed();
      if (props?.onClose) {
        props?.onClose();
        return;
      }
      setIsOpen(false);

      dispatch({
        type: 'USER_IS_APPLYING',
        payload: false,
      });
    } else {
      setPopoverAnchorElement(null);
    }

    let event = props.closeEvent;
    GTM.track(gtmEvents[event], {
      type: props.type,
      meId: props.meId,
      location: props.location,
    });
  };

  // PopOver related functions
  const handleEmailChange = (event) => {
    setPopoverEmail(event.target.value);
  };

  const validateEmail = () => {
    let re = /\S+@\S*\S+/;
    return re.test(popoverEmail);
  };

  // show popOver UI instead of inline CTAV3 component
  if (props.showPopOver) {
    return (
      <>
        <Popover
          PaperProps={{
            style: { borderRadius: 12 },
          }}
          elevation={2}
          id={popoverId}
          open={popoverOpen}
          disableScrollLock={true}
          anchorEl={popoverAnchorElement}
          onClose={onCTAClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <form autoComplete="off" onSubmit={onFormSubmit}>
            <Box
              className={twMerge(
                `flex flex-col justify-center gap-3 rounded-xl py-4 px-5 `,
              )}
            >
              <FontAwesomeIcon
                className="ml-auto cursor-pointer text-gray-400"
                icon="fa-solid fa-xmark"
                onClick={onCTAClose}
              />
              <TextField
                id="email"
                label="Enter your email ID"
                variant="outlined"
                className={twMerge(
                  `h-42px placeholder:gray-slate-400 w-72 flex-1 rounded-[10px] border border-gray-200 bg-white text-black`,
                )}
                type="email"
                name="email"
                required
                color="yellow"
                size="normal"
                autoFocus={true}
                fullWidth={true}
                onChange={handleEmailChange}
                value={popoverEmail}
              />
              <Button
                className={`${
                  !validateEmail() ? 'cursor-not-allowed opacity-30' : ''
                } natural sm ml-auto flex items-center whitespace-nowrap rounded-[10px] border-2 border-v5-yellow-200 bg-v5-yellow-200 px-4 py-2 font-manrope text-sm font-bold capitalize text-black hover:bg-v5-yellow-100`}
                type="submit"
                variant="contained"
                disabled={!validateEmail}
              >
                {props.innerText || 'Proceed to Book'}
              </Button>
            </Box>
          </form>
        </Popover>
        <Button
          className="natural sm flex items-center whitespace-nowrap rounded-[10px] border-2 border-v5-yellow-200 bg-v5-yellow-200  px-4 text-base font-bold capitalize text-black hover:bg-v5-yellow-100"
          type="contained"
          aria-describedby={popoverId}
          onClick={handleCTAClick}
        >
          {props.text || 'Book Your Trial, Now'}
          <FontAwesomeIcon
            icon={['fas', 'angle-right']}
            className="ml-2 mt-1 text-xl"
            style={{ marginBottom: '2px' }}
          ></FontAwesomeIcon>
        </Button>
      </>
    );
  }

  return (
    <div className=" flex justify-center" ref={ref}>
      {isOpen ? (
        <div className="flex items-center gap-x-2">
          <form
            className={twMerge(
              `flex items-stretch justify-center  text-black md:flex-nowrap`,
            )}
            style={{ height: props.height ? props.height : '56px' }}
            onSubmit={onFormSubmit}
          >
            <InputBase
              sx={{ p: 1 }}
              inputProps={{ 'aria-label': 'Apply to Crio' }}
              color="yellow"
              size="normal"
              className={twMerge(
                `border-r-none h-full min-h-[56px] flex-1 rounded-l-[10px] rounded-r-none border border-gray-200 bg-white  text-black ${props.inputClassName}`,
              )}
              ref={ref}
              id="filled-basic"
              autoFocus
              placeholder="Email"
              type="email"
              name="email"
              required
            />

            <Button
              size="small"
              type="contained"
              className={twMerge(
                `subtitle1  h-full min-h-[56px] whitespace-nowrap  rounded-l-none rounded-r-[10px] px-2 font-manrope text-sm font-bold capitalize text-black ${props.applyNowInsideClassName}`,
              )}
            >
              {props.innerText || 'Apply Now'}
            </Button>
          </form>

          <div className="cursor-pointer" onClick={onCTAClose}>
            <FontAwesomeIcon
              icon={['fas', 'times']}
              className="ml-2 text-gray-400"
            ></FontAwesomeIcon>
          </div>
        </div>
      ) : (
        <>
          {as ? (
            React.cloneElement(as, {
              onClick: handleCTAClick,
            })
          ) : (
            <Button
              disableRipple
              type="contained"
              className={`font-manrope  ${props.className} natural md flex items-center text-center font-bold capitalize`}
              onClick={handleCTAClick}
              style={{
                minWidth: props.width ? props.width : '200px',
              }}
            >
              {props.text ? props.text : 'Apply Now'}
              <FontAwesomeIcon
                icon={['fas', 'angle-right']}
                className="ml-2"
                style={{ fontSize: '17px' }}
              ></FontAwesomeIcon>
            </Button>
          )}
        </>
      )}
    </div>
  );
}
ApplyNowCTAV3.propTypes = {
  openEvent: PropTypes.string.isRequired,
};
