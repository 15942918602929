import { graphql } from 'gatsby';
import SEO from '@components/Common/SEO/index';
import '@fortawesome/fontawesome-svg-core/styles.css';
import React, { useContext, useEffect, useState } from 'react';
import FooterV2 from '@components/FooterV2/index';
import CrackedCrioCareersSection from '@components/v5/CrackedCrioCareersSection/index';
import ProjectsSection from '@components/v5/ProjectsSection/index';
import VideoTestimonialSection from '@components/v5/VideoTestimonialSection/index';
import CrioComparisionSection from '@components/v5/CrioComparisionSection/index';
import FAQSection from '@components/v5/FAQSection/index';
import WhyCrioSection from '@components/v5/WhyCrioSection/index';
import CrioButton from '@components/v5/CrioButton/index';
import PostContentCTALayout from '@components/v5/layouts/PostContentCTALayout/index';
import { Link } from 'gatsby';
import { ERoutes } from '@src/constants/routes/index';
import { EProgramIDs } from '@src/constants/data/programs/index';
import SaleMiddleStrip from '@components/v5/Sale/SaleMiddleStrip';
import { stripData } from '@components/Sale/data';
// import FspStylePageHero from '@components/v5/FspStylePageHero/index';
import StatsPanelFSP from '@components/v5/StatsPanelFSP/index';
import FspPricingCardSection from '@components/v5/FspPricingCardSection/index';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import {
  LEAD_GEN_HOME_PROJECTS,
  LEAD_GEN_FLOATING_CTA,
  LEAD_GEN_DEVELOPERS_CRACKED,
  LEAD_GEN_PROJECTS,
  LEAD_GEN_CAREER_ASSISTANCE,
  LEAD_GEN_TESTIMONIALS,
  LEAD_GEN_CRIO_COMPARISION,
  LEAD_GEN_FAQ,
  LEAD_GEN_CURRICULUM_PRICING_CARD,
  LEAD_GEN_WHY_CRIO,
} from '../constants/LeadGenerationConstants/index';
import ScrollButtonComponent from '@components/ScrollButtonComponent/index';
import { useWebEngage } from '@src/hooks/useWebEngage';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';
import { GTM } from '@src/analytics/gtm';
import TechnologiesMasterSection from '@components/v5/TechnologiesMasterSection/index';
import fsp from '../seo/jsonld/fsd.json';
import fdt from '../seo/jsonld/fdt.json';
import bdt from '../seo/jsonld/bdt.json';
import dataScienceJsonLd from '../seo/jsonld/data-science.json';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import useCrioStat from '@src/hooks/useCrioStats';
import useDataScienceProgram from '@src/hooks/useDataScienceProgram';
import PPCNavbar from '@components/PPCNavbar';
import CurriculumV4 from '@components/v5/CurriculumV4';
import WhyCrioSectionV3 from '@components/v5/WhyCrioSectionV3';
import SkillSection from '@components/v5/SkillSection';
import { curriculumData } from '@src/constants/fsdCurriculumData';
import { accelerateCurriculumData } from '@src/constants/accelerateCurriculumData';
import CustomHeroSection from '@components/CustomHeroSection';
import BottomStrip from '@components/PPCNavbar/BottomStrip';
import MentorTestimonialsSection from '@components/MentorSection';
import { useLocation } from '@reach/router';
import FormSubmitButton from '@components/LeadGenerationForm/FormComponents/FormSubmitButton';
import useProvisionalEnrollment from '@src/hooks/useProvisionalEnrollment';
import useAffiliate from '@src/hooks/useAffiliate';
import AffiliateSuccessPopup from '@components/Popups/AffiliateSuccessPopup';
import useSendActivities from '@src/hooks/useSendActivities';

export default function FspStylePpcPage(props) {
  const { captureUserClickEvent } = useWebEngage();
  const location = useLocation();

  const isAcceleratePage =
    location?.pathname === '/provisional-enrollment/accelerate/';
  console.log('🚀 ~ FspStylePpcPage ~ pathName:', location);
  const { isAffiliatePage } = useAffiliate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const state = useContext(GlobalStateContext);

  const shouldShowAffiliatePagePopup = (shouldShowPopup = false) => {
    setIsPopupOpen(shouldShowPopup);
  };

  const { isDataSciencePage } = useDataScienceProgram();
  const { sendActivityHandler } = useSendActivities();

  const onPlacementButtonClick = () => {
    captureUserClickEvent('Get our Placement Stats', {
      placementLocation: 'FSP_PAGE',
    });

    sendActivityHandler(
      ELeadSquaredActivityCode.SD_PAGE_GET_OUR_PLACEMENT_STATS_CLICKED,
    );
  };
  const { data: queryData } = props;

  const { fspStylePpcPagesYaml: data } = queryData;
  const { isProvisionalEnrollment } = useProvisionalEnrollment();

  const program = props.data.allStrapiPrograms.nodes.find(
    (ele) => ele.PrimaryProgramID == data.PrimaryProgramID,
  );

  const programId = program?.PrimaryProgramID;
  const jsonLd =
    programId === EProgramIDs.DATA_SCIENCE
      ? dataScienceJsonLd
      : programId === EProgramIDs.FULL_STACK
      ? fdt
      : programId === EProgramIDs.FELLOWSHIP
      ? fsp
      : bdt;

  const sale = props.data.strapiSale.SaleID;

  const { placementStats } = useCrioStat();

  const getProjectsSectionPreContent = (sale) => {
    switch (sale) {
      case 'true':
        return (
          <div className="mx-4 mb-10 flex justify-center">
            <SaleMiddleStrip data={stripData[program.PrimaryProgramID]}>
              <LeadGenerationButton
                type="Book Your Trial, Now"
                buttonLocation={LEAD_GEN_HOME_PROJECTS}
              />
            </SaleMiddleStrip>
          </div>
        );

      default:
        return <></>;
    }
  };

  useEffect(() => {
    //webengage events specific to FSD Page(experiment):
    GTM.track(gtmEvents.PAGE_VIEW_FSD, {
      type: data.PrimaryProgramID,
      slug: data.slug,
    });

    //GA EVENT FOR PAGE VIEW:
    GTM.track(gtmEvents.PAGE_VIEW, {
      type: data.PrimaryProgramID,
      slug: data.slug,
    });
  }, []);

  return (
    <div id="top">
      <SEO
        title={data.SEOTitle}
        description={data.SEODescription}
        canonicalUrl={data.canonicalUrl}
        meta={data.metatags || []}
      >
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      </SEO>
      <ScrollButtonComponent
        component={
          <LeadGenerationButton
            type="Book Your Trial, Now"
            buttonLocation={LEAD_GEN_FLOATING_CTA}
          />
        }
        startAt={600}
      ></ScrollButtonComponent>
      <PPCNavbar />
      <div className="bg-white">
        <div className="text-v5-neutral-300">
          <CustomHeroSection
            data={{
              slug: 'default',
              pageUrl: 'FELLOWSHIP',
              heroStyles: null,
              sectionWrapperStyles: null,
              sectionOneStyles: null,
              sectionTwoStyles: null,
              formStyles: null,
              isInfoCardVisible: true,
              secondFold: isAcceleratePage
                ? '<p className="text-[14px] md:text-[30px]">  <strong>Crafted for Future Innovators </strong>: An Exclusive Program by Industry Experts to Prepare Undergrad Students for a Thriving Career in Software Development</p>'
                : '<p class="text-[14px] leading-[18px] font-medium md:mb-5 md:text-[24px] md:leading-8">The <strong class="font-bold">future</strong> of software development is driven by <strong class="font-bold">Generative AI</strong>. Master the essential skills of tomorrow, build <strong class="font-bold">real-world projects</strong>, and solve modern challenges with the power of AI.</p>',
              firstFold: isAcceleratePage
                ? '<h1 class="text-center font-manrope text-[28px] font-bold md:text-left md:text-[48px]">Crio.Do ACCELERATE</h1>'
                : '<h1 class="text-center font-manrope text-[28px] font-bold md:text-left md:text-[48px]">Fellowship Program in Software Development with GenAI</h1>',
              createdAt: '2024-10-27T17:04:23.525Z',
              updatedAt: '2024-10-28T04:07:09.072Z',
              publishedAt: '2024-10-27T17:04:24.681Z',
            }}
            props={{
              shouldShowAffiliatePagePopup: shouldShowAffiliatePagePopup,
              dark: isAcceleratePage,
              disableInfoLabelOne: isAcceleratePage,
            }}
          />
        </div>
        <AffiliateSuccessPopup
          isOpen={isPopupOpen}
          onClose={() => setIsPopupOpen(false)}
        />
        {isProvisionalEnrollment || isAffiliatePage ? (
          <div>
            <VideoTestimonialSection
              quote={
                isDataSciencePage ? (
                  <>
                    Get more insights on how Crio can help you{' '}
                    <br class="hidden sm:block" />
                    crack a data analytics role in top tech companies.
                  </>
                ) : (
                  data.testimonialQuote
                )
              }
              disableCareerHandbook={
                data.PrimaryProgramID === EProgramIDs.QA_AUTOMATION_TESTING
              }
              postContent={<ConfirmSeatButton />}
            ></VideoTestimonialSection>
            <CurriculumV4
              heading={''}
              description={''}
              quote={data.curriculumQuote}
              type={data.PrimaryProgramID}
              curriculum={
                isAcceleratePage
                  ? accelerateCurriculumData.curriculum
                  : curriculumData.curriculum
              }
              advancedCurriculum={
                isAcceleratePage
                  ? accelerateCurriculumData.advancedCurriculum
                  : curriculumData.advancedCurriculum
              }
              dreamCompanies={data.dreamCompanies}
              superDreamCompanies={data.superDreamCompanies}
              isSDPage
            />
            <div id="mentor-section" className="section-target"></div>
            <MentorTestimonialsSection />
            <div className="min-h-[800px] py-10">
              <ProjectsSection
                heading={data.projectsTitle}
                description={data.projectsDescriptionV2}
                projects={data.projects}
                preContent={getProjectsSectionPreContent(sale)}
              ></ProjectsSection>
            </div>
          </div>
        ) : (
          <>
            <div className="mt-10">
              <div id="curriculum" className="section-target"></div>
              <CurriculumV4
                heading={''}
                description={''}
                quote={data.curriculumQuote}
                type={data.PrimaryProgramID}
                curriculum={curriculumData.curriculum}
                advancedCurriculum={curriculumData.advancedCurriculum}
                dreamCompanies={data.dreamCompanies}
                superDreamCompanies={data.superDreamCompanies}
                isSDPage
              />
            </div>
            <div id="crio-success-stories" className="section-target"></div>
            <CrackedCrioCareersSection
              postContent={
                <PostContentCTALayout>
                  <div>
                    <div className="-mb-10">
                      <StatsPanelFSP
                        stats={placementStats.map((stat) => {
                          return {
                            title: stat.content,
                            subtitle: `${stat.label}`,
                          };
                        })}
                      ></StatsPanelFSP>
                    </div>
                    <PostContentCTALayout className="mb-10">
                      <Link to={ERoutes.PLACEMENTS}>
                        <CrioButton
                          variant="secondary"
                          onClick={onPlacementButtonClick}
                          className="h-[64px] w-[300px] border-v5-neutral-600 py-6 text-v5-neutral-600 sm:w-auto"
                        >
                          Get our Placement Stats
                        </CrioButton>
                      </Link>
                      <LeadGenerationButton
                        type="Book Your Trial, Now"
                        className="h-[64px] w-[300px]"
                        buttonLocation={LEAD_GEN_DEVELOPERS_CRACKED}
                      />
                    </PostContentCTALayout>
                  </div>
                </PostContentCTALayout>
              }
            ></CrackedCrioCareersSection>
            <SkillSection
              dreamCompanies={data.skills}
              heading={
                'Gain the competitive edge with mastery of essential development skills'
              }
              description={
                'Master leading tech skills like Java, React JS, MongoDB, Kafka'
              }
            />

            <div id="projects" className="section-target"></div>
            <div className="min-h-[800px] py-10">
              <ProjectsSection
                heading={data.projectsTitle}
                description={data.projectsDescriptionV2}
                projects={data.projects}
                postContent={
                  <PostContentCTALayout>
                    <LeadGenerationButton
                      type="Book Your Trial, Now"
                      buttonLocation={LEAD_GEN_PROJECTS}
                    />
                  </PostContentCTALayout>
                }
                preContent={getProjectsSectionPreContent(sale)}
              ></ProjectsSection>
            </div>

            {data.technologies ? (
              <TechnologiesMasterSection
                data={data.technologies}
                postContent={
                  <PostContentCTALayout>
                    <LeadGenerationButton
                      type="Book Your Trial, Now"
                      buttonLocation={'LEAD_GEN_TECHNOLOGIES'}
                    />
                  </PostContentCTALayout>
                }
              />
            ) : (
              <></>
            )}
            <div id="career-services" className="section-target"></div>
            <WhyCrioSection
              heading={data.careerTitleV2}
              description={data.careerDescription}
              data={data.careers}
              postContent={
                <PostContentCTALayout>
                  <LeadGenerationButton
                    type="Book Your Trial, Now"
                    buttonLocation={LEAD_GEN_CAREER_ASSISTANCE}
                  />
                </PostContentCTALayout>
              }
            ></WhyCrioSection>

            <FspPricingCardSection
              price={data.price}
              duration={data.priceDuration}
              sale={sale}
              program={program.PrimaryProgramID}
              postContent={
                <PostContentCTALayout>
                  <LeadGenerationButton
                    type="Book Your Trial, Now"
                    buttonLocation={LEAD_GEN_CURRICULUM_PRICING_CARD}
                  />
                </PostContentCTALayout>
              }
            ></FspPricingCardSection>
            <VideoTestimonialSection
              quote={
                isDataSciencePage ? (
                  <>
                    Get more insights on how Crio can help you{' '}
                    <br class="hidden sm:block" />
                    crack a data analytics role in top tech companies.
                  </>
                ) : (
                  data.testimonialQuote
                )
              }
              disableCareerHandbook={
                data.PrimaryProgramID === EProgramIDs.QA_AUTOMATION_TESTING
              }
              postContent={
                <PostContentCTALayout>
                  <div className="-mt-16 xxs:-mt-10 sm:-mt-0">
                    <LeadGenerationButton
                      type="Book Your Trial, Now"
                      buttonLocation={LEAD_GEN_TESTIMONIALS}
                    />
                  </div>
                </PostContentCTALayout>
              }
            ></VideoTestimonialSection>
            <WhyCrioSectionV3
              heading={data.highlightsTitle}
              description={data.highlightsDescription}
              quote={data.highlightsQuote}
              data={data.highlightsV2}
              postContent={
                <PostContentCTALayout>
                  <LeadGenerationButton
                    type="Book Your Trial, Now"
                    buttonLocation={LEAD_GEN_WHY_CRIO}
                  />
                </PostContentCTALayout>
              }
            ></WhyCrioSectionV3>
            <CrioComparisionSection
              postContent={
                <div className="-mt-12">
                  <PostContentCTALayout>
                    <LeadGenerationButton
                      type="Book Your Trial, Now"
                      buttonLocation={LEAD_GEN_CRIO_COMPARISION}
                    />
                  </PostContentCTALayout>
                </div>
              }
            ></CrioComparisionSection>
            <div id="faq" className="section-target"></div>
            <FAQSection
              data={data.faqs}
              postContent={
                <PostContentCTALayout>
                  <LeadGenerationButton
                    type="Book Your Trial, Now"
                    buttonLocation={LEAD_GEN_FAQ}
                  />
                </PostContentCTALayout>
              }
            ></FAQSection>
          </>
        )}
      </div>

      <FooterV2></FooterV2>
      <BottomStrip />
    </div>
  );
}

export const ConfirmSeatButton = () => {
  return (
    <div className="flex items-center justify-center">
      <a className="" href="#top">
        <FormSubmitButton text={'Confirm Your Seat'} className={''} />
      </a>
    </div>
  );
};

export const pageQuery = graphql`
  query ($id: String!) {
    fspStylePpcPagesYaml(id: { eq: $id }) {
      PrimaryProgramID
      slug
      SEOTitle
      canonicalUrl
      SEODescription

      heroTitle
      heroSubtitle
      heroDescription
      heroImage {
        childImageSharp {
          gatsbyImageData(
            width: 750
            quality: 100
            blurredOptions: {}
            placeholder: NONE
            formats: [AUTO, WEBP]
          )
        }
      }

      statsSubtitle

      highlightsTitle
      highlights {
        description
        image {
          childImageSharp {
            gatsbyImageData(
              width: 200
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }

      highlightsV2 {
        description
        image {
          childImageSharp {
            gatsbyImageData(
              width: 200
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }

      projectsTitle
      projectsDescription
      projectsDescriptionV2

      careerTitle
      careerTitleV2
      careerDescription
      careers {
        description
        image {
          childImageSharp {
            gatsbyImageData(
              width: 200
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }

      curriculumTitle
      curriculumDescription
      curriculumQuote

      dreamCompanies {
        image {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        originalImage {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      superDreamCompanies {
        image {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        originalImage {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      curriculum {
        title
        image {
          childImageSharp {
            gatsbyImageData(
              width: 100
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        duration
        tag
        data
        skills
        anchor
        cardTitle
        cards {
          image {
            childImageSharp {
              gatsbyImageData(
                width: 300
                quality: 100
                blurredOptions: {}
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
          data
          skills
          title
        }
      }

      curriculumfsd {
        basicCurriculumFSD {
          description
          duration
          title
          cards {
            description
            skills
            title
            subCards {
              description
              title
              skills
              spanToWidth
            }
          }
          image {
            childImageSharp {
              gatsbyImageData(
                width: 100
                quality: 100
                blurredOptions: {}
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
          subCards {
            description
            skills
            spanToWidth
            title
          }
          specializationType {
            numberOfCards
            title
          }
          description1
          description2
          skills
        }
        advancedCurriculumFSD {
          duration
          cards {
            description
            subCards {
              description
              skills
              spanToWidth
              title
              isCardLarge
            }
            title
            skills
          }
          image {
            childImageSharp {
              gatsbyImageData(
                width: 100
                quality: 100
                blurredOptions: {}
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
          title
          description
        }
      }

      advancedCurriculum {
        title
        anchor
        image {
          childImageSharp {
            gatsbyImageData(
              width: 100
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        duration
        tag
        data
        skills
        cardTitle
        cards {
          image {
            childImageSharp {
              gatsbyImageData(
                width: 300
                quality: 100
                blurredOptions: {}
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
          data
          skills
          title
        }
      }

      steps

      price
      priceDuration

      faqs {
        question
        answer
      }

      skills {
        image {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        originalImage {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }

      jobGuarantee {
        question
        answer
        image {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }

    allStrapiPrograms {
      ...allStrapiProgramsFragment
    }

    ...strapiDataFragment
  }
`;
