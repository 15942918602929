import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import React from 'react';

export const ChatCallToAction = ({
  text,
  link,
  programInterested = 'Software Development',
}) => {
  return (
    <div className="chat-call-to-action -mx-2 flex items-center justify-center p-2 md:-mx-4 md:p-2  ">
      <LeadGenerationButton
        buttonLocation="EXTERNAL_CHATBOT"
        formHeading="Book your Slot"
        pageOneButtonText={text}
        pageTwoButtonText={text}
        type="HiddenButton"
        programInterested={programInterested}
      >
        <button className="w-full max-w-[250px] rounded-lg bg-v5-yellow-100 px-3 py-2.5 text-center text-sm font-semibold text-black shadow-lg transition-colors duration-200 md:px-4 md:py-3">
          {text}
        </button>
      </LeadGenerationButton>
    </div>
  );
};
