import React from 'react';
import Testimonials from './Testimonials';
// import RequestCallbackButton from '../RequestCallbackButton/index';
import { useLocation } from '@reach/router';
import { getProgramTypeFromPath } from '@src/constants/pages';
import useMentorSectionData from '@src/hooks/MentorSection/useMentorSectionData';

export default function MentorTestimonialsSection({ postContent }) {
  const location = useLocation();
  const pathname = location?.pathname;
  const programType = getProgramTypeFromPath(pathname);

  const mentorsData = useMentorSectionData(programType);
  if (!mentorsData?.testimonials?.length > 0) return null;

  return (
    <section
      className="flex w-full items-center justify-center  text-center text-crio-neutral-900"
      style={{ minHeight: '700px', maxHeight: '900px' }}
    >
      <div className="h-full w-full">
        <div>
          <Testimonials data={mentorsData} />
        </div>
        <div>{postContent}</div>
      </div>
    </section>
  );
}
