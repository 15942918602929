import React, { useState, useEffect } from 'react';
import useRegisterMetadata from '@src/hooks/Masterclass/useRegisterMetadata';
import { calculateTimeRemaining } from '@src/utils/Masterclass';
import TimeDisplay from './TimeDisplay';

export default function EventStartTimer() {
  const { getEventDetails } = useRegisterMetadata();
  const { sessionStartDateTime } = getEventDetails();

  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining(sessionStartDateTime));
    }, 1000);

    // Initial calculation
    setTimeRemaining(calculateTimeRemaining(sessionStartDateTime));

    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, [sessionStartDateTime]);

  return (
    <div className="flex items-center gap-x-2 font-rubik text-v5-neutral-500">
      <div className="text-xs md:text-sm">Event starts in</div>
      <TimeDisplay value={timeRemaining.days} unit="d" />
      <TimeDisplay value={timeRemaining.hours} unit="h" />
      <TimeDisplay value={timeRemaining.minutes} unit="m" />
      <TimeDisplay value={timeRemaining.seconds} unit="s" />
    </div>
  );
}
