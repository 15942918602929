import { cn } from '@/lib/utils';
import EventCard from '@components/Masterclass/EventCardV2';
import React from 'react';

export default function EventGrid({ events, className }) {
  return (
    <div className={cn('grid grid-cols-3 gap-9', className)}>
      {events.map((event, index) => (
        <EventCard key={index} event={event} />
      ))}
    </div>
  );
}
