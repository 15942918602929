import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { EProgramIDs, EPrograms } from '../data/programs/index';
import { ERoutes } from '../routes/index';

export const programLinks = [
  {
    title: EPrograms[EProgramIDs.FELLOWSHIP].title,
    subtitle:
      'Upskill and land your dream career as an AI-powered Full-Stack or Backend developer - Assured!',
    link: ERoutes.FELLOWSHIP,
    icon: (
      <StaticImage
        loading="lazy"
        src="../../images/placements/Icons/Fellowship.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
  },
  {
    title: EPrograms[EProgramIDs.QA_AUTOMATION_TESTING].title,
    subtitle:
      'Get practical work experience of automation testing & get software testing roles in top companies- Assured!',
    link: ERoutes.QA_AUTOMATION_TESTING,
    icon: (
      <StaticImage
        loading="lazy"
        src="../../images/placements/Icons/QA.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
  },
  {
    title: EPrograms[EProgramIDs.DATA_SCIENCE].title,
    subtitle:
      'Master the in-demand, AI-powered data analytics skills to land top data analyst roles - Assured!',
    link: ERoutes.DATA_SCIENCE,
    icon: (
      <StaticImage
        loading="lazy"
        src="../../images/placements/Icons/DataScience.webp"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
  },
  {
    title: EPrograms[EProgramIDs.SYSTEM_DESIGN].title,
    subtitle:
      'Master advanced software development and design skills with hands-on experience in System Design - Assured!',
    link: ERoutes.SYSTEM_DESIGN,
    icon: (
      <StaticImage
        loading="lazy"
        src="../../images/SystemDesignIcon.webp"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
  },
];

export const links = [
  { name: 'Hire from Crio', link: ERoutes.RECRUIT },
  { name: 'Masterclass', link: ERoutes.MASTERCLASS },
  { name: 'Projects', link: ERoutes.PROJECTS },
  { name: 'Blog', link: ERoutes.BLOG },
];

export const placementLinks = [
  {
    title: 'Placement Stats',
    subtitle:
      'Helping our Learners Get the Best Career Breakthroughs and into the Best Companies',
    link: ERoutes.PLACEMENTS,
    icon: (
      <StaticImage
        loading="lazy"
        src="../../images/placements/Icons/PlacementStats.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
  },
  {
    title: 'Success Stories',
    subtitle:
      'Hear from some of our learners who took their careers to new heights',
    link: ERoutes.SUCCESS_STORIES,
    icon: (
      <StaticImage
        loading="lazy"
        src="../../images/placements/Icons/SuccessStories.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
  },
];

export const resourcesLink = [
  {
    title: 'ROI Calculator',
    subtitle:
      'Calculate the returns on your educational investment with our ROI calculator',
    link: ERoutes.ROI,
    icon: (
      <StaticImage
        loading="lazy"
        src="../../images/placements/Icons/ROI.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
  },
];
