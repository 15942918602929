import React from 'react';
import RawHTML from '@components/Common/RawHTML';
import {
  FullStackImage,
  SystemDesignImage,
  SDETImage,
  DataAnalyticsImage,
  DefaultImage,
} from './ProgramImages';

export const ProgramCard = ({ program }) => {
  const getImage = (imageName) => {
    switch (imageName) {
      case 'FullStack':
        return <FullStackImage />;
      case 'SystemDesign':
        return <SystemDesignImage />;
      case 'SDET':
        return <SDETImage />;
      case 'DataAnalytics':
        return <DataAnalyticsImage />;
      default:
        return <DefaultImage />;
    }
  };

  return (
    <div className="flex h-[220px] flex-col rounded-lg bg-transparent shadow-md">
      <div className="relative h-24 w-full">{getImage(program.imageType)}</div>
      <div className="flex flex-1 flex-col overflow-hidden p-3">
        <h3 className="mb-2 text-sm font-semibold text-black">
          <RawHTML>{program.title}</RawHTML>
        </h3>
        <div className="scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-300 hover:scrollbar-thumb-gray-400 flex-1 overflow-y-auto">
          <RawHTML className="text-xs text-black">
            {program.description}
          </RawHTML>
        </div>
      </div>
    </div>
  );
};
