import React from 'react';
import RawHTML from '@components/Common/RawHTML';

export const ChatSection = ({ items }) => {
  return (
    <div className="chat-section -mx-2 flex flex-col gap-3 p-0 md:-mx-4 md:gap-4">
      {items.map((section, index) => (
        <div key={index} className="rounded-lg bg-v5-neutral-300  p-3">
          <RawHTML className="mb-2 text-[14px] font-semibold text-black">
            {section.title}
          </RawHTML>
          <RawHTML className="text-sm text-black">{section.content}</RawHTML>
        </div>
      ))}
    </div>
  );
};
