import { useLocation } from '@reach/router';

const useAffiliate = () => {
  const location = useLocation();

  const isAffiliatePage = location.pathname.includes('affiliate');

  return { isAffiliatePage };
};

export default useAffiliate;
