import React, { useState } from 'react';

export const Tooltip = ({ content, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="relative inline-block">
      {React.cloneElement(children, {
        onMouseEnter: () => setIsVisible(true),
        onMouseLeave: () => setIsVisible(false),
        onFocus: () => setIsVisible(true),
        onBlur: () => setIsVisible(false),
      })}
      {isVisible && (
        <div
          className="absolute bottom-full left-1/2 z-50 mb-2 -translate-x-1/2 rounded-md bg-gray-900 
            px-3 py-1.5 text-xs font-medium text-white opacity-100
            shadow-lg transition-opacity duration-200"
          role="tooltip"
        >
          {content}
          <div
            className="absolute top-full left-1/2 -mt-1 -translate-x-1/2 
              border-4 border-transparent border-t-gray-900"
          />
        </div>
      )}
    </div>
  );
};
