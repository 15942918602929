import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export const FullStackImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Programs/FullStack.png"
    alt="Full Stack Development"
    placeholder="blurred"
    className="!h-auto max-h-full w-auto max-w-full object-contain"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'contain',
    }}
  />
);

export const SystemDesignImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Programs/SystemDesign.png"
    alt="System Design"
    placeholder="blurred"
    className="!h-auto max-h-full w-auto max-w-full object-contain"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'contain',
    }}
  />
);

export const SDETImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Programs/SDET.png"
    alt="QA Automation (SDET)"
    placeholder="blurred"
    className="!h-auto max-h-full w-auto max-w-full object-contain"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'contain',
    }}
  />
);

export const DataAnalyticsImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Programs/DataAnalytics.png"
    alt="Data Analytics with AI"
    placeholder="blurred"
    className="!h-auto max-h-full w-auto max-w-full object-contain"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'contain',
    }}
  />
);

export const DefaultImage = () => (
  <StaticImage
    src="../../../../images/Chatbot/Programs/Default.png"
    alt="Program Image"
    placeholder="blurred"
    className="!h-auto max-h-full w-auto max-w-full object-contain"
    imgClassName="mix-blend-normal"
    transformOptions={{
      fit: 'contain',
    }}
  />
);
