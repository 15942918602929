import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import { doLoginToBackend } from '@src/api/index';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';
import { useAuth } from '@src/firebase/useAuth';
import { useContext } from 'react';
import toast from 'react-hot-toast';
import useSendActivities from './useSendActivities';

/**
 * Custom hook for handling user login process.
 * @returns {Function} handleLogin - Function to handle login process.
 */
const useLogin = () => {
  const auth = useAuth();
  const state = useContext(GlobalStateContext);
  const { sendActivityHandler } = useSendActivities();

  /**
   * Function to handle the post-login process, including backend login and error handling.
   * @param {string} idToken - ID token obtained after user authentication.
   * @param {Function} cb - Callback function to execute after successful login.
   */

  const handlePostFirebaseLogin = async (idToken, cb) => {
    let response = await doLoginToBackend(idToken);

    if (response) {
      cb();
    } else {
      // Display error toast if login to backend fails
      toast.error(
        'Authentication failed due to poor network connection. Please refresh your browser and try again.',
        { duration: 8000, style: { fontSize: '14px' }, id: 'auth' },
      );
    }
  };

  /**
   * Function to handle user login process.
   * @param {Event} e - The event object.
   */

  async function handleLogin(e) {
    // Track user sign-in event
    GTM.track(gtmEvents.USER_SIGNIN);

    // Track sign-in event for LeadSquared
    sendActivityHandler(ELeadSquaredActivityCode.SIGN_IN_CLICKED);
    e.preventDefault();

    // Variables to store ID token and user information
    let idToken, user;

    // Check if user is already authenticated
    if (auth?.user) {
      // User is already authenticated, get ID token directly
      idToken = await auth.getIdToken();
      user = auth.user;
    } else {
      // Initiate sign-in process and get ID token afterward
      const signInResult = await auth.signin();
      idToken = signInResult.idToken;
      user = signInResult.user;
    }

    // Extract user information for tracking
    const { email, displayName } = user || {};

    // Track user sign-up event for web-engage
    GTM.track(gtmEvents.USER_SIGNEDUP, {
      email,
      displayName,
    });

    // Handle post-login process
    handlePostFirebaseLogin(idToken, () => {
      // Redirect user to home page after successful login
      window.open('/learn/home/', '_self');
    });
  }

  return handleLogin;
};

export default useLogin;
