import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, A11y, Autoplay, Pagination } from 'swiper';
import { ProjectCard } from './MessageTypes/ProjectCard';
import { ProfileCard } from './MessageTypes/ProfileCard';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import useResizer from '@components/extra/useResizer';

SwiperCore.use([Navigation, A11y, Autoplay, Pagination]);

export default function ChatSwiperCarousel({ items }) {
  const isMobile = useResizer();

  const [isBeginning, setIsBeginning] = React.useState(true);
  const [isEnd, setIsEnd] = React.useState(false);
  const swiperRef = useRef(null);

  const renderCarouselItem = (item) => {
    // If it has project-specific properties, it's a project card
    if (item.projectLink || item.techStack) {
      return <ProjectCard project={item} />;
    }
    // If it has profile-specific properties, it's a profile card
    if (item.description && item.imageType) {
      return <ProfileCard profile={item} />;
    }
    return <ProjectCard project={item} />;
  };

  return (
    <div className="relative">
      <Swiper
        spaceBetween={16}
        slidesPerView={isMobile ? 1 : 1.2}
        pagination={{
          clickable: true,
          bulletActiveClass: 'swiper-pagination-bullet-active',
          bulletClass: 'swiper-pagination-bullet',
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        onSlideChange={(swiper) => {
          setIsBeginning(swiper.isBeginning);
          setIsEnd(swiper.isEnd);
        }}
        className="flex !px-4"
      >
        {items.map((item, index) => (
          <SwiperSlide key={index}>{renderCarouselItem(item)}</SwiperSlide>
        ))}
      </Swiper>

      <style jsx global>{`
        .swiper {
          padding: 0 32px !important;
          padding-bottom: 32px !important;
        }

        .swiper-pagination {
          bottom: 0 !important;
        }

        .swiper-pagination-bullet {
          width: 12px !important;
          height: 12px !important;
          background: transparent !important;
          border: 1px solid #194145 !important;
          opacity: 1 !important;
          margin: 0 4px !important;
        }

        .swiper-pagination-bullet-active {
          background: #194145 !important;
        }
      `}</style>
    </div>
  );
}
