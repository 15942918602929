// import Button from '@components/Button/index';
// const endpointURL = config.backendUrl;
// import HeaderDropdown from "./HeaderDropdown";
import useOuterClick from '@components/useOuterClick/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { globalHistory as history } from '@reach/router';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '@src/context/GlobalContextProvider';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import countries from '../../../utils/i18n-countries';
import { doLoginToBackend } from '../../api/index';
import * as styles from './Header.module.scss';
import { useAuth } from '@src/firebase/useAuth';
import toast from 'react-hot-toast';
import { StaticImage } from 'gatsby-plugin-image';
import Button from '@mui/material/Button';

const FellowshipHeader = (props) => {
  const [dropdown, setDropdown] = useState(null);
  const [mobileNavActive, setmobileNavActive] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const auth = useAuth();
  const { location, navigate } = history;
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  useEffect(() => {
    // setScrollTop(window.pageYOffset);
    if (window.pageYOffset > 50) {
      setTimeout(() => {
        setScrolled(true);
      }, 1);
    }
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);
  const innerRef = useOuterClick((ev) => {
    if (dropdown != null) setDropdown(null);
  });

  useEffect(() => {
    if (auth?.user) {
      // User logged in already or has just logged in.
      setIsLoggedIn(true);
      dispatch({
        type: 'USER_IS_LOGGED_IN',
        payload: true,
      });
    } else {
      setIsLoggedIn(false);
      dispatch({
        type: 'USER_IS_LOGGED_IN',
        payload: false,
      });
    }
  }, [auth?.user]);

  const items = [
    {
      name: 'Placements',
      link: false,
      visibleFrom: ['xl'],
      subItems: [
        {
          name: 'Placement Stats',
          link: '/placements',
          subItems: [],
        },
        {
          name: 'Success Stories',
          link: '/testimonials',
          subItems: [],
        },
      ],
      onClick: () => {
        setDropdown('placements');
      },
      onMouseOver: () => {
        setDropdown('placements');
      },
    },
    {
      name: 'Blog',
      link: '/blog',
      visibleFrom: ['xl'],
      openInNewWindow: true,

      subItems: [],
      onClick: () => {},
    },
    {
      name: 'About',
      link: '/about-us',
      visibleFrom: ['xl'],
      subItems: [],
      onClick: () => {},
    },
    {
      name: 'Projects',
      link: '/projects',
      visibleFrom: ['xl'],
      subItems: [],
      openInNewWindow: true,
      onClick: () => {},
    },
    {
      name: 'Hire from Crio',
      link: '/recruit',
      visibleFrom: ['xl'],
      openInNewWindow: true,
      subItems: [],
      onClick: () => {},
    },
    {
      name: 'Programs',
      link: false,
      visibleFrom: ['xl'],
      subItems: [
        ...(!countries.includes(state.country)
          ? [
              {
                name: 'Fellowship Program in Software Development',
                link: '/software-development-fellowship-program',
                subItems: [],
              },
            ]
          : []),
        {
          name: 'Masters in QA Automation Testing',
          link: '/qa-automation-testing',
          subItems: [],
        },
      ],
      onClick: () => {
        setDropdown('programs');
      },
      onMouseOver: () => {
        setDropdown('programs');
      },
    },
  ];

  function onScroll() {
    // setScrollTop(window.pageYOffset);
    if (window.pageYOffset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }

  const handlePostFirebaseLogin = async (idToken, cb) => {
    let response = await doLoginToBackend(idToken);
    if (response) {
      cb();
    } else {
      toast.error(
        'Authentication failed due to poor network connection. Please refresh your browser and try again.',
        { duration: 8000, style: { fontSize: '14px' }, id: 'auth' },
      );
    }
  };
  async function handleLogin(e) {
    e.preventDefault();
    // if (auth?.user === null) {
    //   return;
    // }
    if (auth?.user) {
      const idToken = await auth.getIdToken();
      handlePostFirebaseLogin(idToken, () => {
        window.open('/learn/home/', '_self');
      });
      return;
    }
    auth.signin().then(async (res) => {
      const idToken = res.idToken;
      handlePostFirebaseLogin(idToken, () => {
        window.open('/learn/home/', '_self');
      });

      return;
    });
  }
  function getDropdown(dropdown) {
    switch (dropdown) {
      case 'programs':
        return (
          <div
            ref={innerRef}
            className="programs dropdown"
            onMouseLeave={() => {
              setDropdown(null);
            }}
          >
            <Link to="/full-stack-developer-track">
              <div className={'programItem'}>
                <h6 className="mb-1">Full Stack Developer Program</h6>
                <p className="bodyText2 " style={{ color: '#7A7D81' }}>
                  Become a skilled Full-Stack developer with hands-on experience
                  in Javascript and MERN stack.
                </p>
              </div>{' '}
            </Link>

            {!countries.includes(state.country) && (
              <>
                <hr></hr>
                <Link to="/backend-developer-track">
                  <div className={`programItem pt-3`}>
                    <h6 className="mb-1">Backend Developer Program</h6>
                    <p className="bodyText2" style={{ color: '#7A7D81' }}>
                      Become a skilled Backend developer with hands-on
                      experience in building a scalable web backend.
                    </p>
                  </div>
                </Link>
                <hr></hr>
                <Link to="/software-development-fellowship-program">
                  <div className={`programItem pt-3`}>
                    <h6 className="mb-1">
                      Fellowship Program in Software Development with GenAI
                    </h6>
                    <p className="bodyText2" style={{ color: '#7A7D81' }}>
                      Upskill and land your dream career as an AI-powered
                      Full-Stack or Backend developer - Assured!
                    </p>
                  </div>
                </Link>
                <hr></hr>
                <Link to="/qa-automation-testing">
                  <div className={`programItem pt-3`}>
                    <h6 className="mb-1">Masters in QA Automation Testing</h6>
                    <p className="bodyText2" style={{ color: '#7A7D81' }}>
                      Get practical work experience of automation testing & get
                      software testing roles in top companies- Assured!
                    </p>
                  </div>
                </Link>
              </>
            )}
          </div>
        );
      case 'placements':
        return (
          <div
            ref={innerRef}
            className="placements dropdown"
            onMouseLeave={() => {
              setDropdown(null);
            }}
          >
            <Link to="/placements">
              <div className={'programItem'}>
                <h6 className="mb-1">Placement Stats</h6>
                <p className="bodyText2 " style={{ color: '#7A7D81' }}>
                  Helping our Learners Get the Best Career Breakthroughs and
                  into the Best Companies
                </p>
              </div>{' '}
            </Link>
            <hr></hr>
            <Link to="/testimonials">
              <div className={'programItem'}>
                <h6 className="mb-1">Success Stories</h6>
                <p className="bodyText2 " style={{ color: '#7A7D81' }}>
                  Hear from some of our learners who took their careers to new
                  heights
                </p>
              </div>{' '}
            </Link>
          </div>
        );
      case 'business':
        return (
          <div
            ref={innerRef}
            className="business dropdown"
            onMouseLeave={() => {
              setDropdown(null);
            }}
          >
            <Link to="/onboard">
              <div className="programItem">
                <h6>Crio OnBoard</h6>
              </div>{' '}
            </Link>
            <hr></hr>

            <Link to="/recruit">
              <div className={`programItem pt-3 pb-2`}>
                <h6>Crio Recruit</h6>
              </div>
            </Link>
          </div>
        );
    }
  }

  return (
    <React.Fragment>
      <div className={styles.customFont}>
        <div
          className={`navbar subtitle1 scrolled flex items-center justify-between md:px-3 lg:px-6`}
          onMouseLeave={() => {
            setDropdown(null);
          }}
        >
          <Link to="/">
            <div
              className={styles.logo}
              onClick={() => {
                setTimeout(
                  () => window.scrollTo({ top: 0, behavior: 'smooth' }),
                  200,
                );
              }}
            >
              <StaticImage
                loading="lazy"
                src="../../images/Crio_Dark.png"
                placeholder="blurred"
                alt="Crio.Do"
              />
              {/* <Image filename={'Crio_Dark.png'}></Image> */}
            </div>
          </Link>
          <div className="flex flex-row-reverse items-center">
            <div className="mx-4 block md:mr-0 lg:hidden">
              <FontAwesomeIcon
                className="cursor-pointer"
                icon={['fas', 'bars']}
                color="#000"
                size="lg"
                onClick={() => {
                  setmobileNavActive(true);
                }}
              />
            </div>
            {props?.disableCTA === true ? (
              ''
            ) : (
              <>
                <div
                  className={`mr-3 hidden ${
                    location.pathname.includes('/web-development-trial')
                      ? 'md:hidden'
                      : 'md:block '
                  }`}
                >
                  <Link to={'/web-development-trial'}>
                    <Button
                      // color="#fff"
                      className="sm shimmer-btn flex items-center whitespace-nowrap bg-crio-yellow-500 font-bold capitalize text-crio-green-900"
                      type="contained"
                      style={{
                        background:
                          'linear-gradient(107.15deg, #FFC772 -9.95%, #FF980E 108.2%)',
                      }}
                    >
                      Book Your Trial
                      <FontAwesomeIcon
                        icon={['fas', 'arrow-up']}
                        className="ml-2 rotate-45"
                        style={{ marginBottom: '2px' }}
                      ></FontAwesomeIcon>
                    </Button>{' '}
                  </Link>
                </div>
                <div className="mr-3 hidden md:block ">
                  <Button
                    className="sm  whitespace-nowrap border-2 border-black font-bold capitalize text-black"
                    variant="outlined"
                    onClick={handleLogin}
                  >
                    {'Sign In'}
                  </Button>
                </div>
              </>
            )}
            <div
              style={{ position: 'relative' }}
              className=" mr-3 flex-row-reverse md:hidden lg:flex"
            >
              {items
                .filter((item) => !props.disable?.includes(item.name))
                .map((ele, idx) => (
                  <Link to={ele.link ? ele.link : undefined} key={idx}>
                    <div
                      style={{ position: 'relative' }}
                      onClick={ele.onClick}
                      onMouseOver={ele.onMouseOver}
                      className={`mx-4 hidden cursor-pointer  items-center
             font-semibold hover:text-gray-500 md:flex 
          `}
                    >
                      <div className=" whitespace-nowrap">{ele.name}</div>
                      {ele.subItems.length > 0 && (
                        <>
                          <span>
                            <FontAwesomeIcon
                              icon={['fal', 'angle-down']}
                              size="1x"
                              className="ml-2"
                            />
                          </span>
                        </>
                      )}{' '}
                    </div>
                  </Link>
                ))}
              {getDropdown(dropdown)}
            </div>
          </div>
        </div>
        <AnimatePresence>
          {mobileNavActive && (
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              transition={{ duration: 0.5, type: 'tween', stiffness: 100 }}
              exit={{ y: 1000 }}
              className={`${styles.mobileNavbar} bg-crio-green-800 `}
              onClick={() => {
                setmobileNavActive(false);
              }}
            >
              <section className="">
                <div className="absolute bottom-0 right-0 w-5/12">
                  <StaticImage
                    loading="lazy"
                    src="../../images/home/menu_mobile.png"
                    placeholder="blurred"
                    alt="Crio.Do"
                  />
                  {/* <Image filename="home/menu_mobile.png"></Image> */}
                </div>
                <div className="ml-5 mt-5 flex  flex-col-reverse items-start justify-center">
                  {' '}
                  {props?.disableCTA === true ? (
                    ''
                  ) : (
                    <div className=" my-3">
                      <div className="">
                        <Link to={'/web-development-trial'}>
                          <Button
                            className="md shimmer-btn mb-6 bg-crio-yellow-500 font-bold capitalize text-crio-green-900"
                            type="primary"
                            style={{
                              background:
                                'linear-gradient(107.15deg, #FFC772 -9.95%, #FF980E 108.2%)',
                            }}
                          >
                            Book Your Trial, Now
                            <FontAwesomeIcon
                              icon={['fas', 'arrow-up']}
                              className="ml-2 rotate-45"
                              style={{ marginBottom: '2px' }}
                            ></FontAwesomeIcon>
                          </Button>{' '}
                        </Link>
                      </div>
                      <div className="">
                        <Button
                          className="md border-2 border-white text-lg font-bold capitalize text-white"
                          variant="outlined"
                          onClick={handleLogin}
                          type={'primary'}
                          style={{ width: '100%' }}
                        >
                          {'Sign In'}
                        </Button>
                      </div>
                    </div>
                  )}
                  {items
                    .filter((item) => !props.disable?.includes(item.name))
                    .map((ele, idx) => (
                      <Link
                        to={ele.link ? ele.link : undefined}
                        target={ele.openInNewWindow ? '_blank' : ''}
                        key={idx}
                      >
                        <div
                          className={` my-3 flex cursor-pointer  
              font-normal text-white`}
                        >
                          <div>
                            <div
                              className={`flex items-center text-crio-green-200 ${
                                ele.subItems.length > 0 && 'mb-3 underline '
                              } `}
                            >
                              <h5 className={` whitespace-nowrap`}>
                                {ele.name}
                              </h5>
                              <div>
                                {ele.subItems.length > 0 && (
                                  <>
                                    <span>
                                      <FontAwesomeIcon
                                        icon={['fal', 'angle-down']}
                                        size="1x"
                                        className="ml-2"
                                      />
                                    </span>
                                  </>
                                )}{' '}
                              </div>
                            </div>
                            <div>
                              {ele.subItems.map((subItem, subItemIndex) => (
                                <Link
                                  key={subItemIndex}
                                  to={subItem.link && subItem.link}
                                  target={
                                    subItem.openInNewWindow ? '_blank' : ''
                                  }
                                >
                                  <div className="mb-2">
                                    <h5 className=" whitespace-nowrap font-normal ">
                                      {subItem.name}
                                    </h5>
                                  </div>
                                </Link>
                              ))}
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                  <Link to={'/'}>
                    <div
                      className={` my-3 flex cursor-pointer  
              font-normal text-crio-green-200`}
                    >
                      <div>
                        <div className={`flex items-center `}>
                          <h5 className={` whitespace-nowrap`}>Home</h5>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <div>
                    <FontAwesomeIcon
                      className="my-3  cursor-pointer"
                      icon={['fal', 'times']}
                      size="2x"
                      color="#fff"
                      onClick={() => {
                        setmobileNavActive(false);
                      }}
                    />
                  </div>
                </div>
                {/* <div className="flex  items-center text-center flex-col-reverse absolute inset-center">
                  {items
                    .filter((item) => !props.disable?.includes(item.name))
                    .map((ele, idx) => (
                      <a
                        href={ele.link ? ele.link : undefined}
                        key={idx}
                        onClick={() => {
                          setmobileNavActive(false);
                        }}
                      >
                        <div
                          className={`mx-3 my-5 font-normal cursor-pointer  items-center
              flex text-white`}
                        >
                          <div>
                            <div
                              className={`flex items-center justify-center `}
                            >
                              <h5
                                className={`font-light text-2xl whitespace-nowrap`}
                              >
                                {ele.name}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </a>
                    ))}{" "}
                  <FontAwesomeIcon
                    className="cursor-pointer mb-5"
                    icon={["fal", "times"]}
                    size="2x"
                    color="#fff"
                    onClick={() => {
                      setmobileNavActive(false);
                    }}
                  />
                </div> */}
              </section>{' '}
              {/* <div className="mobile-mascot">
                <Image filename="Menu.png"></Image>
              </div> */}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <style>
        {`
       
        .navbar {
    
        // position: fixed !important;
        left: 0;
        right: 0;
        background-color: transparent;
        z-index: 3;
        padding: 5px 0px;
        box-sizing: border-box;
        height:65px;
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
      }
      .cross{
        position:absolute;
        right:30px;
      }
      .navbar.scrolled {
        -webkit-backdrop-filter: blur(15px);
        -moz-backdrop-filter: blur(15px);
        backdrop-filter: blur(15px);
        background-color: #fff;
        box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.1);
      }
     
      .dropdown{
      
        position: absolute;
        top: 48px;
        border-radius: 5px;
        margin-left: 16px;
        background-color: #fff;
        padding: 20px 20px 10px 20px;
        text-align: left;
        box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.15);
        left:0px;
      }
      .dropdown.business{left:140px;}
      .mobile-mascot
      {
        position:absolute;
        bottom:0px;
        // transform: translate3d(50%,-25%,0);
        width:50%;
        z-index:0;
      }
      .programItem {
     
        color: #000;
        padding-bottom: 20px;
      }
      .programItem:hover {
        color: #7bba8c;
      }
      `}
      </style>
    </React.Fragment>
  );
};
export default FellowshipHeader;
