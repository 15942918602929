import { graphql } from 'gatsby';

import '@fortawesome/fontawesome-svg-core/styles.css';
import React, { useEffect, useState } from 'react';
import FooterV2 from '@components/FooterV2/index';
import SessionInfoLabel from '@components/JoinSession/SessionInfoLabel';
import Button from '@components/Button/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import PaperClip from '@src/images/JoinSession/PaperClip';
import CrioLogo from '@components/NavigationV2/Navbar/CrioLogo/index';
import { format } from 'date-fns';
import useFetchSessionLink from '@src/hooks/useFetchSessionLink';
import JoinSessionModal from '@components/JoinSessionModal/index';
import { getQueryParams, getSessionStartDate } from '@src/utils/helper';
import { useLocation } from '@reach/router';

export default function JoinSessionPage(props) {
  const { data: queryData } = props;
  const { joinSessionPagesYaml: data } = queryData;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [message, setMessage] = useState('');
  const [subMessage, setSubMessage] = useState('');

  const location = useLocation();

  const { origin, search, pathname } = location;

  const isQAJoinSessionPage = pathname.includes('/crio-trial-workshop-qa');
  const isAccelerateJoinSessionPage = pathname.includes(
    '/trial-workshop-accelerate',
  );

  const query = getQueryParams(location?.search) || {};
  const k = query?.k;

  const slug = pathname;

  const {
    fetchJoinSessionLinkQuery,
    data: joinSessionData,
    errored,
  } = useFetchSessionLink();

  const platformJoinSessionUrl = `${origin}/learn/magic${slug}`;
  const joinSessionUrl = joinSessionData?.joinUrl || platformJoinSessionUrl;

  useEffect(() => {
    fetchJoinSessionLinkQuery(slug, k);
  }, []);

  const { pageTitle, programTitle, benefits, pageImage } = data || {};

  const pageImageData = getImage(pageImage.childImageSharp);

  const getFormattedSessionStartDate = (isAccelerateJoinSessionPage) => {
    return format(
      getSessionStartDate(isAccelerateJoinSessionPage),
      'do LLL, p',
    );
  };

  const getFormattedSessionStartTime = () => {
    return format(new Date(joinSessionData.scheduledStartTime), 'p');
  };

  const getFormattedSessionStartDateForLateJoiner = (
    isAccelerateJoinSessionPage,
  ) => {
    const day = new Date().getDay();

    if (day === 5 || day === 6) {
      return '2:00 PM';
    }

    return isAccelerateJoinSessionPage ? '7:00 PM' : '8:30 PM';
  };

  const joinSessionUrlHandler = () => {
    if (
      joinSessionData?.message.includes('ERR_JOINED_EARLY') ||
      joinSessionData?.message.includes('ERR_JOINED_LATE')
    ) {
      let message = ``;

      if (errored) {
        window.open(platformJoinSessionUrl, '_blank');
      } else if (joinSessionData?.message.includes('ERR_JOINED_EARLY')) {
        message = `${getFormattedSessionStartTime()} today.`;
      } else if (joinSessionData?.message.includes('ERR_JOINED_LATE')) {
        message = `${getFormattedSessionStartDateForLateJoiner(
          isAccelerateJoinSessionPage,
        )} tomorrow.`;
      }
      setMessage(`The free trial workshop is scheduled for ` + message);
      setSubMessage(
        <>
          Please join us <strong>10 mins before</strong> the scheduled start
          time <strong>via laptop</strong>.<br /> Till then you can visit our
          homepage to explore our programs and the Crio platform!
        </>,
      );
      handleOpen();
    } else {
      window.open(joinSessionUrl, '_blank');
    }
  };

  return (
    <div className="flex h-[100dvh] flex-col items-center">
      <JoinSessionModal
        open={open}
        handleClose={handleClose}
        subMessage={subMessage}
        message={message}
      />
      <div className="flex h-[60px] w-full items-center justify-center bg-[#2DF8C5] py-3 md:h-[100px]">
        <CrioLogo dark={false} className="md:h-[50px] md:w-[170px]" />
      </div>
      <div className="flex max-w-[1440px] flex-col gap-14 p-3 py-8 md:flex-row md:p-8 md:py-16">
        <div className="flex w-full flex-col items-center gap-8 md:w-[70%] md:items-start">
          <h1 className="text-center font-manrope text-[28px] font-extrabold leading-[30px] md:text-left md:text-[44px] md:leading-[66px]">
            {pageTitle}{' '}
          </h1>
          <div className="relative block max-w-[200px] md:hidden">
            <h2 className="relative z-[1] text-center font-manrope text-[16px] font-extrabold">
              {programTitle}
            </h2>
            <StaticImage
              width={200}
              loading="lazy"
              src="../images/JoinSession/Highlight.webp"
              placeholder="blurred"
              alt="Crio.Do"
              className="absolute top-0 left-0"
              imgStyle={{ objectFit: 'contain' }}
            />
          </div>
          <div className="flex flex-col gap-6">
            {benefits.map((benefit) => {
              const icon = getImage(benefit.icon.childImageSharp);

              return (
                <div
                  key={benefit.content}
                  className="flex flex-col items-center gap-1 md:flex-row md:items-start md:gap-3"
                >
                  <div className="h-[30px] w-[30px]">
                    <GatsbyImage
                      loading="lazy"
                      image={icon}
                      className=""
                      alt={'Joining Detail'}
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  </div>
                  <p className="max-w-[420px] flex-1 text-center font-manrope text-[16px] md:text-left md:text-[20px]">
                    {benefit.content}
                  </p>
                </div>
              );
            })}
          </div>
          <div className="flex w-[270px] flex-col items-center justify-center gap-3 rounded-[10px] border-[1px] border-[#02BF91] bg-[#2DF8C5]/60 py-5 px-3 md:w-[360px] md:items-start md:px-6">
            <SessionInfoLabel
              label={'Date & Time'}
              value={getFormattedSessionStartDate(isAccelerateJoinSessionPage)}
              icon={
                <StaticImage
                  width={150}
                  loading="lazy"
                  src="../images/JoinSession/Calendar.webp"
                  placeholder="blurred"
                  alt="Crio.Do"
                  className=""
                  imgStyle={{ objectFit: 'contain' }}
                />
              }
            />
            <div className="block h-[1px] w-[180px] bg-[#07634C] md:hidden"></div>
            <SessionInfoLabel
              label={'Location'}
              value={'Online/Zoom'}
              icon={
                <StaticImage
                  width={150}
                  loading="lazy"
                  src="../images/JoinSession/Location.webp"
                  placeholder="blurred"
                  alt="Crio.Do"
                  className=""
                  imgStyle={{ objectFit: 'contain' }}
                />
              }
            />
          </div>
          <div className="flex flex-col items-center gap-3 md:items-start">
            <Button
              className="flex h-[70px] w-[280px] max-w-[280px] items-center justify-center gap-2 rounded-[10px] bg-v5-yellow-200"
              onClick={() => joinSessionUrlHandler()}
            >
              Join Now
              <FontAwesomeIcon
                className="text-lg font-bold transition-all	group-hover:translate-x-1"
                icon={['fas', 'angle-right']}
              ></FontAwesomeIcon>
            </Button>
            <p className="max-w-[500px] text-center font-manrope text-[12px] font-medium md:text-left md:text-[16px] [&_a]:font-extrabold [&_a]:text-[#02BF91] [&_a]:underline">
              <span>
                Not what you're looking for? Click here to attend Crio’s free
                trial for the{' '}
                <a
                  href={
                    isQAJoinSessionPage
                      ? `${origin}/crio-trial-workshop/${search}`
                      : `${origin}/crio-trial-workshop-qa/${search}`
                  }
                  className="font-extrabold text-[#02BF91] underline"
                >
                  {isQAJoinSessionPage
                    ? `'Fellowship Program in Software Development with GenAI’`
                    : '‘Fellowship Program in QA Automation.’'}
                </a>
              </span>
            </p>
          </div>
        </div>
        <div className="hidden w-full max-w-[600px] flex-col  gap-4 md:flex">
          <div className="relative rounded-full border-[1px] border-[#02BF91] bg-[#72F9D7] p-6">
            <h2 className="relative z-[1] text-center font-manrope text-[16px] font-extrabold">
              {programTitle}
            </h2>
            <div className="absolute top-[24px] left-[-16px]">
              <PaperClip />
            </div>
          </div>
          <GatsbyImage
            loading="lazy"
            image={pageImageData}
            className=""
            alt={'Joining Detail'}
            imgStyle={{ objectFit: 'contain' }}
          />
        </div>
      </div>
      <div className={'w-full'}>
        <FooterV2 />
      </div>
    </div>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    joinSessionPagesYaml(id: { eq: $id }) {
      slug
      programTitle
      pageTitle
      pageImage {
        childImageSharp {
          gatsbyImageData(
            width: 750
            quality: 100
            blurredOptions: {}
            placeholder: NONE
            formats: [AUTO, WEBP]
          )
        }
      }

      benefits {
        icon {
          childImageSharp {
            gatsbyImageData(
              width: 750
              quality: 100
              blurredOptions: {}
              placeholder: NONE
              formats: [AUTO, WEBP]
            )
          }
        }
        content
      }
    }
  }
`;
