import React, { useEffect } from 'react';
import CurriculumHeader from './CurriculumHeader';
// import { getImage } from 'gatsby-plugin-image';
import { useState } from 'react';
import CurriculumDetail from './CurriculumDetail';
import { cn } from '@/lib/utils';

const DesktopCurriculum = ({
  curriculumSection,
  isLightTheme,
  className,
  isSDPage,
}) => {
  const [selected, setSelected] = useState(curriculumSection[0]);

  useEffect(() => {
    setSelected(curriculumSection[0]);
  }, [curriculumSection]);

  return (
    <div
      className={cn(
        'mb-8 min-h-[660px] w-full max-w-[1050px] gap-4 md:flex',
        className,
      )}
    >
      <div className="flex flex-1 flex-col gap-3">
        {curriculumSection.map((curriculum, idx) => {
          return (
            <div
              className={cn(
                'w-full max-w-[500px] cursor-pointer overflow-hidden rounded-[30px] py-5 px-5 ',
                `${
                  isLightTheme ? 'text-v5-neutral-600' : 'text-v5-neutral-200'
                }`,
                `${
                  selected.title === curriculum.title
                    ? isLightTheme
                      ? 'bg-v5-green-100'
                      : 'bg-v5-green-400'
                    : isLightTheme
                    ? 'bg-v5-neutral-100'
                    : 'bg-v5-green-700'
                }`,
              )}
              onClick={() => {
                setSelected(curriculum);
              }}
            >
              <CurriculumHeader data={curriculum} isLightTheme={isLightTheme} />
            </div>
          );
        })}
      </div>
      <div className="w-full flex-1">
        <div
          className={cn(
            `${
              isSDPage ? 'md:min-h-[1000px]' : 'md:min-h-[660px]'
            } w-full rounded-[30px]  py-6 md:min-w-[550px]`,
            `${
              isLightTheme
                ? 'bg-v5-neutral-100 text-v5-neutral-600'
                : 'bg-v5-green-700 text-v5-neutral-200'
            }`,
          )}
          onClick={() => {}}
        >
          <CurriculumHeader
            data={selected}
            className="px-6"
            isLightTheme={isLightTheme}
          />
          <div
            className={cn(
              'customScrollbar mt-4  overflow-y-auto overflow-x-hidden px-6 pb-10',
              `${isLightTheme ? 'text-v5-neutral-500' : ''}`,
              `${isSDPage ? 'max-h-[1000px]' : 'max-h-[670px]'}`,
            )}
          >
            <CurriculumDetail data={selected} isLightTheme={isLightTheme} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopCurriculum;
